import React from 'react';

import { File } from '../Folder.component';
import styles from '../Folder.module.scss';

import { ReactComponent as DownloadIcon } from "../../../../../../../icons/n-Download.svg"
import { ReactComponent as BinIcon } from '../../../../../../../icons/Unpin.svg';
import { chatsService } from '../../../../../../../api';
import { useChatStore } from '../../../../../Chat.hook';
import { Text } from 'src/components/Text/Text';
import { Popover } from 'antd';

interface SummaryProps {
  id: string;
  onClickDownload: () => void;
  folderName: string;
  fullFileName: string;
  files: File[]
}

export const Summary: React.FC<SummaryProps> = ({
  folderName: name,
  fullFileName,
  onClickDownload,
  files,
}) => {

  const { setChats } = useChatStore()

  const tableCount = files.filter((file) => file.fileName.endsWith('.csv')).length;
  const plotCount = files.filter((file) => file.fileName.endsWith('.html')).length;
  const imageCount = files.filter((file) => file.fileName.endsWith('.png')).length;

  const deleteFolder = async () => {
    await Promise.all(files.map(file => chatsService.deleteChatOutput(file.id)));
    const chats = await chatsService.findAll();
    setChats(chats)
  }

  return (
    <section className={styles['folder__section']}>
      <div className={styles['folder__summary-content']}>
        <div className={styles['folder__summary-text']}>
        <Popover content={<Text.Small>{fullFileName}</Text.Small>} placement="top">
          <span className={styles['folder__summary-title']}>{name}</span>
        </Popover>
          <span className={styles['folder__summary-details']}>
            {`${tableCount} table, ${plotCount + imageCount} plot`}
          </span>
        </div>
        <div className={styles['folder__actions']}>
          <DownloadIcon
            onClick={onClickDownload}
            className={styles['folder__action-icon']}
            data-dropdown-ignore-click
          />
          <BinIcon
            className={styles['icon']}
            onClick={deleteFolder}
            data-dropdown-ignore-click
          />
        </div>
      </div>
    </section>
  );
};
