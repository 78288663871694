import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import classNames from 'classnames';

import { chatsService } from '../../../../api';
import { useChatStore } from '../../Chat.hook';
import { getFileNameWithoutQueryParams } from '../Messages/components/assets/MessageAssetContainer.utils';
import EmptyOutput from './OutputContent/EmptyOutput/EmptyOutput.component';
import OutputContent, { OutputContentProps } from './OutputContent/OutputContent.component';
import OutputSkeleton from './OutputSkeleton/OutputSkeleton.component';
import styles from './Outputs.module.scss';

interface Tab {
  id: string;
  label: string;
  content: React.ReactNode | ((studies: OutputContentProps['studies']) => React.ReactNode) | null;
}

const defaultTabs: Tab[] = [{ id: 'jobs', label: 'Jobs Output', content: null }];

const groupByCodeFilePath = (data) => {
  if (!data) return {};
  const result = data.reduce((acc, item) => {
    const fileName = getFileNameWithoutQueryParams(item.code_file_path);

    if (!acc[fileName]) {
      acc[fileName] = [];
    }

    acc[fileName].push(item);
    return acc;
  }, {});

  return result;
};

const Outputs: React.FC<{
  chatStudies: OutputContentProps['studies'];
  jobsStudies: OutputContentProps['studies'];
}> = ({ chatStudies = [], jobsStudies = [] }) => {
  const [activeTab, setActiveTab] = useState<Tab>(defaultTabs[0]);
  const { isLoading, chats, selectedChat, setStudies, setChats, outputs, isOutputsCollapsed } =
    useChatStore();
  const [tabs, setTabs] = useState(defaultTabs);

  const truncate = (url) => {
    const [name, ext] = url.split('.');

    return name.length > 23 ? `${name.slice(0, 12)}...${name.slice(-10)}.${ext}` : url;
  };

  const realtimeChat = useMemo(
    () => chats.find((chat) => chat?.id === selectedChat?.id),
    [chats, selectedChat?.id],
  );

  useEffect(() => {
    const chatTab = tabs.find((tab) => tab.id === 'chat');

    if (chatTab) {
      setActiveTab(chatTab);
    }
  }, [realtimeChat, tabs]);

  const isCodeFile = (file) => !file.asset_file_path && file.code_file_path;

  useEffect(() => {
    setStudies(
      chats.length
        ? chats
            .map((chat) => ({
              id: chat.id,
              title: chat?.name,
              folders: Object.entries(groupByCodeFilePath(chat.chat_outputs)).map(
                ([name, value], index) => {
                  return {
                    id: `folder-${chat.id}-${index}`,
                    originalFolderName: name,
                    codeFileName: name,
                    folderName: truncate(name),
                    files: (value as any).map((file) => ({
                      id: file.id,
                      ...(!isCodeFile(file)
                        ? {
                            originalPath: file.asset_file_path,
                            originalFileName: file.asset_file_path.split('?')[0],
                            fileName: truncate(getFileNameWithoutQueryParams(file.asset_file_path)),
                          }
                        : {
                            isCode: true,
                            originalPath: file.code_file_path,
                            originalFileName: file.code_file_path.split('?')[0],
                            fileName: truncate(getFileNameWithoutQueryParams(file.code_file_path)),
                          }),
                    })),
                  };
                },
              ),
            }))
            .filter((chat) => chat.folders.length)
        : [],
    );
  }, [chats, outputs, selectedChat?.name, setStudies]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteChatOutputs = async (chatId: string) => {
    await chatsService.deleteAllChatOutputs(chatId);
    const chats = await chatsService.findAll();

    setChats(chats);
  };

  useEffect(() => {
    if (tabs.length === 1) {
      setTabs(() => [
        {
          id: 'chat',
          label: 'Chat Output',
          content: (studies: OutputContentProps['studies']): React.ReactNode => (
            <OutputContent studies={studies} onDeleteChatOutputs={onDeleteChatOutputs} />
          ),
        },
        defaultTabs[0],
      ]);
    }
  }, [onDeleteChatOutputs, selectedChat, tabs, tabs.length]);

  useEffect(() => {
    if (tabs.length === 2) {
      setActiveTab(tabs[0]);
    }
  }, [tabs, tabs.length]);

  if (isLoading) {
    return (
      <div className={styles.outputs}>
        <OutputSkeleton />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.outputs, { [styles['outputs-collapsed']]: isOutputsCollapsed })}
    >
      <div className={styles.tabs}>
        {tabs?.map((tab) => (
          <div
            key={tab.id}
            onClick={() => setActiveTab(tab)}
            className={cn(styles['tabs--tab'], {
              [styles['tabs--tab__active']]: activeTab.id === tab.id,
            })}
          >
            {tab.label}
          </div>
        ))}
      </div>

      <div
        className={cn(styles.content, {
          [styles['content--empty']]:
            activeTab.id === 'chat' ? !chatStudies.length : !jobsStudies.length,
        })}
      >
        {(typeof activeTab.content === 'function' &&
          chatStudies.length &&
          activeTab.content(activeTab.id === 'chat' ? chatStudies : jobsStudies)) || (
          <EmptyOutput />
        )}
      </div>
    </div>
  );
};

export default Outputs;
