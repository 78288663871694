import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import the SCSS file for styles
import { authService } from '../../api';
import Logo from '../../components/Logo/Logo.component';
import './Login.style.scss';

const ACCESS_TOKEN_KEY = 'accessToken';
const USER_KEY = 'user';

const signUpDisabled = process.env.REACT_APP_HIDE_SIGN_UP === 'true';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const user = localStorage.getItem(USER_KEY);

    if (accessToken && user) {
      navigate('/chat');
    }
  }, [navigate]);

  const clearForm = () => {
    setEmail('');
    setPassword('');
    setError('');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const formData = new URLSearchParams();
    formData.append('email', email);
    formData.append('password', password);

    const submit = isRegistering ? authService.register : authService.login;

    try {
      const { status, data } = await submit({
        email,
        password,
      });
      if (status === 200 || status === 201) {
        localStorage.setItem(USER_KEY, JSON.stringify(data));
        localStorage.setItem(ACCESS_TOKEN_KEY, data.token);
        navigate('/chat');
        window.location.reload();
      } else {
        setError(isRegistering ? 'Registration failed' : 'Login failed');
      }
    } catch (err) {
      const errorMessage = err?.response?.data?.message;
      if (!errorMessage) {
        setError('An error occurred');
      } else {
        const error = typeof errorMessage === 'string' ? errorMessage : errorMessage[0];
        setError(error || 'An error occurred');
      }
    }
  };

  useEffect(() => {
    clearForm();
  }, [isRegistering]);

  return (
    <div className="login-form-container">
      <Logo className="login-logo" />
      <form className="login-form" onSubmit={handleSubmit}>
        {error && <div className="error-message">{error}</div>}
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-input"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-input"
            required
          />
        </div>
        <button type="submit" className="login-btn">
          {isRegistering ? 'Sign Up' : 'Log In'}
        </button>
      </form>
      {!signUpDisabled && (
        <div className="switch-form">
          <span>{isRegistering ? 'Already have an account?' : "Don't have an account?"}</span>

          <button onClick={() => setIsRegistering(!isRegistering)}>
            {isRegistering ? 'Log In' : 'Sign Up'}
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
