import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useModal } from './Modal.hook';

const ModalPortal = ({ children }: { children: ReactNode }) => {
    const elRef = useRef<HTMLDivElement | null>(null);
    const { closeModal } = useModal();

    if (!elRef.current) {
        elRef.current = document.createElement('div');
    }

    useEffect(() => {
        const modalRoot = document.getElementById('modal-root')!;
        modalRoot.appendChild(elRef.current);

        const handleClickOutside = (event: MouseEvent) => {
            if (elRef.current && !elRef.current.contains(event.target as Node)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            modalRoot.removeChild(elRef.current!);
        };
    }, [closeModal]);

    return ReactDOM.createPortal(children, elRef.current);
};

export default ModalPortal;
