import React from 'react';

import { ReactComponent as ChartIcon } from '../../../../../../../icons/32/Chart.svg';
import { ReactComponent as CodeIcon } from '../../../../../../../icons/32/Code.svg';
import { ReactComponent as TableIcon } from '../../../../../../../icons/32/Table.svg';
import { File } from '../Folder.component';
import styles from '../Folder.module.scss';

export const FileIcon: React.FC<{ file: File }> = ({ file }) => {
  const fileExtension = file.fileName.split('.').pop();
  let Icon;

  switch (fileExtension) {
    case 'r':
      Icon = CodeIcon;
      break;
    case 'csv':
      Icon = TableIcon;
      break;
    default:
      Icon = ChartIcon;
  }

  return <Icon className={styles['folder__file-icon-image']} />;
};
