import { AxiosResponse } from 'axios';

import { AuthApi, AuthResponseDto, CreateUserDto, LoginDto } from '../../swagger_api';
import { swaggerConfiguration } from '../swagger';

class AuthService {
  authApi: AuthApi;

  constructor() {
    this.authApi = new AuthApi(swaggerConfiguration);
  }

  login = async (loginDto: LoginDto): Promise<AxiosResponse<AuthResponseDto>> => {
    try {
      const response = await this.authApi.login(loginDto);
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  };

  register = async (registerDto: CreateUserDto): Promise<AxiosResponse<AuthResponseDto>> => {
    try {
      const response = await this.authApi.register(registerDto);
      return response;
    } catch (error) {
      console.error('Error in register:', error);
      throw error;
    }
  };
}

export const authService = new AuthService();
