import { useCallback, useEffect } from 'react';

import { Socket } from 'socket.io-client';

import Modal from '../../components/Modal/Modal.component';
import { useModal } from '../../components/Modal/Modal.hook';
import ModalContent from '../../components/Modal/ModalContent.component';
import { ModalName } from '../../components/Modal/modalEnums';
import { useChatStore } from './Chat.hook';
import styles from './Chat.module.scss';
import { withChat } from './WithChat';
import AnalysisModalBody from './components/AnalysisModal/AnalysisModalBody.component';
import AnalysisModalFooter from './components/AnalysisModal/AnalysisModalFooter.component';
import AnalysisModalHeader from './components/AnalysisModal/AnalysisModalHeader.component';
import EditCodeModalBody from './components/EditCodeModal/EditCodeModalBody.component';
import EditCodeModalHeader from './components/EditCodeModal/EditCodeModalHeader.component';
import Layout from './components/Layout/Layout.component';
import Messages from './components/Messages/Messages';
import Outputs from './components/Outputs/Outputs.component';
import { useMessagesScroll } from './hooks/useMessagesScroll';

export type ChatComponentProps = {
  socket: Socket;
};
const ChatComponent = (props) => {
  const { chats, studies } = useChatStore();
  const { initializeModal } = useModal();

  const initializeAnalysisModal = useCallback(() => {
    initializeModal(
      ModalName.ANALYSIS_SETTINGS_MODAL,
      <ModalContent
        header={<AnalysisModalHeader />}
        body={<AnalysisModalBody />}
        footer={<AnalysisModalFooter />}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeEditCodeModal = useCallback(() => {
    initializeModal(
      ModalName.EDIT_CODE_MODAL,
      <ModalContent
        className={styles['edit-code-modal-content']}
        classNameBody={styles['edit-code-modal-body']}
        header={<EditCodeModalHeader socket={props.socket} />}
        body={<EditCodeModalBody />}
        footer={null}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeAnalysisModal();
    initializeEditCodeModal();
  }, [initializeAnalysisModal, initializeEditCodeModal]);

  const { chatsRef, handleScrollToChatMessage } = useMessagesScroll();

  return (
    <Layout isChat>
      <div className={styles['chat-workspace']}>
        <Modal />
        <Messages {...props} ref={chatsRef} handleScrollToChatMessage={handleScrollToChatMessage} />
        {!!chats.length && <Outputs jobsStudies={[]} chatStudies={studies} />}
      </div>
    </Layout>
  );
};

export default withChat(ChatComponent);
