import React from 'react';
import { Popover } from 'antd';
import SpinnerLoading from 'src/components/Loading/SpinnerLoading/SpinnerLoading';
import { ReactComponent as RunIcon } from 'src/icons/Run.svg';
import { Text } from 'src/components/Text/Text';

type RunActionProps = {
  isCodeMessage: boolean;
  isShared: boolean;
  messageProcessing: boolean;
  isLoading: boolean;
  onRunCode: (event: React.MouseEvent) => void;
};

const RunAction: React.FC<RunActionProps> = ({
  isCodeMessage,
  isShared,
  messageProcessing,
  isLoading,
  onRunCode,
}) => {
  if (!isCodeMessage || isShared) {
    return null;
  }

  return (
    <Popover
      content={
        <Text.Small>
          {messageProcessing ? 'Run is unavailable due to ongoing actions.' : 'Run'}
        </Text.Small>
      }
      placement="top"
    >
      <div data-dropdown-ignore-click>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <RunIcon
            style={{ opacity: messageProcessing ? 0.6 : 1, pointerEvents: messageProcessing ? 'none' : 'auto' }}
            data-dropdown-ignore-click
            onClick={onRunCode}
          />
        )}
      </div>
    </Popover>
  );
};

export default RunAction;
