import { useLocation, useNavigate } from "react-router-dom";

import { useModal } from "../../../../../../components/Modal/Modal.hook";
import Button from "../../../../../../components/Button/Button.component";
import useManageChatModalStore from "./ManageChatModal.hook";
import styles from "./ManageChatModal.module.scss";
import { chatsService, usersService } from "../../../../../../api";
import { useChatStore } from "../../../../Chat.hook";

const ManageChatModalFooter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        actionButtonText,
        setTitle,
        selectedDataset,
        reset,
        chatId,
        chatName,
        setActionButtonText,
        mode,
        setIsCreating,
        isCreating,
        datasets
    } = useManageChatModalStore();

    const { closeModal } = useModal();
    const { setSelectedChat, setChats, sharedChats, setSharedChats} = useChatStore();


    const handleCreateChat = async () => {
        try {
            setIsCreating(true)
            const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;

            const newChat = await chatsService.createChat({
                name: chatName,
                user_id: userId,
                dataset_id: selectedDataset,
                active_dataset: datasets.find(dataset => dataset.id === selectedDataset).name
            });

            await updateChatList(newChat);

            await Promise.resolve();

            const queryParams = new URLSearchParams(location.search);
            queryParams.set('chatId', newChat.id);
            navigate({ search: queryParams.toString() });

        } catch (error) {
            console.error("Failed to create chat:", error);
        }
    };

    const handleUpdateChat = async () => {
        try {
            await chatsService.updateChat(chatId, { name: chatName });
            await updateChatList();
        } catch (error) {
            console.error("Failed to update chat:", error);
        }
    };

    const handleDeleteChat = async () => {
        try {
            await chatsService.deleteChat(chatId);
            const chats = await chatsService.findAll();
            setChats(chats)
            setSelectedChat(null)
            closeModal();
            reset();
        } catch (error) {
            console.error("Failed to delete chat:", error);
        }
    }

    const handleDeleteSharedChat = async () => {
      try {
            const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;
            await usersService.deleteSharedChat(userId, chatId);
            const updatedSharedChats = sharedChats.filter(chat => chat.id !== chatId);
            setSharedChats(updatedSharedChats)
            setSelectedChat(null)
            closeModal();
            reset();
        } catch (error) {
            console.error("Failed to delete chat:", error);
        }
    }

    const updateChatList = async (selectedChat = null) => {
        const allChats = await chatsService.findAll();
        setChats(allChats);
        if (selectedChat) {
            setSelectedChat(selectedChat);
        }
        closeModal();
        reset();
    };

    const onClickAction = async () => {
      switch (mode) {
        case 'create':
          setActionButtonText('Create');
            setTitle('Enter a chat name');
            await handleCreateChat();
          break;
          case 'edit':
            setActionButtonText('Save');
            setTitle('Edit chat name');
            await handleUpdateChat();
          break;
          case 'delete':
            handleDeleteChat()
          break;
          case 'delete_shared':
          handleDeleteSharedChat()
          break;
        default:
          break;
      }
    };

    return (
        <div className={styles['manage-chat-modal__footer']}>
            <Button color="secondary" onClick={() => { closeModal(); reset(); }}>
                Cancel
            </Button>
            <Button onClick={onClickAction} disabled={(!chatName.trim()) || isCreating}>
                {actionButtonText}
            </Button>
        </div>
    );
};

export default ManageChatModalFooter;
