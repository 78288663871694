import React, { useRef } from "react";
import { DownArrow } from "./components/DownArrow";
import { UpArrow } from "./components/UpArrow";

import useNumberInput from "./n-Input.hook";
import { calculatePaddingRight } from "./n-Input.utils";

import styles from "./n-Input.module.scss";
import classNames from "classnames";

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    description?: string;
    label?: string;
    onChange?: (value: string | number) => void;
    minValue?: number;
    maxValue?: number;
    step?: number;
    format?: (value: string) => string;
    validation?: (value: string) => boolean;
    error?: string;
    value?: string | number;
    style?: React.CSSProperties;
    className?: string;
}

const Input: React.FC<InputProps> = ({
    description,
    label,
    type = "text",
    minValue,
    maxValue,
    step = 1,
    value: propValue,
    onChange,
    format,
    validation,
    className,
    error,
    ...props
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { increment, decrement } = useNumberInput(
        parseFloat(propValue as string),
        minValue,
        maxValue,
        step,
        onChange
    );

    return (
        <div className={styles.input}>
            {label && <label className={styles.input__label}>{label}</label>}
            <div className={styles.input__wrapper}>
                <input
                    className={classNames(styles.input__input, className)}
                    {...props}
                    type={type}
                    value={propValue !== undefined ? propValue.toString() : ""}
                    onChange={e => onChange(e.target.value)} // 
                    style={{ paddingRight: calculatePaddingRight(description) }}
                    aria-describedby="input-error"
                    aria-invalid={!!error}
                    ref={inputRef}
                />
                {description && (
                    <span className={styles.input__description}>{description}</span>
                )}
                {type === "number" && (
                    <>
                        <UpArrow onClick={increment} />
                        <DownArrow onClick={decrement} />
                    </>
                )}
                {error && (
                    <span id="input-error" className={styles.input__error}>
                        {error}
                    </span>
                )}
            </div>

        </div>
    );
};

export default Input;
