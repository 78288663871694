import React, { useState } from 'react';
import cn from 'classnames';

import ChartsRenderer from './ChartsRenderer/ChartsRenderer.component';
import styles from './InteractiveChart.module.scss';

import { ReactComponent as ChartIcon } from '../../icons/32/Chart.svg';
import { ReactComponent as WarningIcon } from '../../icons/Warning.svg';
import { ReactComponent as TickIcon } from '../../icons/Tick.svg';
import { ReactComponent as ArrowIcon } from '../../icons/Arrow.svg';

import Button from '../Button/Button.component';
import Select, { Option } from '../Select/Select.component';
import { Patient } from './InteractiveChart.utils';

type ExcludedPoints = Record<number, { index: number }[]>

type OnSaveData = {
    excludedPoints: ExcludedPoints;
}

interface InteractiveChartProps {
    title: string;
    patientsOptions: Option[]
    patientData: Patient[];
    totalPatients: number;
    isOpen?: boolean;
    pageSize?: number;
    onPageChange?: (page: number) => void;
    onSave: (onSaveData: OnSaveData) => void;
    [key: string]: any;
}

const InteractiveChart: React.FC<InteractiveChartProps> = ({
    title,
    patientData,
    patientsOptions,
    totalPatients,
    isOpen: defaultIsOpen = true,
    pageSize: defaultPageSize = 4,
    onPageChange,
    onSave,
    ...restProps }
) => {
    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [currentPage, setCurrentPage] = useState(0);
    const [excludedPoints, setExcludedPoints] = useState<ExcludedPoints>({});
    const [saved, setSaved] = useState(false);

    const pageSize = defaultPageSize;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handlePointClick = (_, elements: any, chartIndex: number) => {
        if (elements.length > 0) {
            const { index } = elements[0];
            setExcludedPoints((prevState) => {
                const chartPoints = prevState[chartIndex] || [];

                const pointExists = chartPoints.some((p) => p.index === index);
                if (pointExists) {
                    return {
                        ...prevState,
                        [chartIndex]: chartPoints.filter((p) => !(p.index === index)),
                    };
                } else {
                    return {
                        ...prevState,
                        [chartIndex]: [...chartPoints, { index }],
                    };
                }
            });
        }
    };

    const prevPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
            onPageChange && onPageChange(currentPage - 1);
        }
    };

    const nextPage = () => {
        if ((currentPage + 1) * pageSize < totalPatients) {
            setCurrentPage(currentPage + 1);
            onPageChange && onPageChange(currentPage + 1);
        }
    };

    const onChangePatients = (selectedOption: number) => {
        const selectedPage = Math.floor((selectedOption - 1) / pageSize);
        setCurrentPage(selectedPage);
        onPageChange && onPageChange(selectedPage);
    };

    const onSaveChanges = () => {
        const preparedData = { excludedPoints }
        onSave(preparedData);
        setSaved(true);
    }

    return (
        <div
            className={cn(styles['interactive-chart__container'], { [styles['interactive-chart__container-closed']]: !isOpen })}
            {...restProps}
        >
            <div className={styles['interactive-chart__header']} onClick={toggleDropdown}>
                <h2 className={styles['interactive-chart__header-title']}>
                    <ChartIcon width={24} height={24} />
                    {title}
                </h2>
                <div
                    id="toggleDropdown"
                    className={styles['interactive-chart__header-toggle']}
                >
                    <ArrowIcon
                        className={cn(styles['interactive-chart__header-toggle-arrow'], {
                            [styles['interactive-chart__header-toggle-arrow-opened']]: isOpen,
                        })}
                    />
                </div>
            </div>
            <div
                id="dropdownContent"
                className={cn(styles['interactive-chart__dropdown'], { [styles['interactive-chart__dropdown--opened']]: isOpen })}
            >
                <div className={styles['interactive-chart__alert']} role="alert">
                    <WarningIcon /> If you want to exclude data points, you can click on any of them.
                </div>
                <ChartsRenderer
                    patients={patientData}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    onPointClick={handlePointClick}
                    excludedPoints={excludedPoints}
                />
                <div className={styles['interactive-chart__footer']}>
                    {saved && <span className={styles['interactive-chart__footer-saved-text']}>Patient profiles were saved.</span>}
                    {!saved && (
                        <Button
                            icon={TickIcon}
                            className={styles['interactive-chart__footer-button']}
                            onClick={onSaveChanges}>
                            Save
                        </Button>
                    )}
                    <div className={styles['interactive-chart__footer-pagination']}>
                        <button
                            id="prevPage"
                            className={styles['interactive-chart__footer-pagination-button']}
                            onClick={prevPage}
                            disabled={currentPage === 0}
                        >
                            <ArrowIcon />
                        </button>
                        <button
                            id="nextPage"
                            className={styles['interactive-chart__footer-pagination-button']}
                            onClick={nextPage}
                            disabled={(currentPage + 1) * pageSize >= totalPatients}
                        >
                            <ArrowIcon />
                        </button>

                        <Select
                            className={styles['interactive-chart__footer-pagination-select']}
                            revertButton={false}
                            options={patientsOptions}
                            selectedOption={patientsOptions[currentPage].value}
                            onChange={value => onChangePatients(+value)}
                        />
                        <span className={styles['interactive-chart__footer-pagination-count']}>of {totalPatients}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InteractiveChart;
