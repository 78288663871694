import { AxiosResponse } from 'axios';

import { AnalysisSettingsApi, AnalysisSettingsDto } from '../../swagger_api';
import { protectedConfiguration } from '../swagger';

class AnalysisSettingsService {
    analysisSettingsApi: AnalysisSettingsApi;

    constructor() {
        this.analysisSettingsApi = new AnalysisSettingsApi(protectedConfiguration);
    }

    update = async (analysisSettingsId: string, updatedAnalysisSettings: AnalysisSettingsDto): Promise<AxiosResponse<any>> => {
        try {
            const response = await this.analysisSettingsApi.update(updatedAnalysisSettings, analysisSettingsId);
            return response;
        } catch (error) {
            console.error('Error in login:', error);
            throw error;
        }
    };
}

export const analysisSettingsService = new AnalysisSettingsService();
