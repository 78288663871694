import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './Menu.module.scss';

export interface MenuOption {
    label: string | React.ReactNode;
    handler?: (value?: string) => void;
    icon?: string | React.ReactNode;
}

export interface MenuProps {
    options: MenuOption[];
    onToggle: () => void;
    isOpen: boolean;
    children: ReactNode;
    offsetLeft?: number;
    offsetTop?: number;
    stopPropagation?: boolean;
}

const Menu: React.FC<MenuProps> = ({ options, onToggle, isOpen, children, offsetLeft, offsetTop, stopPropagation }) => {
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleWithPropagation = useCallback((event) => {
      onToggle();
      if (stopPropagation) {
        event.stopPropagation();
      }
    },[onToggle, stopPropagation])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
              toggleWithPropagation(event);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, toggleWithPropagation]);

    return (
        <div ref={menuRef} className={styles.menu}>
            <div onClick={toggleWithPropagation} className={styles['menu__trigger']}>
                {children}
            </div>
            {isOpen && (
                <div className={styles['menu__list-container']} style={{ top: offsetTop, left: offsetLeft }}>
                    <ul className={styles['menu__list']}>
                        {options.map((option: MenuOption, index) => (
                            <li
                                key={index}
                                className={classNames(styles['menu__item'], styles['menu__link'])}
                                onClick={(event) => {
                                    option.handler?.(option.label as string);
                                    toggleWithPropagation(event);
                                }}
                            >
                                {option.icon}
                                {option.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Menu;
