import { useCallback, useRef } from 'react';

export const useMessagesScroll = () => {
  const outputsRef = useRef(null);
  const chatsRef = useRef(null);

  const handleScrollToOutputMessage = useCallback(
    (messageIds) => {
      if (outputsRef.current) {
        outputsRef.current.scrollToMessage(messageIds);
      }
    },
    [outputsRef],
  );

  const handleScrollToChatMessage = useCallback(
    (messageId) => {
      if (chatsRef.current) {
        chatsRef.current.scrollToMessage(messageId);
      }
    },
    [chatsRef],
  );

  return {
    outputsRef,
    chatsRef,
    handleScrollToOutputMessage,
    handleScrollToChatMessage,
  };
};