//import { compareItems, rankItem } from '@tanstack/match-sorter-utils';
//import { ColumnDef, FilterFn, SortingFn, sortingFns } from '@tanstack/react-table';

export const isNumeric = (value: string): boolean => {
  return !isNaN(Number(value));
};

export const transformNumericValues = (csvData) => {
  const transformedData = csvData.map((row) =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => [
        key,
        isNumeric(value as string) ? Number(value) : value,
      ]),
    ),
  );
  return transformedData;
};

export const findRepeatingValues = (data: any): { header: string; values: string[] }[] => {
  const valueCounts: Record<string, Record<string, number>> = {};

  // Count the occurrence of each non-numeric value for each header
  for (const row of data) {
    for (const [header, value] of Object.entries(row)) {
      if (isNumeric(value as string)) {
        continue; // Skip numeric values
      }

      if (!valueCounts[header]) {
        valueCounts[header] = {};
      }

      if (!valueCounts[header][value as string]) {
        valueCounts[header][value as string] = 0;
      }

      valueCounts[header][value as string]++;
    }
  }

  const result: { header: string; values: string[] }[] = [];

  // Filter headers with values repeating less than 10 times and collect these values
  for (const [header, counts] of Object.entries(valueCounts)) {
    const repeatingValues = Object.entries(counts).map(([value]) => value);

    if (repeatingValues.length > 0) {
      result.push({ header, values: repeatingValues });
    }
  }

  return result.filter((items) => items.values.length < 10);
};

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
//export const fuzzyFilter = (row, columnId, value, addMeta) => {
//  // Rank the item
//  //const itemRank = rankItem(row.getValue(columnId), value);

//  // Store the itemRank info
//  addMeta({
//    itemRank,
//  });

//  // Return if the item should be filtered in/out
//  return itemRank.passed;
//};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
//export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
//  let dir = 0;

//  // Only sort by rank if the column has ranking information
//  if (rowA.columnFiltersMeta[columnId]) {
//    dir = compareItems(
//      rowA.columnFiltersMeta[columnId]?.itemRank!,
//      rowB.columnFiltersMeta[columnId]?.itemRank!,
//    );
//  }

//  // Provide an alphanumeric fallback for when the item ranks are equal
//  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
//};

export const generateColumns = (data, selectFilters) => {
  if (!data || data.length === 0) return [];

  const firstRow = data[0];

  return Object.keys(firstRow).map((key) => {
    let filterVariant;
    if (selectFilters.some((filter) => filter.header === key)) {
      filterVariant = 'select';
    } else if (isNumeric(firstRow[key])) {
      filterVariant = 'range';
    } else {
      filterVariant = 'text';
    }

    return {
      accessorKey: key,
      id: key,
      header: key,
      meta: {
        filterVariant,
      },
      cell: (info) => info.getValue(),
      filterFn: filterVariant === 'text' ? 'includesString' : 'auto',
    };
  });
};
