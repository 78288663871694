import styles from "./AnalysisModalHeader.module.scss";
import useAnalysisModalStore from "./AnalysisModal.hook";


const AnalysisModalHeader = () => {
    const { studyName, activeBadge, setActiveBadge } = useAnalysisModalStore();

    return (
        <section className={styles.container}>
            <h1 className={styles.title}>
                {activeBadge}:<span className={styles.badgeNormal}>{studyName}</span>
            </h1>
            <div className={styles.detailsWrapper}>
                <div className={styles.badgeWrapper}>
                    <button
                        className={activeBadge === 'NCA' ? styles.badgeBold : styles.badgeNormal}
                        onClick={() => setActiveBadge('NCA')}
                    >
                        NCA
                    </button>
                    <button
                        className={activeBadge === 'POP-PK' ? styles.badgeBold : styles.badgeNormal}
                        onClick={() => setActiveBadge('POP-PK')}
                    >
                        POP-PK
                    </button>
                </div>
            </div>
        </section>
    );
};

export default AnalysisModalHeader;
