import { useCallback, useState } from 'react';
import { Socket } from 'socket.io-client';
import cn from "classnames";

import { useModal } from '../../../../components/Modal/Modal.hook';

import Button from '../../../../components/Button/Button.component';
import SpinnerLoading from '../../../../components/Loading/SpinnerLoading/SpinnerLoading';

import { ReactComponent as SaveFileIcon } from "../../../../icons/SaveFile.svg";
import { ReactComponent as TerminalIcon } from "../../../../icons/Terminal.svg";

import styles from "./EditCodeModal.module.scss";
import useEditableCodeStore from "./EditCodeModal.hook";
import useSendCode from './hooks/useSendCode';

const EditCodeModalFooter: React.FC<{ socket: Socket }> = ({ socket }) => {
  const {
    currentCode,
    resetState,
    fileName,
    isEdited,
    setIsEdited,
    originalKey,
  } = useEditableCodeStore();

  const { closeModal } = useModal();
  const { isLoading: loading, handleSave } = useSendCode(socket);

  const [savingType, setSavingType] = useState<null | 'execute' | 'save'>(null);

  const onCancel = useCallback(() => {
    closeModal();
    resetState();
    setIsEdited(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, resetState]);

  const isButtonDisabled = loading || !isEdited;

  const handleSaveArguments = { currentCode, originalKey, onSuccess: onCancel };

  const onHandleSave = (execute: boolean) => {
    setSavingType(execute ? 'execute' : 'save');
    handleSave({ ...handleSaveArguments, execute });
  };

  return (
    <div className={styles['edit-code-modal']}>
      <div className={styles['edit-code-modal__header']}>
        <p className={styles['edit-code-modal__header__subtitle']}>{fileName}</p>
      </div>
      <div className={styles['edit-code-modal__actions']}>
        <div className={styles['edit-code-modal__actions-combination']}>
          <Button
            className={cn(styles['edit-code-modal__actions-execute'], {
              [styles['disabled-area']]: isButtonDisabled,
            })}
            onClick={() => onHandleSave(true)}
            icon={loading && savingType === 'execute' ? SpinnerLoading : TerminalIcon}
          >
            Save & Execute
          </Button>
          <Button
            color="secondary"
            className={cn({
              [styles['disabled-area']]: isButtonDisabled,
            })}
            icon={loading && savingType === 'save' ? SpinnerLoading : SaveFileIcon}
            onClick={() => onHandleSave(false)}
          >
            Save to a New File
          </Button>
        </div>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditCodeModalFooter;
