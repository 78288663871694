import { RefObject, forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { Checkbox, Collapse } from 'antd';
import Button from 'src/components/Button/Button.component';

import { isNumeric } from '../../utils';
import styles from './Filters.module.scss';
import './Filters.style.scss';

export const CombinedFilter: React.FC<any> = forwardRef((props, ref) => {
  const {
    column: { setFilter, id, preFilteredRows },
  } = props;

  const containsRef: RefObject<HTMLInputElement> = useRef();
  const notContainsRef: RefObject<HTMLInputElement> = useRef();
  const startsWithRef: RefObject<HTMLInputElement> = useRef();
  const endsWithRef: RefObject<HTMLInputElement> = useRef();
  const minRef: RefObject<HTMLInputElement> = useRef();
  const maxRef: RefObject<HTMLInputElement> = useRef();
  const [selectedValues, setSelectedValues] = useState([]);

  const uniqueValues = useMemo(() => {
    const valueCounts = {};
    preFilteredRows.forEach((row) => {
      valueCounts[row.values[id]] = (valueCounts[row.values[id]] || 0) + 1;
    });
    return Object.keys(valueCounts).filter((item) => item !== 'undefined');
  }, [id, preFilteredRows]);

  const handleCheckboxChange = (value) => {
    const newValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    setSelectedValues(newValues);
  };

  const handleSelectAll = () => setSelectedValues(uniqueValues);
  const handleClearAll = () => setSelectedValues([]);

  useImperativeHandle(ref, () => ({
    clearFilters: () => {
      if (containsRef.current?.value) containsRef.current.value = '';
      if (notContainsRef.current?.value) notContainsRef.current.value = '';
      if (startsWithRef.current?.value) startsWithRef.current.value = '';
      if (endsWithRef.current?.value) endsWithRef.current.value = '';
      if (maxRef.current?.value) maxRef.current.value = null;
      if (minRef.current?.value) minRef.current.value = null;
      setSelectedValues([]);
    },
  }));

  const applyFilter = () => {
    setFilter({
      text: {
        contains: containsRef.current?.value || '',
        notContains: notContainsRef.current?.value || '',
        startsWith: startsWithRef.current?.value || '',
        endsWith: endsWithRef.current?.value || '',
      },
      number: {
        min: +minRef.current?.value || null,
        max: +maxRef.current?.value || null,
      },
      value: selectedValues,
    });
  };

  const isNumberValue = uniqueValues.every((v) => isNumeric(v));

  return (
    <div className={styles['filters-container']}>
      {!isNumberValue ? (
        <Collapse
          collapsible="header"
          className={styles['filters-collapse']}
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Text Filter',
              style: {
                padding: 0,
                border: 'none',
              },
              children: (
                <>
                  <input
                    className={styles['filters-input']}
                    ref={containsRef}
                    placeholder="Text Contains"
                  />
                  <input
                    className={styles['filters-input']}
                    ref={notContainsRef}
                    placeholder="Text Does Not Contain"
                  />
                  <input
                    className={styles['filters-input']}
                    ref={startsWithRef}
                    placeholder="Text Starts With"
                  />
                  <input
                    className={styles['filters-input']}
                    ref={endsWithRef}
                    placeholder="Text Ends With"
                  />
                </>
              ),
            },
          ]}
        />
      ) : (
        <Collapse
          collapsible="header"
          className={styles['filters-collapse']}
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Number Filter',
              style: {
                padding: 0,
                border: 'none',
              },
              children: (
                <>
                  <input
                    className={styles['filters-input']}
                    type="number"
                    ref={minRef}
                    placeholder="Min"
                  />
                  <input
                    className={styles['filters-input']}
                    type="number"
                    ref={maxRef}
                    placeholder="Max"
                  />
                </>
              ),
            },
          ]}
        />
      )}

      {uniqueValues.length > 0 && (
        <Collapse
          collapsible="header"
          className={styles['filters-collapse']}
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Values',
              children: (
                <div className={styles['filters-values']}>
                  <button onClick={handleSelectAll}>Select All</button>
                  <button onClick={handleClearAll}>Clear All</button>
                  {uniqueValues.map((value) => (
                    <div key={value} className={styles['filters-value']}>
                      <Checkbox
                        checked={selectedValues.includes(value)}
                        onChange={() => handleCheckboxChange(value)}
                      >
                        {value}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              ),
            },
          ]}
        />
      )}
      <div className={styles['filters-buttons']}>
        <Button onClick={applyFilter}>Apply</Button>
      </div>
    </div>
  );
});
