import { Text } from '../../components/Text/Text';
import Layout from '../Chat/components/Layout/Layout.component';
import styles from './Dashboard.module.scss';
import { DashboardTicket } from './components/DashboardTicket/DashboardTicket';
import { useDashboard } from './useDashboard';

const Dashboard = () => {
  const { dashboards } = useDashboard();

  return (
    <Layout>
      <Text.H3>Dashboard</Text.H3>
      <div className={styles.tickets}>
        {dashboards.map((dashboard) => (
          <DashboardTicket
            key={dashboard.jobName}
            name={dashboard.file}
            job={dashboard.jobName}
            asset={dashboard.asset}
          />
        ))}
      </div>
    </Layout>
  );
};
export default Dashboard;
