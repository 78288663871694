import { memo, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { AxiosError } from 'axios';
import classNames from 'classnames';
import { chatsService, usersService } from 'src/api';
// Import AxiosError
import useNotification from 'src/hooks/useNotification';
import { sortAndExtractChats } from 'src/pages/Chat/utils';
import { ErrorResponse } from 'src/types/chat';

import { useModal } from '../../../../../components/Modal/Modal.hook';
import { ModalName } from '../../../../../components/Modal/modalEnums';
import { ReactComponent as Logo } from '../../../../../icons/Logo.svg';
import { ReactComponent as CollapseIcon } from '../../../../../icons/collapse-icon.svg';
import { ReactComponent as PencilRightIcon } from '../../../../../icons/item-pencil-right-top.svg';
import { useChatStore } from '../../../Chat.hook';
import ChatListGroup from './ChatsList/ChatListGroup/ChatListGroup.component';
import ChatListSkeleton from './ChatsList/ChatListSkeleton/ChatListSkeleton.component';
import ManageChatModal from './ManageChatModal/ManageChatModal.component';
import styles from './Sidebar.module.scss';

const getChatById = (chatList, id) => chatList.find((chat) => chat.id === id);

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    selectedChat,
    chats,
    isLoading,
    setIsLoading,
    sharedChats,
    setSharedChats,
    setSelectedChat,
    toggleChat,
    isChatCollapsed,
  } = useChatStore();
  console.log('🚀 ~ Sidebar ~ isChatCollapsed:', isChatCollapsed);

  const processedChatIdsRef = useRef<string[]>([]);

  const { showModal } = useModal();
  const [searchParams] = useSearchParams();
  const currentChatId = searchParams.get('chatId');

  const { contextHolder, openNotification } = useNotification();

  const handleChatSelection = useCallback(
    (chatToSelect) => {
      if (chatToSelect && chatToSelect?.id !== selectedChat?.id) {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('chatId', chatToSelect.id);
        setSelectedChat(chatToSelect);
        navigate({ search: queryParams.toString() });
      }
    },
    [location.search, navigate, selectedChat?.id, setSelectedChat],
  );

  const activeChats = chats.filter((chat) => !chat.deleted);

  useEffect(() => {
    if (!chats.length && getChatById(activeChats, currentChatId)) return;

    const chatToSelect = currentChatId ? getChatById(activeChats, currentChatId) : activeChats[0];

    if (chatToSelect) {
      handleChatSelection(chatToSelect);
    }
  }, [currentChatId, chats, activeChats, handleChatSelection]);

  const fetchSharedChats = useCallback(async () => {
    try {
      const user = await usersService.getCurrentUser();
      const sharedChats = (user.data as any).sharedChats;
      const sortedChats = sortAndExtractChats(sharedChats);
      setSharedChats(sortedChats);
    } catch (error) {
      if ((error as AxiosError).isAxiosError) {
        const axiosError = error as AxiosError<ErrorResponse>;
        openNotification(
          axiosError.response?.data?.message || 'Failed to fetch shared chats',
          'error',
        );
      } else {
        openNotification('An unexpected error occurred', 'error');
      }
    }
  }, [setSharedChats, openNotification]);

  const addChatToListIfNeeded = useCallback(async () => {
    try {
      if (!currentChatId) return;

      setIsLoading(true);
      const { data } = await chatsService.saveSharedChat(currentChatId);
      await fetchSharedChats();
      setIsLoading(false);

      handleChatSelection(data);
    } catch (error) {
      setIsLoading(false);
      if (error.isAxiosError) {
        const axiosError = error as AxiosError<ErrorResponse>;
        openNotification(
          axiosError.response?.data?.message || 'Failed to add chat to list',
          'error',
        );
      } else {
        openNotification('An unexpected error occurred', 'error');
      }
    }
  }, [currentChatId, fetchSharedChats, handleChatSelection, setIsLoading, openNotification]);

  const checkAndAddChat = useCallback(async () => {
    if (processedChatIdsRef.current.includes(currentChatId)) return;

    processedChatIdsRef.current = [...processedChatIdsRef.current, currentChatId];

    const existingSharedChat = getChatById(sharedChats, currentChatId);
    if (!existingSharedChat) {
      try {
        const allChats = await chatsService.findAll();
        const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;
        const authorChat =
          getChatById(allChats, currentChatId) &&
          allChats.find((chat) => chat.user_id === userId && chat.id === currentChatId);

        if (!authorChat) {
          await addChatToListIfNeeded();
        }
      } catch (error) {
        if (error.isAxiosError) {
          const axiosError = error as AxiosError<ErrorResponse>;
          openNotification(
            axiosError.response?.data?.message || 'Failed to check and add chat',
            'error',
          );
        } else {
          openNotification('An unexpected error occurred', 'error');
        }
      }
    }
  }, [currentChatId, sharedChats, addChatToListIfNeeded, openNotification]);

  useEffect(() => {
    if (!isLoading) {
      checkAndAddChat();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAndAddChat]);

  return (
    <div
      className={classNames(styles['sidebar-container'], {
        [styles['sidebar-container-collapsed']]: isChatCollapsed,
      })}
    >
      {contextHolder}
      <ManageChatModal />
      <div className={styles['sidebar-container__logo']}>
        <CollapseIcon onClick={toggleChat} />
        <Logo />
        <PencilRightIcon onClick={() => showModal(ModalName.MANAGE_CHAT_MODAL)} />
      </div>
      <div className={styles['sidebar-container__list-container']}>
        {isLoading ? (
          <ChatListSkeleton />
        ) : (
          <div className={styles['sidebar-container__list-chats']}>
            {!!sharedChats.length && (
              <ChatListGroup
                shared
                chats={sharedChats}
                selectedChat={selectedChat}
                onSelectChat={handleChatSelection}
              />
            )}
            {!!activeChats.length && (
              <ChatListGroup
                chats={activeChats}
                selectedChat={selectedChat}
                onSelectChat={handleChatSelection}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Sidebar);
