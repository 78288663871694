import React from 'react';

import styles from '../Welcome.module.scss';

export interface CardProps {
  title: string;
  description: string;
  onClick?: () => void;
}

export const Card: React.FC<CardProps> = ({ title, description, onClick }) => (
  <div className={styles['welcome__card']} onClick={onClick}>
    <h2 className={styles['welcome__card-title']}>{title}</h2>
    <p className={styles['welcome__card-description']}>{description}</p>
  </div>
);
