import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { CSSTransition } from 'react-transition-group';

import { Popover } from 'antd';
import cn from 'classnames';
import { Text } from 'src/components/Text/Text';

import Button from '../../../../components/Button/Button.component';
import { ReactComponent as TermsIcon } from '../../../../icons/Terms.svg';
import { ReactComponent as FilterIcon } from '../../../../icons/filter-green.svg';
import { ReactComponent as SortingDown } from '../../../../icons/sorting-arrow-down.svg';
import { ReactComponent as SortingUp } from '../../../../icons/sorting-arrow-up.svg';
import { ReactComponent as TooltipIcon } from '../../../../icons/tooltip.svg';
import { Terms } from '../Terms/Terms';
import terms_admiral from '../Terms/terms_admiral.json';
import terms_tiger from '../Terms/terms_tiger.json';
import styles from './DatasetTable.module.scss';
import './DatasetTable.style.scss';
import { CombinedFilter } from './components/Filters/Filters';
import GlobalSearch from './components/GlobalSearch/GlobalSearch';
import Pagination from './components/Pagination/Pagination';

function DatasetTable({ csvData, datasets, openDataset, selectedDatasetId }) {
  const [filterVisibility, setFilterVisibility] = useState({});
  console.log('🚀 ~ DatasetTable ~ filterVisibility:', filterVisibility);
  const [termsOpened, setTermsOpened] = useState(false);

  const filterRef = useRef(null);

  const terms = useMemo(() => {
    const type = datasets.find((d) => d.id === selectedDatasetId)?.name || '';
    return type === 'admiral' ? terms_admiral : terms_tiger;
  }, [datasets, selectedDatasetId]);

  const toggleFilterVisibility = (columnId) => {
    setFilterVisibility((prev) => ({
      ...prev,
      [columnId]: !prev[columnId],
    }));
  };

  const createColumns = useCallback((data) => {
    if (!data.length) return [];
    const sampleRow = data[0];
    return Object.keys(sampleRow).map((key) => {
      const column = {
        Header: key.charAt(0).toUpperCase() + key.slice(1),
        accessor: key,
        Filter: ({ column }) => <CombinedFilter ref={filterRef} column={column} />,
        filter: 'filter',
      };

      return column;
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => createColumns(csvData), [csvData]);

  const resetAll = () => {
    setGlobalFilter('');
    setFilterVisibility({});
    setAllFilters([]);

    if (filterRef?.current) filterRef?.current?.clearFilters();
    setSortBy([]);
  };

  const defaultColumn = {
    Filter: () => null,
    Cell: ({ value }) => <span className="table-cell">{value}</span>,
  };

  const filterTypes = useMemo(
    () => ({
      filter: (rows, id, filterValue) => {
        let filteredRows = rows;

        if (filterValue.text) {
          const { contains, notContains, startsWith, endsWith } = filterValue.text;
          filteredRows = filteredRows.filter((row) => {
            const rowValue = row.values[id];
            if (!rowValue) return true;

            const value = rowValue.toString().toLowerCase();

            if (contains && !value.includes(contains.toLowerCase())) return false;
            if (notContains && value.includes(notContains.toLowerCase())) return false;
            if (startsWith && !value.startsWith(startsWith.toLowerCase())) return false;
            if (endsWith && !value.endsWith(endsWith.toLowerCase())) return false;
            return true;
          });
        }

        if (filterValue.number) {
          const { min, max } = filterValue.number;
          filteredRows = filteredRows.filter((row) => {
            const value = row.values[id];
            if (min && value < min) return false;
            if (max && value > max) return false;
            return true;
          });
        }

        if (filterValue.value.length) {
          filteredRows = filteredRows.filter((row) => filterValue.value.includes(row.values[id]));
        }

        return filteredRows;
      },
    }),
    [],
  );

  const tableProps = useTable(
    {
      columns,
      data: csvData,
      defaultColumn,
      filterTypes,
      initialState: {
        pageSize: 50,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    setAllFilters,
    setSortBy,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
  } = tableProps;

  const highlightSearchTerm = (text) => {
    if (!state.globalFilter || !text) return text;
    const regex = new RegExp(`(${state.globalFilter})`, 'gi');
    return text
      .split(regex)
      .map((part, index) =>
        part.toLowerCase() === state.globalFilter.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        ),
      );
  };

  const currentFilters = useMemo(() => {
    return state.filters.map((filter) => ({
      id: filter.id,
      value: filter.value,
    }));
  }, [state.filters]);

  return (
    <div className={styles['datasets']}>
      <div className={styles.sidebarActions}>
        <div className={styles.title}>Datasets</div>
        <div className={styles.sidebarActionsButtons}>
          <GlobalSearch globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
          <Button onClick={resetAll} className={styles.actionButton}>
            Reset Filters
          </Button>
          <div className={styles.buttons}>
            <Button
              icon={TermsIcon}
              className={styles.actionButton}
              onClick={() => setTermsOpened(!termsOpened)}
            >
              Show Terms
            </Button>
          </div>
        </div>
      </div>
      <div className={styles['datasets-group']}>
        <div className={styles['datasets-container']}>
          <table {...getTableProps()} className={styles['datasets-table']}>
            <thead className="">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th key={column.id}>
                        <div>
                          <span>{column.render('Header')}</span>
                          <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.sortedIndex === -1 ? <SortingDown /> : <SortingUp />}
                          </span>
                          <Popover
                            content={
                              <div>
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                              </div>
                            }
                            trigger="click"
                            placement="bottom"
                          >
                            <div
                              style={{ padding: 2 }}
                              className={cn({
                                [styles['datasets-table-filter-active']]: currentFilters.find(
                                  (col) => col.id === column.id,
                                ),
                              })}
                            >
                              <FilterIcon onClick={() => toggleFilterVisibility(column.id)} />
                            </div>
                          </Popover>

                          <Popover
                            content={
                              <Text.Small>
                                {terms.find((term) => term.name === column.id)?.description || ''}
                              </Text.Small>
                            }
                            placement="top"
                          >
                            <TooltipIcon className={styles['datasets-table-description']} />
                          </Popover>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.length && !page.every((item) => Object.values(item.values).every((v) => !v)) ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={row.id}>
                      {row.cells.map((cell, i) => (
                        <td {...cell.getCellProps()} key={`${cell.id}-${i}`}>
                          {highlightSearchTerm(cell.render('Cell').props.value)}
                        </td>
                      ))}
                    </tr>
                  );
                })
              ) : (
                <p className={styles['datasets-table-nodata']}>
                  Data is not available. Please check or reset your filters.
                </p>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          datasets={datasets}
          selectedDatasetId={selectedDatasetId}
          openDataset={openDataset}
          pageOptions={pageOptions}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          state={state}
          gotoPage={gotoPage}
        />
      </div>

      <CSSTransition in={termsOpened} timeout={100} classNames="terms" unmountOnExit>
        <Terms initialTerms={terms} />
      </CSSTransition>
    </div>
  );
}

export default memo(DatasetTable, (prevProps, nextProps) => {
  return prevProps.csvData.length === nextProps.csvData.length;
});
