import { create } from 'zustand';
import { ChatDto, MessageDto } from '@/swagger_api';
import { sortChatsByDate } from './utils';
import { processMessages } from './utils/messages';

interface ReplyModeState {
  messageId: string;
  codeFileName: string;
  assetFileName: string;
}

export enum DatasetType {
  Default = 'default',
  Clean = 'clean_dataset',
}

export interface ChatState {
  isLoading: boolean;
  chats: ChatDto[];
  sharedChats: ChatDto[];
  studies: any[];
  outputs: any[];
  cachedMessages: { [key: string]: { logMessages: MessageDto[]; chatMessages: MessageDto[] } };
  selectedChat: ChatDto | null;
  replyMode: ReplyModeState | null;
  messages: { chatMessages: MessageDto[]; logMessages: MessageDto[] };
  activeDataset: string;
  setActiveDataset: (dataset: string) => void;
  cleanDatasetIncluded: boolean;
  isChatCollapsed: boolean;
  isOutputsCollapsed: boolean;
  setCleanDatasetIncluded: (included: boolean) => void;
  setMessages: (chatMessages: MessageDto[], logMessages: MessageDto[], chatId: string) => void;
  addMessage: (message: MessageDto) => void;
  setIsLoading: (loading: boolean) => void;
  setChats: (chats: ChatDto[]) => void;
  setStudies: (studies: any[]) => void;
  setSelectedChat: (chat: ChatDto | null) => void;
  setOutputs: (outputs: any[]) => void;
  setReplyMode: (state: ReplyModeState | null) => void;
  setSharedChats: (chats: ChatDto[]) => void;
  toggleChat: () => void;
  toggleOutputs: () => void;
}

export const useChatStore = create<ChatState>((set, get) => ({
  isLoading: false,
  chats: [],
  sharedChats: [],
  studies: [],
  outputs: [],
  cachedMessages: {},
  selectedChat: null,
  replyMode: null,
  cleanDatasetIncluded: false,
  activeDataset: '',
  messages: { chatMessages: [], logMessages: [] },
  isChatCollapsed: false,
  isOutputsCollapsed: false,
  toggleChat: () => {
    const isChatCollapsed = get().isChatCollapsed;
    set({ isChatCollapsed: !isChatCollapsed });
  },
  toggleOutputs: () => {
    const isOutputsCollapsed = get().isOutputsCollapsed;
    set({ isOutputsCollapsed: !isOutputsCollapsed });
  },
  setActiveDataset: (dataset) => set({ activeDataset: dataset }),
  setCleanDatasetIncluded: (included) => set({ cleanDatasetIncluded: included }),
  setMessages: (chatMessages, logMessages, chatId) => {
    const cachedMessages = chatId
      ? { ...get().cachedMessages, [chatId]: { logMessages, chatMessages } }
      : get().cachedMessages;
    set({ cachedMessages, messages: { chatMessages, logMessages } });
  },
  addMessage: (message) => {
    const prevMessages = get().messages;
    const chatId = get().selectedChat?.id;
    if (!chatId) return;

    const { chatMessages, updatedLogMessages } = processMessages(message, prevMessages);

    const updatedCachedMessages = {
      ...get().cachedMessages,
      [chatId]: {
        chatMessages: [...(get().cachedMessages[chatId]?.chatMessages || []), message],
        logMessages: updatedLogMessages
      }
    };

    set({
      messages: { chatMessages, logMessages: updatedLogMessages },
      cachedMessages: updatedCachedMessages
    });
  },
  setIsLoading: (loading) => set({ isLoading: loading }),
  setChats: (chats) => set({ chats: sortChatsByDate(chats) }),
  setSelectedChat: (chat) => set({ selectedChat: chat, replyMode: null }),
  setStudies: (studies) => set({ studies }),
  setOutputs: (outputs) => set({ outputs }),
  setReplyMode: (state) => set({ replyMode: state }),
  setSharedChats: (sharedChats) => set({ sharedChats })
}));
