import React from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import routes from '../../../../../../router/routes';
import './Tabs.style.scss';

type RouteValues = (typeof routes)[keyof typeof routes];

interface Tab {
  key: string;
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

interface TabsProps {
  tabs: Tab[];
  onActiveTab: (tab: RouteValues) => void;
  activeTab: RouteValues;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onActiveTab }) => {
  return (
    <div className="tabs">
      {tabs.map(({ key, title, icon: Icon }) => (
        <Link key={key} to={key} style={{ height: '100%' }}>
          <div
            className={cn('tab', { tab__active: activeTab === key })}
            onClick={() => onActiveTab(key)}
          >
            <Icon />
            {title}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Tabs;
