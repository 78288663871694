import { useState, useCallback } from 'react';
import { Socket } from 'socket.io-client';
import { chatsService } from '../../../../../api';
import { useChatStore } from '../../../Chat.hook';
import { getFileNameWithoutQueryParams } from '../../Messages/components/assets/MessageAssetContainer.utils';

interface SendCodeParams {
  codeShouldBeExecuted?: boolean;
  commandContext: [string, string];
  fileName: string;
}

interface MessageMetadata {
  text: string;
  user_id: string | undefined;
  type: "R";
  url: string;
  name: string;
  message_type: "agent_asset";
  command_context: [string, string];
  internal: true;
  is_service_message?: boolean;
}

interface Message {
  chat_id: string;
  text: string;
  metadata: MessageMetadata;
  message_author: null;
}

interface HandleSaveParams {
  execute: boolean;
  currentCode: string;
  originalKey: string;
  onSuccess: () => void;
}

const RUN_CODE_MESSAGE_TEXT = 'Run the code';
const SAVE_CODE_MESSAGE_TEXT = 'Code is saved in output';

const useSendCode = (socket: Socket) => {
  const [isLoading, setIsLoading] = useState(false);
  const { selectedChat, setChats } = useChatStore();

  const sendCode = useCallback(async (params: SendCodeParams) => {
    const { codeShouldBeExecuted = false, commandContext, fileName } = params;

    setIsLoading(true);

    try {
      const userId = JSON.parse(localStorage.getItem('user') ?? '{}')?.user?.id;
      const [, modifiedFileName] = commandContext;

      const messageText = codeShouldBeExecuted ? RUN_CODE_MESSAGE_TEXT : SAVE_CODE_MESSAGE_TEXT;

      const message: Message = {
        chat_id: selectedChat.id,
        text: messageText,
        metadata: {
          text: messageText,
          user_id: userId,
          type: "R",
          url: fileName,
          name: modifiedFileName,
          message_type: "agent_asset",
          command_context: commandContext,
          internal: true,
        },
        message_author: null,
      };

      socket.emit('message', message);

      await chatsService.saveChatOutput({
        message_id: null,
        asset_file_path: null,
        code_file_path: fileName,
        chat_id: selectedChat.id,
      });

      const updatedChats = await chatsService.findAll();
      setChats(updatedChats);
    } catch (error) {
      console.error('Error sending edited code:', error);
    } finally {
      setIsLoading(false);
    }
  }, [socket, selectedChat.id, setChats]);

  const handleSave = useCallback(async (params: HandleSaveParams)  => {
    const { execute, currentCode, originalKey, onSuccess } = params;

    if (isLoading || !currentCode) return;

    setIsLoading(true);

    try {
      const savedAssetPath = await chatsService.editCodeAsset({
        updated_code: currentCode,
        original_key: originalKey,
      });

      const originalFileName = getFileNameWithoutQueryParams(originalKey);
      const modifiedFileName = getFileNameWithoutQueryParams(savedAssetPath);

      await sendCode({
        codeShouldBeExecuted: execute,
        commandContext: [originalFileName, modifiedFileName],
        fileName: savedAssetPath,
      });
    } catch (error) {
      console.error('Error saving/executing code:', error);
      alert('Failed to save/execute code');
    } finally {
      setIsLoading(false);
      onSuccess();
    }
  }, [isLoading, sendCode]);

  return { handleSave, setIsLoading, isLoading };
};

export default useSendCode;
