import { useMemo } from 'react';

import TableSkeleton from 'src/components/TableSkeleton/TableSkeleton';
import { isNumeric } from 'src/pages/Datasets/components/DatasetTable/utils';

import styles from './ChatTable.module.scss';

type ChatTableProps = {
  csvData: Record<string, string>[];
  isLoading: boolean;
  isChat?: boolean;
};

function roundToTwoDigits(number) {
  const roundedNumber = Math.round(number * 100) / 100;
  return roundedNumber % 1 === 0 ? Math.floor(roundedNumber) : roundedNumber;
}

const ChatTable = ({ csvData, isLoading }: ChatTableProps) => {
  const filteredData = useMemo(() => {
    return csvData.filter((row) => Object.values(row).every((value) => value));
  }, [csvData]);

  const headers = useMemo(() => {
    if (!csvData[0]) return [];
    return Object.keys(csvData[0]);
  }, [csvData]);

  if (isLoading || !headers) {
    return <TableSkeleton className={styles.skeleton} />;
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header.toLocaleLowerCase()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header) => {
                const value = isNumeric(row[header]) ? roundToTwoDigits(row[header]) : row[header];
                return <td key={header}>{value}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ChatTable;
