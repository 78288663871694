import { create } from 'zustand';
import { AnalysisSettingsDto } from '../../../../../swagger_api';

interface AnalysisSettingsState {
    nca: AnalysisSettingsDto | null;
    popPk: AnalysisSettingsDto | null;
    setAnalysisSettings: (nca?: AnalysisSettingsDto, popPk?: AnalysisSettingsDto) => void;
}

const useAnalysisSettingsStore = create<AnalysisSettingsState>((set) => ({
    nca: null,
    popPk: null,
    setAnalysisSettings: (nca, popPk) => set({ nca, popPk }),
}));

export default useAnalysisSettingsStore;
