import classNames from 'classnames';

import { ChatDto } from '@/swagger_api';

export const sortAndExtractChats = (sharedChats: any[]) => {
  return [...sharedChats]
    .sort((a: any, b: any) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);

      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        return 0; // No sorting if dates are invalid
      }

      return dateB.getTime() - dateA.getTime();
    })
    .map((chat: any) => chat.chat);
};

export const sortChatsByDate = (chats: ChatDto[]): ChatDto[] =>
  [...chats].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getCollapsedClassNames = (baseClass, collapsedClass, sidebarCollapsedClass, outputsCollapsedClass) => {
  return (isChatCollapsed, isOutputsCollapsed) => {
    if (isChatCollapsed && isOutputsCollapsed) {
      return classNames(baseClass, [collapsedClass]);
    }
    return classNames(baseClass, {
      [sidebarCollapsedClass]: isChatCollapsed,
      [outputsCollapsedClass]: isOutputsCollapsed,
    });
  };
};
