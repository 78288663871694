import { create } from 'zustand';

import { initialState as ncaInitialState } from './components/AnalysisNCAForm/AnalysisNCAForm.constant';
import { initialState as popPkInitialState } from './components/AnalysisPopPkForm/AnalysisPopPkForm.constant';


interface BadgeStore {
    activeBadge: string;
    setActiveBadge: (badge: string) => void;
}

interface ErrorStore {
    hasError: boolean;
    setHasError: (hasError: boolean) => void;
}


interface StudyStore {
    studyName: string;
    setStudyName: (name: string) => void;
}

type NCAState = typeof ncaInitialState;
type PopPKState = typeof popPkInitialState;


interface AnalysisStates {
    ncaState: NCAState;
    setNcaState: (state: NCAState) => void;
    popPkState: PopPKState;
    setPopPkState: (state: PopPKState) => void;
    ncaConfig: any[];
    setNcaConfig: (state: any[]) => void;
    popPkConfig: any[]
    setPopPkConfig: (state: any[]) => void;
}

const useAnalysisModalStore = create<BadgeStore & StudyStore & AnalysisStates & ErrorStore>((set) => ({
    activeBadge: 'NCA',
    setActiveBadge: (badge) => set({ activeBadge: badge }),
    hasError: false,
    setHasError: (hasError) => set({ hasError }),
    studyName: 'Dose Escalation Study_4',
    setStudyName: (name) => set({ studyName: name }),
    ncaState: ncaInitialState,
    setNcaState: (state) => set({ ncaState: state }),
    popPkState: popPkInitialState,
    setPopPkState: (state) => set({ popPkState: state }),
    ncaConfig: [],
    setNcaConfig: (state) => set({ ncaConfig: state }),
    popPkConfig: [],
    setPopPkConfig: (state) => set({ popPkConfig: state }),
}));

export default useAnalysisModalStore;
