import { useMemo } from 'react';

import { Button, Dropdown, MenuProps } from 'antd';
import cn from 'classnames';

import { DatasetDto } from '@/swagger_api';

import { ReactComponent as ArrowDown } from '../../../../../../icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '../../../../../../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../../../../icons/arrow-right.svg';
import styles from './Pagination.module.scss';

const Pagination = ({
  datasets,
  selectedDatasetId,
  openDataset,
  previousPage,
  canNextPage,
  canPreviousPage,
  nextPage,
  pageOptions,
  gotoPage,
  state,
}) => {
  const items: MenuProps['items'] = Array.from(pageOptions).map((page) => ({
    label: <div onClick={() => gotoPage(page)}>{(page as number) + 1}</div>,
    key: String(page),
  }));

  const sortedDatasets = useMemo(
    () =>
      datasets.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    [datasets],
  );


  return (
    <div className={styles['pagination']}>
      <div className={styles['pagination-datasets']}>
        {sortedDatasets.map((dataset: DatasetDto) => (
          <button
            key={dataset.id}
            className={cn(styles['pagination-datasets-item'], {
              [styles['pagination-datasets-item-selected']]: dataset.id === selectedDatasetId,
            })}
            onClick={() => openDataset(dataset)}
          >
            {dataset.name}
          </button>
        ))}
      </div>
      <div className={styles['pagination-list']}>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className={styles['pagination-arrow']}
        >
          <ArrowLeft />
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className={styles['pagination-arrow']}
        >
          <ArrowRight />
        </button>

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomRight"
          arrow
          overlayStyle={{ maxHeight: 200, overflow: 'auto', marginTop: -10 }}
        >
          <Button className={styles['pagination-btn']}>
            {state.pageIndex + 1}
            <ArrowDown />
          </Button>
        </Dropdown>
        <span>of {pageOptions.length} pages </span>
      </div>
    </div>
  );
};

export default Pagination;
