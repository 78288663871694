import React from 'react';
import cn from "classnames";
import { CommonMessage } from "../MessageResponse";
import { ReactComponent as SpinnerIcon } from "../../../../../../../icons/Spinner.svg";
import { ReactComponent as CodeFolderIcon } from "../../../../../../../icons/CodeFolder.svg";
import styles from "./InProgressMessage.module.scss";

interface InProgressMessageProps {
    isUserMessage: boolean;
    status?: string;
    datasetName?: string;
    text: string;
    actionButtonText?: string;
    onClickActionButton?: () => void;
}

const DatasetInfo: React.FC<{ datasetName: string; status?: string }> = ({ datasetName, status }) => (
    <div className={styles['progressMessage-datasetName']}>
        <CodeFolderIcon />
        {status && <div className={styles['progressMessage-completed-badge']}>{status}</div>}
        <span>{datasetName}</span>
    </div>
);

const LoadingText: React.FC<{ text: string }> = ({ text }) => (
    <div className={styles['progressMessage-text']}>
        <SpinnerIcon className={styles['progressMessage-icon']} />
        <span>{text}</span>
    </div>
);

const ActionButton: React.FC<{ actionButtonText: string; onClickActionButton?: () => void }> = ({ actionButtonText, onClickActionButton }) => (
    <button className={styles['progressMessage-actionButton']} onClick={onClickActionButton}>
        {actionButtonText}
    </button>
);

const BottomLoading: React.FC<{ text: string }> = ({ text }) => (
    <div className={styles['progressMessage-bottom-loading']}>
        <SpinnerIcon className={styles['progressMessage-icon']} />
        <span>{text}</span>
    </div>
);

const InProgressMessage: React.FC<InProgressMessageProps> = ({ isUserMessage, status, datasetName, text, actionButtonText, onClickActionButton }) => {
    const isCompleted = status === 'completed';

    return (
        <CommonMessage isUserMessage={isUserMessage}>
            <div className={cn(styles.progressMessage, {
                [styles['progressMessage-dataset']]: !!datasetName,
                [styles['progressMessage-dataset-completed']]: !!datasetName && isCompleted,
            })}>
                {datasetName && <DatasetInfo datasetName={datasetName} status={status} />}

                {!isCompleted ? (
                    <LoadingText text={text} />
                ) : (
                    actionButtonText && <ActionButton actionButtonText={actionButtonText} onClickActionButton={onClickActionButton} />
                )}
            </div>

            {isCompleted && <BottomLoading text={text} />}
        </CommonMessage>
    );
}

export default InProgressMessage;
