import CodeEditor from '../../../../../Jobs/components/JobPage/components/CodeEditor/CodeEditor.component';
import { JobCsvTable } from '../../../../../../pages/Jobs/components/JobPage/components/JobCsvTable';
import Charts from '../../components/assets/Charts';
import MessageAssetContainer from '../../components/assets/MessageAssetContainer';
import styles from './assets.module.scss';

const Asset = ({ asset, message, codeFile, socket, messageProcessing, scrollToMessage }) => {
  const commonProps = {
    socket,
    title: asset.name,
    message,
    messageProcessing,
    codeFile,
    scrollToMessage,
  };

  switch (asset.type) {
    case 'html':
      return (
        <MessageAssetContainer {...commonProps}>
          <Charts asset={asset.url} />
        </MessageAssetContainer>
      );
    case 'py':
    case 'R':
      return (
        <MessageAssetContainer {...commonProps} className={styles['interactive-chart-code']}>
          <CodeEditor code={asset.url} />
        </MessageAssetContainer>
      );
    case 'csv':
      return (
        <MessageAssetContainer {...commonProps} isOpenByDefault={false}>
          <JobCsvTable filePath={asset.url} />
        </MessageAssetContainer>
      );
    default:
      return null;
  }
};

export default Asset;
