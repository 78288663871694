import { Option } from "../Select/Select.component";

export interface DataPoint {
    x: number;
    y: number;
}

export interface Patient {
    id: string;
    label: string;
    data: DataPoint[];
}

const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substr(2, 5);

export function generateRandomPatientData(numPatients = 100, maxAnomalies = 1): Patient[] {
    const patients: Patient[] = [];
    const minDataPoints = 6; // Minimum number of data points per patient
    const maxDataPoints = 10; // Maximum number of data points per patient
    for (let i = 1; i <= numPatients; i++) {
        const halfLife = parseFloat((Math.random() * 50).toFixed(2)); // Ensure half-life is a number
        const randomPoints = Math.floor(Math.random() * (maxDataPoints - minDataPoints + 1)); // Random number of extra points
        const numDataPoints = Math.max(minDataPoints, randomPoints + minDataPoints); // Ensure at least minDataPoints
        const curveData: DataPoint[] = [];
        let anomalyCount = 0;
        for (let j = 0; j < numDataPoints; j++) {
            const t = (j / (numDataPoints - 1)) * 50; // Ensure points are evenly distributed
            let concentration = Math.round(100 * Math.exp(-t / halfLife));
            // Introduce anomalies randomly up to maxAnomalies times
            if (Math.random() < (maxAnomalies - anomalyCount) / (numDataPoints - j)) {
                concentration = Math.round(
                    concentration * (Math.random() < 0.5 ? Math.random() * 0.5 + 1.5 : Math.random() * 0.5 + 0.5)
                );
                anomalyCount++;
            }
            curveData.push({ x: t, y: concentration });
        }
        patients.push({
            id: generateUniqueId(),
            label: `Subject ${i}`,
            data: curveData,
        });
    }
    return patients;
}


export const generatePatientOptions = (totalPatients: number): Option[] => {
    const options: Option[] = [];
    let start = 1;
    let end = 4;

    while (start <= totalPatients) {
        options.push({ value: `${start}`, label: `${start}-${end} patients` });
        start = end + 1;
        end = start + 3;
    }

    return options;
};