import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthRedirect = (notFound?: boolean) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else if (notFound) {
      navigate('/chat');
    }
  }, [navigate, notFound, token]);
};

export default useAuthRedirect;
