import { createContext, ReactNode, useState } from 'react';
import { ModalName } from './modalEnums';

interface ModalContextType {
    initializeModal: (name: ModalName, content: ReactNode) => void;
    showModal: (name: ModalName) => void;
    closeModal: () => void;
    modalContent: ReactNode | null;
    activeModal: ModalName | null;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [activeModal, setActiveModal] = useState<ModalName | null>(null);
    const [modals, setModals] = useState({});

    const initializeModal = (name: ModalName, content: ReactNode) => {
        setModals(windows => ({ ...windows, [name]: content }))
    }

    const showModal = (name: ModalName) => {
        setModalContent(modals[name]);
        setActiveModal(name);
    };

    const closeModal = () => {
        setModalContent(null);
        setActiveModal(null);
    };

    return (
        <ModalContext.Provider value={{ showModal, closeModal, modalContent, initializeModal, activeModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalContext;
