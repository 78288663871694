import { ReactComponent as Chat } from '../../../../../icons/Chat.svg';
import { ReactComponent as Dashboard } from '../../../../../icons/Dashboard.svg';
import { ReactComponent as Datasets } from '../../../../../icons/Datasets.svg';
import { ReactComponent as Jobs } from '../../../../../icons/Jobs.svg';
import routes from '../../../../../router/routes';

export const tabs = [
  { key: routes.CHAT, title: 'Chat', icon: Chat },
  { key: routes.DATASETS, title: 'Datasets', icon: Datasets },
  { key: routes.DASHBOARD, title: 'Dashboard', icon: Dashboard },
  { key: routes.JOBS, title: 'Jobs', icon: Jobs },
];
