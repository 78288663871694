import cn from 'classnames';

import Dropdown from '../../../../../components/Dropdown/Dropdown.component';
import { Paper } from '../../../../../components/Paper/Paper';
import { ReactComponent as CodeIcon } from '../../../../../icons/jobs/code.svg';
import { ReactComponent as GraphIcon } from '../../../../../icons/jobs/graph.svg';
import { ReactComponent as TableIcon } from '../../../../../icons/jobs/table.svg';
import { AssetDto } from '../../../../../swagger_api';
import styles from '../JobPage.module.scss';

export const JobsSidebar = ({
  allJobs,
  currentJob,
  currentView,
  openedAssets,
  setOpenedAssets,
  setCurrentView,
}) => {
  return (
    <Paper className={styles.list}>
      {allJobs.map((job) => (
        <div key={job.id} className={styles.job}>
          <Dropdown title={job.name} opened={currentJob.id === job.id}>
            {job.assets.map((asset: AssetDto) => {
              const assetType = asset.name.split('.').pop();
              const AssetIcon =
                assetType === 'csv' ? TableIcon : assetType === 'png' ? GraphIcon : CodeIcon;

              return (
                <div
                  key={asset.id}
                  className={cn({
                    [styles.asset]: true,
                    [styles.assetOpened]: currentView?.name === asset.name,
                  })}
                  onClick={() => {
                    setOpenedAssets(
                      openedAssets.includes(asset) ? openedAssets : [...openedAssets, asset],
                    );
                    setCurrentView(asset);
                  }}
                >
                  <AssetIcon />
                  {asset.name}
                </div>
              );
            })}
          </Dropdown>
        </div>
      ))}
    </Paper>
  );
};
