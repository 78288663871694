import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import moment from 'moment';

import { TableSkeleton } from '../../../../components/Tables/Skeleton/SkeletonTable';
import { ReactComponent as RepeatIcon } from '../../../../icons/jobs/repeat.svg';
import { ReactComponent as ViewIcon } from '../../../../icons/jobs/view.svg';
import { JobDto } from '../../../../swagger_api';
import { JobStatus } from '../../../../types/enums/job-status';
import { JobsData } from '../../hooks/useJobsData';
import styles from './JobsTable.module.scss';

type JobsTableProps = {
  jobs: JobsData;
  isLoading: boolean;
};

const headers = [
  'Type',
  'ID',
  'Analysis Type',
  'Method',
  'Start Date',
  'Time Running',
  'OFV',
  'Actions',
];

const JobStatusAction = {
  [JobStatus.COMPLETED]: 'View',
  [JobStatus.IN_PROGRESS]: null,
  [JobStatus.QUEUED]: 'Retry',
  [JobStatus.CANCELED]: 'Retry',
  [JobStatus.FAILED]: 'Retry',
};

const getRows = (job: JobDto) => {
  return {
    type: job.type,
    id: job.name,
    jobId: job.id,
    method: job.method,
    created_at: moment(job.created_at).format('MMMM D YYYY'),
    time_running: moment(job.updated_at).fromNow(),
    field: job.field,
    actions: JobStatusAction[job.status],
    status: job.status,
  };
};

const JobStatusTable = ({ rows }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const renderTypeCell = (type) => {
    switch (type) {
      case 'POP-PK':
        return <div className={cn(styles.jobType, styles.jobTypePop)}>{type}</div>;
      case 'NCA':
        return <div className={cn(styles.jobType, styles.jobTypeNca)}>{type}</div>;
      default:
        return null;
    }
  };

  const renderActionsCell = (job) => {
    if (job.status === JobStatus.IN_PROGRESS) {
      return <td className={styles.tableCell}>-</td>;
    }

    const onRetry = () => {
      console.log('Retry job', job);
    };

    const onView = () => {
      queryParams.set('jobId', job.jobId);
      navigate({
        search: queryParams.toString(),
      });
    };

    return (
      <td className={styles.tableCell}>
        {job.actions === 'Retry' ? (
          <button className={styles.actionButton} onClick={onRetry}>
            <RepeatIcon />
            Retry
          </button>
        ) : (
          <button className={styles.actionButton} onClick={onView}>
            <ViewIcon />
            View
          </button>
        )}
      </td>
    );
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} className={`${styles.tableCell} ${styles.tableHeader}`}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index} className={index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd}>
            <td className={styles.tableCell}>{renderTypeCell(row.type)}</td>
            <td className={styles.tableCell}>{row.id}</td>
            <td className={styles.tableCell}>{row.method}</td>
            <td className={styles.tableCell}>{row.created_at}</td>
            <td className={styles.tableCell}>{row.time_running}</td>
            <td className={styles.tableCell}>{row.field}</td>
            {renderActionsCell(row)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const JobsTables = ({ jobs, isLoading }: JobsTableProps) => {
  if (isLoading) {
    return <TableSkeleton />;
  }

  const tablesToRender = [
    { title: 'Done', rows: jobs[JobStatus.COMPLETED].map((job) => getRows(job)) },
    { title: 'In Progress', rows: jobs[JobStatus.IN_PROGRESS].map((job) => getRows(job)) },
    { title: 'Failed', rows: jobs[JobStatus.FAILED].map((job) => getRows(job)) },
    { title: 'Canceled', rows: jobs[JobStatus.CANCELED].map((job) => getRows(job)) },
    { title: 'Queued', rows: jobs[JobStatus.QUEUED].map((job) => getRows(job)) },
  ].filter(({ rows }) => rows.length > 0);

  return (
    <div className={styles.cont}>
      {tablesToRender.map(({ title, rows }, index) => (
        <React.Fragment key={`${index}-${title}`}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.tableCont}>
            <JobStatusTable rows={rows} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default JobsTables;
