import { ReactNode } from 'react';
import styles from './Modal.module.scss';
import cn from 'classnames';

interface ModalContentProps {
    header: ReactNode;
    body: ReactNode;
    footer: ReactNode;
    className?: string;
    classNameBody?: string;
}

const ModalContent = ({ header, body, footer, className, classNameBody}: ModalContentProps) =>
    <div className={cn(styles['modal__content-wrapper'], className)}>
        <header className={styles['modal__header']}>{header}</header>
         <main className={cn(styles['modal__body'], classNameBody)}>{body}</main>
        {footer && <footer className={styles['modal__footer']}>{footer}</footer>}
    </div >

export default ModalContent;
