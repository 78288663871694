import styles from './JobsLayout.module.scss';

const JobsLayout = ({ children }) => {
  return (
    <div className={styles.jobsLayout}>
      <h2 className={styles.headerTitle}>Jobs</h2>
      {children}
    </div>
  );
};

export default JobsLayout;
