import React, { useState } from 'react';
import cn from 'classnames';
import { chatsService } from 'src/api';
import { useChatStore } from 'src/pages/Chat/Chat.hook';
import { useShareChat } from 'src/pages/Chat/hooks/useShareChat.hook';
import { ChatDto } from '@/swagger_api';

import Menu from '../../../../../../../components/Menu/Menu.component';
import { useModal } from '../../../../../../../components/Modal/Modal.hook';
import { ModalName } from '../../../../../../../components/Modal/modalEnums';
import useManageChatModalStore from '../../ManageChatModal/ManageChatModal.hook';
import useNotification from 'src/hooks/useNotification';  
import styles from './ChatListGroup.module.scss';

interface ChatListGroupProps {
  chats: ChatDto[];
  selectedChat: ChatDto;
  onSelectChat: (chat: ChatDto) => void;
  shared?: boolean;
}

const ChatListGroup: React.FC<ChatListGroupProps> = ({
  chats,
  shared = false,
  selectedChat,
  onSelectChat,
}) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [chatMenuId, setChatMenuId] = useState<string | null>(null);
  const { getIsShared, getSharedChatAuthor } = useShareChat();
  const { showModal } = useModal();
  const { setMode, setChatName, setChatId, setTitle, setActionButtonText } =
    useManageChatModalStore();
  const { setChats } = useChatStore();
  const { contextHolder, openNotification } = useNotification();  

  const handleMenuToggle = (chatId: string) => {
    setChatMenuId(chatId);
    setIsMenuOpened((prevState) => (chatId === chatMenuId ? !prevState : true));
  };

  const copyChatUrlToClipboard = () => {
    const url = window.location.href;
    const chatUrl = new URL(url);
    chatUrl.searchParams.set('chatId', chatMenuId);

    navigator.clipboard.writeText(chatUrl.href).then(
      async () => {
        const chat = chatDetails.find((chat) => chat.id === chatMenuId);
        if (chat) {
          openNotification(
            <p>
              The link to <span>{chat.name}</span> has been copied
            </p>
          ); 

          const optimisticChats = [...chats];
          const index = optimisticChats.findIndex((chat) => chat.id === chatMenuId);
          optimisticChats[index] = { ...optimisticChats[index], shared: true };

          setChats(optimisticChats);

          await chatsService.updateChat(chatMenuId, { shared: true });
          const updatedChats = await chatsService.findAll();
          setChats(updatedChats);
        }
      },
      (err) => {
        console.error('Failed to copy URL: ', err);
      },
    );
  };

  const chatDetails = chats.map((chat) => ({
    ...chat,
    youSharedIt: chat.shared,
    isShared: getIsShared(chat),
    sharedAuthor: getIsShared(chat) ? getSharedChatAuthor(chat) : null,
  }));

  const MENU_OPTIONS = [
    {
      label: 'Edit',
      handler: () => {
        const chatToEdit = chatDetails.find((chat) => chat.id === chatMenuId);
        if (chatToEdit) {
          showModal(ModalName.MANAGE_CHAT_MODAL);
          setMode('edit');
          setChatName(chatToEdit.name);
          setChatId(chatToEdit.id);
        }
      },
    },
    {
      label: 'Share',
      handler: copyChatUrlToClipboard,
    },
    {
      label: 'Delete',
      handler: async () => {
        const chatToDelete = chatDetails.find((chat) => chat.id === chatMenuId);
        if (chatToDelete) {
          showModal(ModalName.MANAGE_CHAT_MODAL);
          setMode('delete');
          setChatName(chatToDelete.name);
          setChatId(chatToDelete.id);
          setTitle(`Are you sure you want to delete the ${chatToDelete.name} chat?`);
          setActionButtonText('Delete');
        }
      },
    },
  ];

  const SHARED_MENU_OPTIONS = [
    {
      label: 'Delete',
      handler: async () => {
        const chatToDelete = chatDetails.find((chat) => chat.id === chatMenuId);
        if (chatToDelete) {
          showModal(ModalName.MANAGE_CHAT_MODAL);
          setMode('delete_shared');
          setChatName(chatToDelete.name);
          setChatId(chatToDelete.id);
          setTitle(`Are you sure you want to delete the ${chatToDelete.name} chat?`);
          setActionButtonText('Delete');
        }
      },
    }
  ];

  return (
    <div className={cn(styles.list, { [styles['list-full']]: !shared })}>
      {contextHolder}
      <ul className={styles['list--item__list']}>
        {chatDetails.map((chat) => (
          <li
            className={cn(styles['list--item__card'], {
              [styles['list--item__card__active']]: selectedChat?.id === chat.id,
              [styles['list--item__card__shared']]: shared,
            })}
            key={chat.id}
          >
            <div className={styles['list--item__card--content']} onClick={() => onSelectChat(chat)}>
              {chat.youSharedIt && !chat.isShared && (
                <p className={styles['list--item__card--shared']}>
                  <b>You shared this</b>
                </p>
              )}  
              {chat.isShared && (
                <p className={styles['list--item__card--shared']}>
                  <b>Shared by {chat.sharedAuthor}</b>
                </p>
              )}
              <h1 className={styles['list--item__card--title']}>{chat.name}</h1>
              <h2 className={styles['list--item__card--subtitle']}>
                {(chat as any)?.dataset?.name}
              </h2>
            </div>

            <Menu
              offsetLeft={-57}
              offsetTop={27}
              onToggle={() => handleMenuToggle(chat.id)}
              isOpen={chat.id === chatMenuId && isMenuOpened}
              options={shared ? SHARED_MENU_OPTIONS : MENU_OPTIONS}
            >
              <button
                className={cn(styles['list--item__menu-button'], {
                  [styles['list--item__menu-button--active']]: selectedChat?.id === chat.id,
                })}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </Menu>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatListGroup;
