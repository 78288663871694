const mockDashboards = [
  {
    file: 'Nca_summary_chart.html',
    jobName: 'nca_185',
    asset: '1',
  },
  {
    file: 'pk_sim_aggr.html',
    jobName: 'nca_186',
    asset: '2',
  },
];

export const useDashboard = () => {
  return { dashboards: mockDashboards };
};
