import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { LOGIN_ROUTE } from 'src/router/routes';

const isTimeoutDisabled = process.env.REACT_APP_SESSION_TIMEOUT_DISABLED === 'true';

export const useSessionTimeout = () => {
  const timeoutRef = useRef(null);
  const warningRef = useRef(null);
  const sessionTimeout = 30 * 60 * 1000; // 30 minutes in milliseconds
  const warningTime = 25 * 60 * 1000; // 25 minutes in milliseconds

  const navigate = useNavigate();

  useEffect(() => {
    startTimer();

    const events = ['click', 'mousemove', 'keydown', 'scroll', 'touchstart'];
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimer = () => {
    timeoutRef.current = setTimeout(() => {
      if (!isTimeoutDisabled) {
        handleLogout();
      }
    }, sessionTimeout);

    warningRef.current = setTimeout(() => {
      toast('Your session will expire soon due to inactivity.');
    }, warningTime);
  };

  const resetTimer = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (warningRef.current) clearTimeout(warningRef.current);

    startTimer();
  };

  const handleLogout = () => {
    toast('Your session was terminated due to inactivity');

    setTimeout(() => {
      navigate(LOGIN_ROUTE);
      localStorage.setItem('accessToken', '');
    }, 2500);
  };
};
