import React, { ChangeEvent } from 'react';

import cn from 'classnames';

import './Input.style.scss';

interface InputProps {
  type: 'text' | 'password';
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  isValid?: boolean;
  errorMessage?: string;
  required?: boolean;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  placeholder = '',
  className = '',
  isValid = true,
  errorMessage = '',
  inputRef,
  ...rest
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={cn('ds-input', className)}>
      <input
        type="text"
        value={value}
        ref={inputRef}
        onChange={handleChange}
        placeholder={placeholder}
        className={`input ${isValid ? '' : 'input-error'}`}
        aria-invalid={!isValid}
        aria-describedby={isValid ? '' : 'error-message'}
        {...rest}
      />
      {!isValid && (
        <span id="error-message" className="error-message">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default Input;
