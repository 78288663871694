import { useCallback, useEffect, useState } from 'react';

import Papa from 'papaparse';
import ChatTable from 'src/pages/Chat/components/Messages/components/ChatTable/ChatTable';

export const JobCsvTable = ({ filePath }) => {
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState<any[]>([]);

  const getCsvTable = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(filePath);
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let csv = '';
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        csv += decoder.decode(value, { stream: !done });
      }

      const results = await Papa.parse(csv, { header: true });
      const rows = results.data;
      setCsvData(rows);

      setTimeout(() => {
        setLoading(false);
      }, 700);
    } catch (error) {
      console.log('error:', error);
    }
  }, [filePath]);

  useEffect(() => {
    getCsvTable();
  }, [getCsvTable]);

  return <ChatTable csvData={csvData} isLoading={loading} isChat={true} />;
};
