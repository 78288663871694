import classNames from 'classnames';

import styles from './Text.module.scss';
import './Text.style.scss';

const BaseText = (props) => {
  const className = classNames({
    [styles.text]: true,
    [props.className]: !!props.className,
  });
  const Tag = props.tag || 'p';

  return (
    <Tag {...props} className={className}>
      {props.children}
    </Tag>
  );
};

const H1 = (props) => {
  const className = classNames({
    [styles.h1]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className} tag={'h1'}>
      {props.children}
    </BaseText>
  );
};

const H2 = (props) => {
  const className = classNames({
    [styles.h2]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className} tag={'h2'}>
      {props.children}
    </BaseText>
  );
};

const H3 = (props) => {
  const className = classNames({
    [styles.h3]: true,
    [styles.text]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className} tag={'h3'}>
      {props.children}
    </BaseText>
  );
};

const H4 = (props) => {
  const className = classNames({
    [styles.h4]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className} tag={'h4'}>
      {props.children}
    </BaseText>
  );
};

const H5 = (props) => {
  const className = classNames({
    [styles.h5]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className} tag={'h5'}>
      {props.children}
    </BaseText>
  );
};

const Bigger = (props) => {
  const className = classNames({
    [styles.bigger]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className}>
      {props.children}
    </BaseText>
  );
};

const Big = (props) => {
  const className = classNames({
    [styles.big]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className}>
      {props.children}
    </BaseText>
  );
};

const Normal = (props) => {
  const className = classNames({
    [styles.normal]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className}>
      {props.children}
    </BaseText>
  );
};

const Regular = (props) => {
  const className = classNames({
    [styles.regular]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className}>
      {props.children}
    </BaseText>
  );
};

const Small = (props) => {
  const className = classNames({
    [styles.small]: true,
    [props.className]: !!props.className,
  });
  return (
    <BaseText {...props} className={className}>
      {props.children}
    </BaseText>
  );
};

export const Text = Object.assign(BaseText, {
  H1,
  H2,
  H3,
  H4,
  H5,
  Bigger,
  Big,
  Normal,
  Regular,
  Small,
});
