import { create } from 'zustand';

interface EditableCodeStoreState {
    fileName: string;
    currentCode: string;
    isEdited: boolean;
    loading: boolean;
    originalKey: string; 
    setCurrentCode: (code: string) => void;
    setIsEdited: (edited: boolean) => void;
    setLoading: (loading: boolean) => void;
    setOriginalKey: (key: string) => void; 
    setFileName: (filename: string) => void;
    resetState: () => void;
}

const useEditableCodeStore = create<EditableCodeStoreState>((set) => ({
    fileName: '',
    currentCode: '',
    isEdited: false,
    loading: false,
    originalKey: '',
    setCurrentCode: (code: string) => set({ currentCode: code }),
    setIsEdited: (edited: boolean) => set({ isEdited: edited }),
    setLoading: (loading: boolean) => set({ loading: loading }),
    setOriginalKey: (key: string) => set({ originalKey: key }), 
    setFileName: (filename: string) => set({fileName: filename}),
    resetState: () => set({ currentCode: '', isEdited: false, loading: false, originalKey: '', fileName: '' }),
}));

export default useEditableCodeStore;
