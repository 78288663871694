import { useCallback, useEffect, useRef, useState } from 'react';

import { Input } from 'antd';
import { throttle } from 'lodash';
import Button from 'src/components/Button/Button.component';

import { ReactComponent as SearchIcon } from '../../../../../../icons/Search.svg';
import styles from './GlobalSearch.module.scss';

const GlobalSearch = ({ globalFilter, setGlobalFilter }) => {
  const [searchIndex, setSearchIndex] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const searchTermRef = useRef(globalFilter || '');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetGlobalFilter = useCallback(
    throttle((value) => setGlobalFilter(value), 1000),
    [setGlobalFilter],
  );

  useEffect(() => {
    if (searchValue.length >= 1 || searchValue.length === 0) throttledSetGlobalFilter(searchValue);
  }, [searchValue, throttledSetGlobalFilter]);

  useEffect(() => {
    if (globalFilter) {
      setSearchIndex(0);
      const allCells = document.querySelectorAll('.table-cell');
      const matches = [];
      allCells.forEach((cell, index) => {
        if (cell.textContent.toLowerCase().includes(globalFilter.toLowerCase())) {
          matches.push(index);
        }
      });
      setSearchResults(matches);
    } else {
      setSearchValue('');
      searchTermRef.current = '';
      setSearchResults([]);
    }
  }, [globalFilter]);

  const nextResult = () => {
    setSearchIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
  };

  const prevResult = () => {
    setSearchIndex((prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length);
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      const allCells = document.querySelectorAll('.table-cell');
      allCells[searchResults[searchIndex]].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [searchIndex, searchResults]);

  return (
    <div className={styles['search-container']}>
      <Input
        ref={searchTermRef}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search"
        className={styles.searchInput}
        prefix={<SearchIcon />}
      />

      {globalFilter && false && (
        <span>
          <Button onClick={prevResult}>Previous</Button>
          <Button onClick={nextResult}>Next</Button>
          <span>
            Showing {searchIndex + 1} of {searchResults.length}
          </span>
        </span>
      )}
    </div>
  );
};

export default GlobalSearch;
