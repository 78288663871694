import React from 'react';

import cn from 'classnames';

import './Button.style.scss';

interface ButtonProps {
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  color?: 'primary' | 'secondary';
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  icon: Icon,
  color = 'primary',
  iconClassName,
  iconColor,
  type = 'button',
  disabled = false,
  className = '',
  fullWidth,
  children,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  const buttonClassName = cn({
    'ds-button': true,
    [`ds-button__${color}`]: true,
    'ds-button__full-width': fullWidth,
    [className]: true,
  });

  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={buttonClassName}
      aria-disabled={disabled}
    >
      {Icon && (
        <Icon className={cn(`ds-button-icon, ds-button-icon__${iconColor}`, iconClassName)} />
      )}
      {children}
    </button>
  );
};

export default Button;
