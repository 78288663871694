import cn from 'classnames';

import Button from '../../../../../../components/Button/Button.component';
import styles from './Suggestions.module.scss';

type SuggestionsProps = {
  suggestions: Suggestions;
  sendMessage: any;
  isShared: boolean;
};

type Suggestions = {
  button_type: string;
  button_content: string[];
};

export const RenderSuggestions = ({ suggestions, isShared, sendMessage }: SuggestionsProps) => {
  return (
    <>
      {suggestions.button_content.map((suggestion, index) => {
        const buttonClassName = cn({
          [styles['suggestionButton']]: true,
        });

        return (
          <Button
            key={index}
            disabled={isShared}
            onClick={() => {
              sendMessage(suggestion, {
                text: suggestion,
                message_type: 'human_button',
                button_type: suggestions.button_type,
              });
            }}
            className={buttonClassName}
          >
            {suggestion}
          </Button>
        );
      })}
    </>
  );
};
