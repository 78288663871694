import { useMemo, useState } from 'react';

import cn from 'classnames';

import { TableSkeleton } from '../../../../components/Tables/Skeleton/SkeletonTable';
import { ReactComponent as ArrowDown } from '../../../../icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../../icons/arrow-up.svg';
import { DatasetDto } from '../../../../swagger_api';
import styles from './DatasetsList.module.scss';

type DatasetProps = {
  datasets: DatasetDto[];
  openDataset: (dataset: DatasetDto) => void;
  selectedDataset: string;
};

export const DatasetsList = ({ datasets, openDataset, selectedDataset }: DatasetProps) => {
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });

  const sortedDatasets = useMemo(() => {
    let sortableDatasets = [...datasets];
    if (sortConfig !== null) {
      sortableDatasets.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableDatasets;
  }, [datasets, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name: string) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.direction === 'ascending' ? styles.asc : styles.desc;
  };

  const headerRenderer = ({ label, dataKey }) => {
    const isSorted = sortConfig.key === dataKey;
    const sortDirection = isSorted ? (
      sortConfig.direction === 'ascending' ? (
        <ArrowDown className={styles.arrowDown} />
      ) : (
        <ArrowUp className={styles.arrowUp} />
      )
    ) : (
      ''
    );
    return (
      <span>
        {label}
        {sortDirection}
      </span>
    );
  };

  return !datasets.length ? (
    <TableSkeleton />
  ) : (
    <div className={styles.datasetTableCont}>
      <div className={styles.sidebarActions}>
        <div className={styles.title}>Datasets</div>
      </div>
      <table className={styles.datasetTable}>
        <thead>
          <tr>
            <th
              className={cn(styles.datasetName, getClassNamesFor('name'))}
              onClick={() => requestSort('name')}
            >
              {headerRenderer({ label: 'Dataset Name', dataKey: 'name' })}
            </th>
            <th className={styles.smaller}></th>
            <th className={styles.smaller}>Rows</th>
            <th className={styles.smaller}>Parent Dataset</th>
            <th className={styles.bigger}>Description</th>
          </tr>
        </thead>
        <tbody>
          {sortedDatasets.map((dataset) => (
            <tr
              key={dataset.id}
              className={cn(styles.dataset, {
                [styles.selected]: dataset.id === selectedDataset,
              })}
              onClick={() => openDataset(dataset)}
            >
              <td className={styles.name}>{dataset.name}</td>
              <td>
                <div className={styles.tag}>{dataset.tag}</div>
              </td>
              <td className={styles.rows}>{dataset.rows_count}</td>
              {dataset.details && <td className={styles.description}>{dataset.details}</td>}
              <td className={styles.description}>{dataset.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
