import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import './skeleton.scss';

export const TableSkeleton = () => {
  const columns = Array.from({ length: 4 }).map((i) => {
    return (
      <th key={i as number}>
        <h1>
          <Skeleton />
        </h1>
      </th>
    );
  });

  const rowColumns = Array.from({ length: 4 }).map((i) => {
    return (
      <td key={i as number}>
        <Skeleton />
      </td>
    );
  });

  const rows = Array.from({ length: 25 }).map((i) => {
    return <tr key={i as number}>{rowColumns}</tr>;
  });

  return (
    <SkeletonTheme highlightColor="#fff">
      <table width="100%" className="skeleton-table">
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </SkeletonTheme>
  );
};
