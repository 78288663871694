import { notification } from 'antd';
import { ReactComponent as CloseIcon } from 'src/icons/close.svg';

import styles from './styles.module.scss';

interface NotificationConfig {
  closeIcon?: boolean;
  type?: 'success' | 'error' | 'info' | 'warning';
  [key: string]: any;
}

const useNotification = (config?: NotificationConfig) => {
  const [api, contextHolder] = notification.useNotification({ stack: false });

  const getNotificationStyle = (type: NotificationConfig['type']) => {
    switch (type) {
      case 'error':
        return { background: 'var(--notification-error)' }; 
      case 'success':
        return { background: 'var(--notification-success)' }; 
      case 'warning':
        return { background: 'var(--notification-warning)' }; 
      case 'info':
        return { background: 'var(--notification-info)' }; 
      default:
        return {}; 
    }
  };

  const openNotification = (message: React.ReactNode, type: NotificationConfig['type'] = 'success') => {
    api.open({
      message,
      duration: 3,
      placement: 'top',
      style: {
        width: 'max-content',
        height: 40,
        padding: '10px 12px 12px 12px',
        borderRadius: 10,
        ...getNotificationStyle(type), 
      },
      className: styles.notification,
      closeIcon: config?.closeIcon && (
        <span>
          <CloseIcon fill="red" />
        </span>
      ),
      ...config,
    });
  };

  return {
    contextHolder,
    openNotification,
  };
};

export default useNotification;
