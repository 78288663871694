import React from "react";
import styles from "./ChatListSkeleton.module.scss";

interface MyComponentProps { }

const ChatListSkeleton: React.FC<MyComponentProps> = () => {
    const blocks = [1, 2];

    return (
        <div className={styles["chat-list-skeleton__container"]}>
            <section className={styles["chat-list-skeleton__column-section"]}>
                <div className={styles["chat-list-skeleton__centered-block"]} />
            </section>
            <div className={styles["chat-list-skeleton__margin"]} />
            <section className={styles["chat-list-skeleton__column-section"]}>
                <div className={styles["chat-list-skeleton__thin-block"]} />
                <div className={styles["chat-list-skeleton__block"]} />
                {blocks.map((_, index) => (
                    <div
                        className={`${styles["chat-list-skeleton__block"]} ${styles["chat-list-skeleton__block--small-margin"]}`}
                        key={index}
                    />
                ))}
            </section>
            <section className={styles["chat-list-skeleton__column-section"]}>
                <div
                    className={styles["chat-list-skeleton__thin-block"]}
                    style={{ marginTop: 20 }}
                />
                <div className={styles["chat-list-skeleton__block"]} />
                {[1].map((_, index) => (
                    <div
                        className={`${styles["chat-list-skeleton__block"]} ${styles["chat-list-skeleton__block--small-margin"]}`}
                        key={index}
                    />
                ))}
            </section>
        </div>
    );
};

export default ChatListSkeleton;
