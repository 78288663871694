import { FC } from 'react';

import Dropdown from '../../../../../components/Dropdown/Dropdown.component';
import EmptyFolder from './EmptyFolder/EmptyFolder.component';
import Folder, { File } from './Folder/Folder.component';

import { ReactComponent as BinIcon } from "../../../../../icons/Unpin.svg"

export interface FolderData {
  id: string;
  folderName: string;
  originalFolderName: string;
  codeFileName: string;
  files: File[];
}

export interface Study {
  id: string;
  title: string;
  folders: FolderData[];
}

export interface OutputContentProps {
  studies: Study[];
  onDeleteChatOutputs: (chatId: string) => void;
}

const OutputContent: FC<OutputContentProps> = ({ onDeleteChatOutputs, studies }) =>
  <>
    {studies.map((study, index) => (
      <Dropdown
        key={index}
        icons={[<BinIcon onClick={() => onDeleteChatOutputs(study.id)} />]}
        title={study.title}
      >
        {study.folders.length === 0 ? (
          <EmptyFolder />
        ) : (
          study.folders.map((folder, folderIndex) => (
            <Folder
              id={folder.id}
              key={folderIndex}
              originalFolderName={folder.originalFolderName}
              folderName={folder.folderName}
              codeFileName={folder.codeFileName}
              files={folder.files}
            />
          ))
        )}
      </Dropdown>
    ))}
  </>


export default OutputContent;
