import { ReactComponent as InfoIcon } from 'src/icons/Info.svg';
import SpinnerLoading from 'src/components/Loading/SpinnerLoading/SpinnerLoading';
import { Text } from 'src/components/Text/Text';
import styles from "./CopyChatSection.module.scss";

import { Popover } from 'antd';

interface CopyChatSectionProps {
  email: string;
  isLoading: boolean;
  onCopyChatClick: () => void;
}

const TOOLTIP_TEXT = 'You cannot send messages here, but you can copy this chat to continue working with it.'
const COPY_CHAT_BUTTON_TEXT = 'Create a Copy of this Chat'

export const CopyChatSection = ({ onCopyChatClick, isLoading, email }: CopyChatSectionProps) => {
  return (
    <div className={styles["copy-chat-section"]}>
      <p className={styles["copy-chat-section__info"]}>
        <Popover 
            rootClassName={styles['copy-chat-section__tooltip']} 
            overlayStyle={{ width: 344 }} 
            overlayInnerStyle={{ background: '#000000CC' }}
            content={<Text.Small>{TOOLTIP_TEXT}</Text.Small>} 
            placement="top"
        >
          <InfoIcon className={styles["copy-chat-section__icon"]} />
        </Popover>
        This chat belongs to{' '}
        <span className={styles["copy-chat-section__email"]}>{email}</span>
      </p>
      <button
        className={styles["copy-chat-section__button"]}
        disabled={isLoading}
        onClick={onCopyChatClick}
      >
        {isLoading ? <SpinnerLoading /> : COPY_CHAT_BUTTON_TEXT}
      </button>
    </div>
  );
};
