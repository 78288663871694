import { AxiosResponse } from 'axios';

import { CreateDatasetDto, DatasetsApi } from '../../swagger_api';
import { protectedConfiguration } from '../swagger';

class DatasetsService {
  datasetsApi: DatasetsApi;

  constructor() {
    this.datasetsApi = new DatasetsApi(protectedConfiguration);
  }

  createDataset = async (dataset: CreateDatasetDto): Promise<AxiosResponse<any>> => {
    try {
      const response = await this.datasetsApi.create(dataset);
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  };

  getDatasets = async (): Promise<AxiosResponse<any>> => {
    try {
      const response = await this.datasetsApi.findAll();
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  };
}

export const datasetsService = new DatasetsService();
