export const mapPatientsData = (rawData) => {
    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substr(2, 5);

    const transformedData = rawData.filter(item => item.conc !== 'NA').map(item => ({
        label: item.subject_id,
        data: { x: parseFloat(item.time), y: parseFloat(item.conc) }
    }));

    const groupedData = transformedData.reduce((acc, item) => {
        const found = acc.find(entry => entry.label === item.label);

        if (found) {
            found.data.push(item.data);
        } else {
            acc.push({ id: generateUniqueId(), label: item.label, data: [item.data] });
        }

        return acc;
    }, []);

    return groupedData;
};
