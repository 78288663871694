import { Paper } from '../../../../components/Paper/Paper';
import { Text } from '../../../../components/Text/Text';
import { ReactComponent as DotsIcon } from '../../../../icons/dots-icon.svg';
import styles from './DashboardTicket.module.scss';
import Asset1 from './asset1.jpg';
import Asset2 from './asset2.jpg';

export const DashboardTicket = ({ name, job, asset }) => {
  return (
    <Paper>
      <div className={styles.header}>
        <div>
          <Text.Regular>{name}</Text.Regular>
          <Text.Small>
            From <strong>{job}</strong> job
          </Text.Small>
        </div>
        <DotsIcon />
      </div>

      <img src={asset === '1' ? Asset1 : Asset2} alt={name} className={styles.image} />
    </Paper>
  );
};
