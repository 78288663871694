import { ADMINISTRATION_TYPES, BLQ_METHODS, INTEGRAL_METHODS, WEIGHTING_METHODS } from "./AnalysisNCAForm.constant";

export const validateField = (name, value) => {
    try {
        const validations = {
            administrationType: (val) => ({
                valid: ADMINISTRATION_TYPES.some(option => option.value === val),
                message: "Invalid admin type."
            }),
            integralMethod: (val) => ({
                valid: INTEGRAL_METHODS.some(option => option.value === val),
                message: "Invalid method."
            }),
            partialAUCTimeMin: (val) => ({
                valid: val >= 0 && val < 7,
                message: "Value should be >= 0 and <= 7."
            }),
            partialAUCTimeMax: (val) => ({
                valid: val <= 7,
                message: "Value should be <= 7."
            }),
            blqMethodAfterTmax: (val) => ({
                valid: BLQ_METHODS.some(option => option.value === val),
                message: "Invalid BLQ method."
            }),
            r2: (val) => ({
                valid: parseFloat(val) >= 0 && parseFloat(val) <= 1,
                message: "Should be between 0 and 1."
            }),
            adjustedR2Percentage: (val) => ({
                valid: parseFloat(val) >= 0 && parseFloat(val) <= 1,
                message: "Should be between 0 and 1."
            }),
            adjustedR2MaxPoints: (val) => ({
                valid: Number.isInteger(+val) && +val > 0,
                message: "Should be a positive integer."
            }),
            adjustedR2MinTime: (val) => ({
                valid: Number.isInteger(+val) && +val > 0,
                message: "Should be a positive integer."
            }),
            numberOfPoints: (val) => ({
                valid: Number.isInteger(+val) && +val > 0,
                message: "Should be a positive integer."
            }),
            intervalMin: (val) => ({
                valid: val >= 0,
                message: "Value should be >= 0."
            }),
            intervalMax: (val) => ({
                valid: val <= 7,
                message: "Value should be <= 7."
            }),
            weighting: (val) => ({
                valid: WEIGHTING_METHODS.some(option => option.value === val),
                message: "Invalid weighting method."
            }),
            extrapolatedAUC: (val) => ({
                valid: parseFloat(val) >= 0 && parseFloat(val) <= 100,
                message: "Should be between 0 and 100."
            }),
            span: (val) => ({
                valid: Number.isInteger(+val) && +val > 0,
                message: "Should be a positive integer."
            }),
            skip: (val) => ({
                valid: true,
                message: "Valid."
            })
        };

        return validations[name] ? validations[name](value) : validations.skip(value);
    } catch (err) {
        // Handle errors here if needed
    }
};
