export const ADMINISTRATION_OPTIONS = [
    { value: "Bolus", label: "Bolus" },
    { value: "Infusion", label: "Infusion" },
    { value: "Oral", label: "Oral" },
    { value: "Intramuscular", label: "Intramuscular" },
    { value: "Subcutaneous", label: "Subcutaneous" },
];

export const DELAY_OPTIONS = [
    { value: "No Delay", label: "No Delay" },
    { value: "Lag Time", label: "Lag Time" },
    { value: "Transit Compartments", label: "Transit Compartments" },
];

export const ABSORPTION_OPTIONS = [
    { value: "Zero Order", label: "Zero Order" },
    { value: "First Order", label: "First Order" },
];

export const DISTRIBUTION_OPTIONS = [
    { value: "1 Compartment", label: "1 Compartment" },
    { value: "2 Compartments", label: "2 Compartments" },
    { value: "3 Compartments", label: "3 Compartments" },
];

export const ELIMINATION_OPTIONS = [
    { value: "Linear", label: "Linear" },
    { value: "Michaelis-Menten", label: "Michaelis-Menten" },
];

export const PARAMETERIZATION_OPTIONS = [
    { value: "With Rate Constants", label: "With Rate Constants" },
    { value: "With PK Parameters", label: "With PK Parameters" }
];

export const INITIAL_PARAMS_OPTIONS = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
];

export const ERROR_MODEL_OPTIONS = [
    { value: "Proportional", label: "Proportional" },
    { value: "Additive", label: "Additive" },
    { value: "Combined", label: "Combined" },
    { value: "None", label: "None" },
];

export const PARAMETER_DISTRIBUTIONS_OPTIONS = [
    { value: "Lognormal", label: "Lognormal" },
    { value: "Normal", label: "Normal" },
];

export const RANDOM_EFFECTS_OPTIONS = [
    { value: "All Parameters", label: "All Parameters" },
    { value: "Some Parameters", label: "Some Parameters" },
];

export const COVARIANCE_MATRIX_OPTIONS = [
    { value: "Diagonal", label: "Diagonal" },
    { value: "Full", label: "Full" },
];

export const initialState = {
    // Structural Model
    administration: ADMINISTRATION_OPTIONS[0].value,
    delay: DELAY_OPTIONS[0].value,
    absorption: ABSORPTION_OPTIONS[0].value,
    distribution: DISTRIBUTION_OPTIONS[0].value,
    elimination: ELIMINATION_OPTIONS[0].value,
    parameterization: PARAMETERIZATION_OPTIONS[0].value,
    automateInitialParameterEstimates: INITIAL_PARAMS_OPTIONS[0].value,
    // Statistical Model
    errorModel: ERROR_MODEL_OPTIONS[3].value,
    parameterDistributions: PARAMETER_DISTRIBUTIONS_OPTIONS[0].value,
    randomEffects: RANDOM_EFFECTS_OPTIONS[0].value,
    covarianceMatrix: COVARIANCE_MATRIX_OPTIONS[0].value,
};

export const FIELD_PROPERTIES = {
    administration: { label: "Administration" },
    delay: { label: "Delay" },
    absorption: { label: "Absorption" },
    distribution: { label: "Distribution" },
    elimination: { label: "Elimination" },
    parameterization: { label: "Parameterization" },
    automateInitialParameterEstimates: { label: "Automate initial parameter estimates?" },
    errorModel: { label: "Error Model" },
    parameterDistributions: { label: "Parameter Distributions" },
    randomEffects: { label: "Random Effects" },
    covarianceMatrix: { label: "Covariance Matrix" },
};

export const FIELD_NAMES = {
    ADMINISTRATION: "administration",
    DELAY: "delay",
    ABSORPTION: "absorption",
    DISTRIBUTION: "distribution",
    ELIMINATION: "elimination",
    PARAMETERIZATION: "parameterization",
    AUTOMATE_INITIAL_PARAMETER_ESTIMATES: "automateInitialParameterEstimates",
    ERROR_MODEL: "errorModel",
    PARAMETER_DISTRIBUTIONS: "parameterDistributions",
    RANDOM_EFFECTS: "randomEffects",
    COVARIANCE_MATRIX: "covarianceMatrix",
};
