import { useState } from 'react';

import { datasetsService } from 'src/api';
import { useModal } from 'src/components/Modal/Modal.hook';
import { useChatStore } from 'src/pages/Chat/Chat.hook';

import Button from '../../../../../../components/Button/Button.component';
import Input from '../../../../../../components/n-Input/n-Input.component';
import styles from './SaveDatasetModal.module.scss';
import { toast } from 'react-toastify';

const SaveDatasetModalBody = ({ datasetFilePath }) => {
  const { closeModal } = useModal();
  const { selectedChat } = useChatStore();

  // @ts-ignore
  const initialDataset = selectedChat.dataset.name;
  const defaultDatasetName = `${initialDataset.split('.')[0]}_derived_${new Date().toISOString().replace(/:/g, '').replace(/-/g, '').substring(0, 14)}`;
  const [name, setName] = useState(defaultDatasetName);

  const handleCreateDataset = async () => {
    try {
      const datasetToCreate = {
        name: name,
        file_path: datasetFilePath,
        details: initialDataset,
        user_id: selectedChat.user_id,
        workspace_id: selectedChat.workspace_id,
      };
      await datasetsService.createDataset(datasetToCreate);
      toast.success(` Dataset with name ${name} successfully created`)
      closeModal();
    } catch (error) {
      console.error('Failed to create dataset:', error);
    }
  };
  return (
    <>
      <div className={styles['dataset-modal__content']}>
        <Input
          className={styles['dataset-modal__input']}
          label="Name"
          value={name}
          onChange={(v) => setName(v as string)}
        />
      </div>
      <div className={styles['dataset-modal__footer']}>
        <Button
          color="secondary"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleCreateDataset}>{'Save'}</Button>
      </div>
    </>
  );
};

export default SaveDatasetModalBody;
