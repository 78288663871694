import styles from "./AnalysisModalForm.module.scss";

import AnalysisGenericForm, { ValidationError } from './AnalysisGenericForm/AnalysisGenericForm.component';

import useAnalysisModalStore from '../AnalysisModal.hook';
import { useOrgAnalysisSettings } from "../hooks/useOrgAnalysisSettings";
import { useEffect } from "react";
import { validateField } from "./AnalysisNCAForm/AnalysisNCAForm.validator";

const USER_KEY = 'user';

const AnalysisModalForm = () => {
    const {
        setHasError,
        activeBadge,
        popPkState,
        ncaState,
        ncaConfig,
        setNcaConfig,
        setPopPkConfig,
        popPkConfig,
        setNcaState,
        setPopPkState
    } = useAnalysisModalStore()

    const currentOrganizatiodId = JSON.parse(localStorage.getItem(USER_KEY)).user.organization_id;

    const { nca, popPk, isLoading, fetchOrgAnalysisSettings } =
        useOrgAnalysisSettings(currentOrganizatiodId)

    const validateFields = (state): Record<string, ValidationError> =>
        Object.entries(state)
            .reduce((acc, [fieldName, fieldValue]) => {
                acc[fieldName] = validateField(fieldName, fieldValue);
                return acc;
            }, {});

    useEffect(() => {
        fetchOrgAnalysisSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (activeBadge === 'NCA' && nca) {
            setNcaState(nca.settings.initialState);
            setNcaConfig(nca.settings.config)
        } else if (activeBadge === 'POP-PK' && popPk) {
            setPopPkState(popPk.settings.initialState);
            setPopPkConfig(popPk.settings.config)
        }
    }, [activeBadge, nca, popPk, setNcaConfig, setNcaState, setPopPkConfig, setPopPkState]);

    useEffect(() => {
        const hasValidationErrorInFields = state => Object.values(validateFields(state)).some(({ valid }) => !valid)

        if (activeBadge === 'NCA') {
            setHasError(hasValidationErrorInFields(ncaState))
        } else {
            setHasError(hasValidationErrorInFields(popPkState))
        }
    }, [activeBadge, ncaState, popPkState, setHasError])

    if (isLoading && !nca && !popPk) return null;

    const handleNcaInputChange = (name: string, value: any) => {
        setNcaState(({
            ...ncaState,
            [name]: value,
        }));
    };

    const handlePopPkInputChange = (name: string, value: any) => {
        setPopPkState(({
            ...popPkState,
            [name]: value,
        }));
    };

    return (
        <div className={styles['analysis-modal-form']}>
            {activeBadge === 'NCA' && (
                <AnalysisGenericForm
                    config={ncaConfig}
                    state={ncaState}
                    errors={validateFields(ncaState)}
                    handleChange={handleNcaInputChange}
                />
            )}
            {activeBadge === 'POP-PK' && (
                <AnalysisGenericForm
                    config={popPkConfig}
                    state={popPkState}
                    errors={validateFields(popPkState)}
                    handleChange={handlePopPkInputChange}
                />
            )}
        </div>
    );
};

export default AnalysisModalForm;
