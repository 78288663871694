import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '../../icons/Arrow.svg';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  icons?: React.ReactNode[];
  nested?: boolean;
  opened?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ title, nested, children, icons, opened = false }) => {
  const [isOpen, setIsOpen] = useState(opened);
  const contentRef = useRef<HTMLDivElement>(null); 
  
  const toggleDropdown = (event) => {
    if (event.target.dataset.dropdownIgnoreClick !== 'true') {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }
  };

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      if (isOpen) {
        content.style.maxHeight = `${content.scrollHeight}px`;

        if(!nested) {
          content.style.maxHeight = `100%`;
        }

        content.style.opacity = '1';
      } else {
        requestAnimationFrame(() => {
          content.style.maxHeight = '0px';
          content.style.opacity = '0';
        });
      }
    }
  }, [children, isOpen, nested]);

  return (
    <div className={styles.dropdown}>
      <button
        className={classNames(styles.dropdown__toggle, {
          [styles['dropdown__toggle--open']]: isOpen && !nested,
          [styles['dropdown__toggle--nested']]: nested,
        })}
        onClick={toggleDropdown}
      >
        <ArrowIcon
          className={classNames(styles.dropdown__arrow, {
            [styles['dropdown__arrow--rotate']]: isOpen,
          })}
        />
        {title}
        <div className={styles.dropdown__icons}>
          {icons}
        </div>
      </button>
      <div
        className={classNames(styles.dropdown__content, {
          [styles['dropdown__content--open']]: isOpen,
        })}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
