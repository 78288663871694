import { useEffect, useState } from 'react';

import cn from 'classnames';

import { Paper } from '../../../../components/Paper/Paper';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { ReactComponent as DotsIcon } from '../../../../icons/dots-icon.svg';
import { ReactComponent as CodeIcon } from '../../../../icons/jobs/code.svg';
import { ReactComponent as GraphIcon } from '../../../../icons/jobs/graph.svg';
import { ReactComponent as TableIcon } from '../../../../icons/jobs/table.svg';
import { AssetDto, JobDto } from '../../../../swagger_api';
import { JobStatus } from '../../../../types/enums/job-status';
import { JobsData } from '../../hooks/useJobsData';
import styles from './JobPage.module.scss';
import CodeEditor from './components/CodeEditor/CodeEditor.component';
import { JobCsvTable } from './components/JobCsvTable';
import { JobsSidebar } from './components/JobsSidebar';

type JobsPageProps = {
  jobs: JobsData;
  jobId: string;
};

const JobPage = ({ jobs, jobId }: JobsPageProps) => {
  const allJobs: JobDto[] = [
    ...jobs[JobStatus.COMPLETED],
    ...jobs[JobStatus.CANCELED],
    ...jobs[JobStatus.FAILED],
    ...jobs[JobStatus.IN_PROGRESS],
    ...jobs[JobStatus.QUEUED],
  ];
  const currentJob = allJobs.find((job) => job.id === jobId);
  const [openedAssets, setOpenedAssets] = useState<AssetDto[]>([]);
  const [currentView, setCurrentView] = useState<AssetDto>();

  const currentViewType = currentView?.name.split('.').pop();

  useEffect(() => {
    if (currentJob?.id && currentJob.assets?.length > 0) {
      setOpenedAssets([currentJob.assets[0]]);
      setCurrentView(currentJob.assets[0]);
    }
  }, [currentJob]);

  // TODO: define by mime type
  const isImageAsset =
    currentViewType === 'png' || currentViewType === 'jpg' || currentViewType === 'jpeg';
  const isCsvAsset = currentViewType === 'csv';
  const isCodeAsset = currentViewType === 'py';

  return (
    <div className={styles.container}>
      <JobsSidebar
        allJobs={allJobs}
        currentJob={currentJob}
        currentView={currentView}
        openedAssets={openedAssets}
        setCurrentView={setCurrentView}
        setOpenedAssets={setOpenedAssets}
      />
      <div className={styles.jobView}>
        <div className={styles.editor}>
          <Paper className={styles.currentViews}>
            {openedAssets.map((asset) => {
              const assetType = asset.name.split('.').pop();
              const AssetIcon =
                assetType === 'csv' ? TableIcon : assetType === 'png' ? GraphIcon : CodeIcon;

              return (
                <div
                  key={asset.id}
                  className={cn({
                    [styles.view]: true,
                    [styles.openedView]: currentView === asset,
                  })}
                  onClick={() => setCurrentView(asset)}
                >
                  <AssetIcon />
                  {asset.name.substring(0, 15)}...
                  <DotsIcon />
                  <CloseIcon
                    onClick={() => {
                      setOpenedAssets(openedAssets.filter((s) => s !== asset));
                      setCurrentView(openedAssets[0]);
                    }}
                  />
                </div>
              );
            })}
          </Paper>
          {isCodeAsset && <CodeEditor code={currentView.file_path} />}
          {isCsvAsset && <JobCsvTable filePath={currentView.file_path} />}
          {isImageAsset && (
            <img
              src={`${process.env.REACT_APP_BASE_API_URL}/${currentView.file_path}`}
              alt="graph"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default JobPage;
