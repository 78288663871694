import React from "react";
import styles from "./ChatSkeleton.module.scss";

const ChatSkeleton: React.FC = () => (
    <main className={styles.mainContainer}>
        <section className={styles.cardsSection}>
            <div className={styles.card}>
                <div className={styles.avatar} style={{ width: "48px", height: "48px" }} />
                <div className={styles.cardBody}>
                    <div className={styles.titleBox} style={{ width: "70px", height: "14px" }} />
                    <div className={styles.descriptionBox} style={{ width: "356px", height: "14px" }} />
                    <div className={styles.contentBox} style={{ width: "100%", height: "40px" }} />
                </div>
            </div>
        </section>
        <section className={styles.gradientSection}>
            <div className={styles.gradientBox} style={{ width: "100%", height: "48px" }} />
        </section>
    </main>
);

export default ChatSkeleton;
