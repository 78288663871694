import EditableCodeEditor from './components/EditableCodeEditor.component';
import useEditableCodeStore from './EditCodeModal.hook'; 

const EditCodeModalBody = () => {
    const { originalKey } = useEditableCodeStore();

    return (
         originalKey && <EditableCodeEditor code={originalKey} />
    );
};

export default EditCodeModalBody;
