import { ReactComponent as FilesIcon } from '../../../../../../icons/96/Files.svg';
import styles from './EmptyOutput.module.scss';

const EmptyOutput = () => (
  <div className={styles['empty-output']}>
    <FilesIcon />
    <span className={styles['empty-output__text']}>No output files yet.</span>
  </div>
);

export default EmptyOutput;
