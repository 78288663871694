export const DASHBOARD_ROUTE = '/dashboard';
export const JOBS_ROUTE = '/jobs';
export const DATASETS_ROUTE = '/datasets';
export const CHAT_ROUTE = '/chat';
export const LOGIN_ROUTE = '/login';

const routes = {
  DASHBOARD: DASHBOARD_ROUTE,
  JOBS: JOBS_ROUTE,
  DATASETS: DATASETS_ROUTE,
  CHAT: CHAT_ROUTE,
  LOGIN: LOGIN_ROUTE,
};

export default routes;
