import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import classNames from 'classnames';
import { Socket } from 'socket.io-client';
import { chatsService } from 'src/api/chats/chats.service';
import TypingIndicator from 'src/components/TypingIndicator/TypingIndicator';
import { CommandName, MessageType } from 'src/types/agentResponse';

import { MessageDto, ThumbActionDtoActionEnum } from '../../../../swagger_api';
import { useChatStore } from '../../Chat.hook';
import { useShareChat } from '../../hooks/useShareChat.hook';
import styles from './Messages.module.scss';
import { CopyChatSection } from './components/CopyChatSection/CopyChatSection.component';
import DatasetManager from './components/DatasetManager/DatasetManager';
import LogsManager from './components/LogsManager/LogsManager';
import MessageInput from './components/MessageInput/MessageInput';
import { MessageResponse } from './components/MessageResponse/MessageResponse';
import { RenderSuggestions } from './components/Suggestions/Suggestions';
import Welcome from './components/Welcome/Welcome.component';
import Asset from './components/assets/RenderAssets';

const agentThoughtEnabled = process.env.REACT_APP_FF_AGENT_THOUGHTS === 'true';

type Props = {
  socket: Socket;
  messageValue: string;
  sendMessage: (overrideMessage, additionalData?: any) => void;
  setMessageValue: (message: string) => void;
  waitingForResponse: boolean;
};

const Messages = forwardRef((props, ref) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isShared, getSharedChatAuthor } = useShareChat();

  const { messageValue, sendMessage, setMessageValue, waitingForResponse, socket } = props as Props;

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const {
    isLoading,
    replyMode,
    selectedChat,
    cachedMessages,
    messages: allMessages,
    setSelectedChat,
    setChats,
    isChatCollapsed,
    isOutputsCollapsed,
  } = useChatStore();

  const { chatMessages: messages } = cachedMessages[selectedChat?.id] || allMessages;

  console.log('Messages:', messages);
  const _sendMessage = (overrideMessage, additionalData = null) => {
    setCanceled(false);
    sendMessage(overrideMessage, additionalData);
  };

  const [highlightedMessage, setHighlightedMessageId] = useState('');
  const [isCopyingLoading, setIsCopyingLoading] = useState(false);
  const [canceled, setCanceled] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    scrollToMessage: (messageId: string) => {
      setHighlightedMessageId(messageId);
      setTimeout(() => setHighlightedMessageId(''), 3000);
      const messageElement = document.getElementById(messageId);
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }));

  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  const pendingMessage = useRef<string | null>(null);

  const scrollToBottom = (behavior: ScrollBehavior) => {
    messagesEndRef.current?.scrollIntoView({ behavior });
  };

  useEffect(() => {
    if (pendingMessage.current) {
      sendMessage(pendingMessage.current);
      pendingMessage.current = null;
    }
  }, [selectedChat, sendMessage]);

  const sendPendingMessage = async (message: string) => {
    pendingMessage.current = message;
    setMessageValue(message);
  };

  const scrollToMessage = ({ messageId, isCopied }: { messageId: string; isCopied: boolean }) => {
    const messageIdToScroll = isCopied
      ? messages.find((message) => message.metadata.original_message_id === messageId)?.id
      : messageId;

    const messageElement = document.getElementById(messageIdToScroll);

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const lastMessages = useMemo(() => {
    let thoughtMessage = null;
    const logMessages = [];

    const lastMessage = messages.at(-1);
    if (!lastMessage?.message_author) {
      return { thoughtMessage, logMessages };
    }

    for (let i = messages.length - 1; i >= 0 && i >= messages.length - 10; i--) {
      const msg = messages[i];

      if (msg.metadata.message_author === null) {
        thoughtMessage = null;
        logMessages.length = 0;
        break;
      }

      if (msg.metadata.message_type === MessageType.THOUGHT && !thoughtMessage) {
        thoughtMessage = msg;
      }

      if (msg.metadata.message_author === 'user') {
        thoughtMessage = null;
      }

      if (msg.metadata.message_type === MessageType.CONSOLE) {
        logMessages.push(msg);
      }

      if (msg.metadata.message_type === null) {
        break;
      }
    }

    return { thoughtMessage, logMessages };
  }, [messages]);

  const onThumbClick = (messageId: string) => async (action: ThumbActionDtoActionEnum) => {
    const message = {
      chat_id: selectedChat.id,
      text: action,
      metadata: {
        text: action,
        user_id: user.user.id,
        command: 'feedback',
        message_type: 'human_command',
        command_context: [messageId],
        internal: true,
        is_service_message: true,
      },
      message_author: null,
    };

    socket.emit('message', message);
    await chatsService.thumbAction({ message_id: messageId, action });
  };

  const onCopyChat = async () => {
    try {
      setIsCopyingLoading(true);

      const copiedChat = await chatsService.copyChat({
        target_user_id: user.user.id,
        chat_id: selectedChat.id,
      });

      const queryParams = new URLSearchParams(location.search);
      queryParams.set('chatId', copiedChat.id);
      setSelectedChat(copiedChat);

      navigate({ search: queryParams.toString() });

      const data = await chatsService.findAll();
      setChats(data);
    } catch (err) {
      throw err;
    } finally {
      setIsCopyingLoading(false);
    }
  };

  const onMessageHeaderClick = (message: MessageDto) => () => {
    const { original_message_id, replied_message_id } = message.metadata;

    const isCopied = !!original_message_id;

    scrollToMessage({ isCopied, messageId: replied_message_id });
  };

  const withSuggestions = useMemo(() => {
    if (!messages.length) return false;
    return messages.at(-1)?.metadata?.agentResponse?.button_context;
  }, [messages]);

  const lastMessageSuggestions = useMemo(() => {
    const lastMessage = messages.at(-1);
    return lastMessage?.metadata?.message_type === MessageType.INPUT_BUTTON;
  }, [messages]);

  const messageProcessing = useMemo(() => {
    const lastMessage = messages.at(-1);

    if (
      lastMessage?.metadata.command === CommandName.FEEDBACK ||
      lastMessage?.metadata.message_type === MessageType.INPUT_BUTTON ||
      canceled
    )
      return false;

    return lastMessage?.metadata?.partial || !lastMessage?.message_author || withSuggestions;
  }, [messages, canceled, withSuggestions]);

  useEffect(() => {
    setTimeout(() => scrollToBottom('smooth'), 500);
  }, [messages.length]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom('smooth');
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelChat = async () => {
    const lastUserMessageId = messages.reverse().find((msg) => !msg.message_author).id;
    if (lastUserMessageId) {
      const prevCancelMessages = selectedChat?.canceled_message_ids || [];
      setCanceled(true);
      setSelectedChat({
        ...selectedChat,
        canceled_message_ids: [...prevCancelMessages, lastUserMessageId],
      });

      chatsService.cancelMessage(selectedChat.id, lastUserMessageId);
    }
  };

  const pendingState = agentThoughtEnabled
    ? messageProcessing && (
        <div className={styles.logs}>
          <LogsManager
            currentThought={lastMessages?.thoughtMessage?.text}
            logMessages={lastMessages.logMessages}
          />
        </div>
      )
    : messageProcessing &&
      !withSuggestions && (
        <div className={styles['messages-processing']}>
          <TypingIndicator />
        </div>
      );

  const messagesContainerClassName = useMemo(() => {
    if (isChatCollapsed && isOutputsCollapsed) {
      return classNames(styles.messagesContainer, [styles['messagesContainer-collapsed']]);
    }
    return classNames(styles.messagesContainer, {
      [styles['messagesContainer-sidebar-collapsed']]: isChatCollapsed,
      [styles['messagesContainer-outputs-collapsed']]: isOutputsCollapsed,
    });
  }, [isChatCollapsed, isOutputsCollapsed]);

  const dividerContainerClassName = useMemo(() => {
    if (isChatCollapsed && isOutputsCollapsed) {
      return classNames(styles['messages-divider'], [styles['messages-divider-collapsed']]);
    }
    return classNames(styles['messages-divider'], {
      [styles['messages-divider-sidebar-collapsed']]: isChatCollapsed,
      [styles['messages-divider-outputs-collapsed']]: isOutputsCollapsed,
    });
  }, [isChatCollapsed, isOutputsCollapsed]);

  if (isLoading) return <Welcome sendSuggestedMessage={sendPendingMessage} />;

  if (!messages.length || !selectedChat || selectedChat.deleted) {
    const hideSuggestions = selectedChat?.deleted && selectedChat?.shared;

    return (
      <div className={styles.emptyLayout}>
        <Welcome sendSuggestedMessage={sendPendingMessage} hideSuggestions={hideSuggestions} />
        {selectedChat?.id && !selectedChat?.deleted && (
          <MessageInput
            sendMessage={sendMessage}
            messageValue={messageValue}
            setMessageValue={setMessageValue}
            waitingForResponse={waitingForResponse}
            isWelcome
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.messagesLayout}>
      <div className={styles.messages}>
        <div className={messagesContainerClassName}>
          {messages.map((message, index) => {
            const suggestions = message.metadata?.agentResponse?.button_context;
            const consoleMessage =
              message.metadata.message_type === MessageType.CONSOLE ||
              message.metadata.is_service_message;
            const skipMessage = agentThoughtEnabled
              ? consoleMessage || message.metadata.message_type === MessageType.THOUGHT
              : consoleMessage;

            if (skipMessage) return null;

            const asset = {
              url: message.metadata.url,
              type: message.metadata.type,
              name: message.metadata.name,
            };

            const sameAuthor = message?.message_author && messages[index - 1]?.message_author;
            const isLastMessage = index === messages.length - 1;

            let codeFile = null;

            for (let i = index - 1; i >= 0 && i >= index - 10; i--) {
              if (messages[i].metadata?.type === 'R') {
                codeFile = {
                  name: messages[i].metadata.name,
                  filePath: messages[i].metadata.url,
                };
                break;
              }
            }

            return (
              <div
                key={message.id}
                id={message.id}
                className={cn(styles.message, {
                  [styles['message-user']]: !message.message_author,
                  [styles['message-user-asset']]: !message.message_author && message.metadata.url,
                  [styles['message-highlighted']]: highlightedMessage === message.id,
                })}
              >
                <MessageResponse
                  metadata={message.metadata}
                  isUserMessage={!message.message_author}
                  text={message.text}
                  showBadge={!sameAuthor}
                  messageType={message.metadata.message_type}
                  onHeaderClick={onMessageHeaderClick(message)}
                  onThumbClick={onThumbClick(message.id)}
                />

                {message.metadata.url && (
                  <Asset
                    asset={asset}
                    message={message}
                    codeFile={codeFile}
                    socket={socket}
                    scrollToMessage={scrollToMessage}
                    messageProcessing={messageProcessing}
                  />
                )}
                {suggestions && isLastMessage && (
                  <div className={styles.suggestions}>
                    <RenderSuggestions
                      isShared={isShared}
                      suggestions={suggestions}
                      sendMessage={sendMessage}
                    />
                  </div>
                )}
              </div>
            );
          })}
          {pendingState}
          <div ref={messagesEndRef} />
        </div>

        {isShared && (
          <CopyChatSection
            isLoading={isCopyingLoading}
            email={getSharedChatAuthor(selectedChat)}
            onCopyChatClick={onCopyChat}
          />
        )}

        {!isShared && (
          <MessageInput
            sendMessage={_sendMessage}
            messageValue={messageValue}
            setMessageValue={setMessageValue}
            waitingForResponse={waitingForResponse}
            disabled={messageProcessing}
            replyMode={replyMode}
            scrollToMessage={scrollToMessage}
            lastMessageSuggestions={lastMessageSuggestions}
            cancelChat={handleCancelChat}
          />
        )}
        {!isShared && (
          <div className={dividerContainerClassName}>
            <DatasetManager />
          </div>
        )}
      </div>
    </div>
  );
});

const EnhancedMessages = memo(Messages);

export default EnhancedMessages;
