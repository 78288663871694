import { useState } from 'react';


import { organizationService } from '../../../../../api/organization/organization.service';
import { analysisSettingsService } from '../../../../../api/analysis-settings/analysis-settings.service';
import useAnalysisModalStore from '../AnalysisModal.hook';
import useAnalysisSettingsStore from './useAnalysisSettings.hook';

export const useOrgAnalysisSettings = (organizationId?: string) => {
    const { nca, popPk, setAnalysisSettings } = useAnalysisSettingsStore()

    const { activeBadge, ncaConfig, popPkConfig, ncaState, popPkState } = useAnalysisModalStore()

    const [loading, setLoading] = useState(false);

    const fetchOrgAnalysisSettings = async () => {
        if (!organizationId) throw Error('No organizationId was provided for useOrgAnalysisSettings');

        setLoading(true);

        const { data: nca } = await organizationService.getAnalysisSettingsByOrganizationId(organizationId, 'nca');
        const { data: popPk } = await organizationService.getAnalysisSettingsByOrganizationId(organizationId, 'pop-pk');

        setAnalysisSettings(nca, popPk);
        setLoading(false);
    };

    const syncOrgAnalysisSettings = async () => {
        setLoading(true);

        const analysisSettings = activeBadge === 'NCA' ? nca : popPk;
        const analysisSettingsConfig = activeBadge === 'NCA' ? ncaConfig : popPkConfig;
        const analysisSettingsState = activeBadge === 'NCA' ? ncaState : popPkState;

        await analysisSettingsService.update(analysisSettings.id, {
            ...analysisSettings,
            settings: {
                config: analysisSettingsConfig,
                initialState: analysisSettingsState
            }
        })

        setLoading(false);
    }

    return {
        nca,
        popPk,
        fetchOrgAnalysisSettings,
        syncOrgAnalysisSettings,
        isLoading: loading,
    };
};
