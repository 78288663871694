import { useCallback, useEffect, useState } from 'react';

import { Socket, io } from 'socket.io-client';
import { chatsService } from 'src/api';
import { MessageDto } from 'src/swagger_api';
import { Commands } from 'src/types/enums/llm-commands';

import { useChatStore } from './Chat.hook';
import useManageChatModalStore from './components/Layout/Sidebar/ManageChatModal/ManageChatModal.hook';
import { isJsonString } from './utils';
import useNotification from 'src/hooks/useNotification';
import { AxiosError } from 'axios';
import { ErrorResponse } from '@/types/chat';

const socket = io(process.env.REACT_APP_BASE_API_URL, {
  reconnectionAttempts: 200,
  reconnectionDelay: 5000,
});

export type MessagesHOCProps = {
  messageValue: string;
  sendMessage: (overrideMessage?: string, additionalData?: any) => void;
  setMessage: (value: string) => void;
  setMessageValue: (value: string) => void;
  waitingForResponse: boolean;
  socket: Socket;
};

export const withChat = (Component) => {
  return () => {
    const { selectedDataset, setActiveDataset } = useManageChatModalStore();
    const {
      selectedChat,
      replyMode,
      setReplyMode,
      setMessages,
      addMessage,
      setCleanDatasetIncluded,
      setIsLoading
    } = useChatStore();

    const [messageValue, setMessageValue] = useState('');
    const [waitingForResponse, setWaitingForResponse] = useState(false);
    const { openNotification, contextHolder } = useNotification();

    const userId = JSON.parse(localStorage.getItem('user'))?.user?.id;

    useEffect(() => {
      socket.on('message', (message: MessageDto) => {
        if (message.chat_id !== selectedChat.id) {
          return;
        }
        setWaitingForResponse(false);
        if (isJsonString(message.text)) {
          const jsonObject = JSON.parse(message.text);
          message.text = JSON.stringify(jsonObject, null, 4);
        }

        addMessage(message);
      });

      return () => {
        socket.off('message');
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat?.id, socket]);

    useEffect(() => {
      setMessages([], [], null);

      if (selectedChat) {
        chatsService.getChat(selectedChat.id).then(({ chat, messages, logMessages }) => {
          setIsLoading(true)
          setMessages(messages, logMessages, selectedChat.id);
          setActiveDataset(chat.active_dataset);
          setCleanDatasetIncluded(false);

          socket.emit('joinChat', selectedChat.id);
        }).catch((err) => {
          const axiosError = err as AxiosError<ErrorResponse>;
          openNotification(axiosError.response?.data?.message || 'Failed to fetch chat', 'error');
        }).finally(() => {
          setIsLoading(false)
        })

      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedChat]);

    useEffect(() => {
      socket.on('connect', () => {
        console.log('Connected to server');
      });

      socket.on('disconnect', (reason) => {
        console.log(`Disconnected from server: ${reason}`);
        if (reason === 'io server disconnect') {
          socket.connect();
        }
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
      };
    }, [selectedChat?.id]);

    const sendMessage = useCallback(
      async (overrideMessage, additionalData = null) => {
        if ((!overrideMessage && !messageValue) || !selectedChat?.id) return;

        const messageContent = messageValue || overrideMessage;

        const message = {
          chat_id: selectedChat.id,
          text: messageContent,
          metadata: {
            selectedDataset,
            user_id: userId,
            ...(additionalData ? additionalData : null),
            replied_message_id: replyMode?.messageId,
            command: replyMode ? Commands.MODIFY_PLOT : undefined,
            command_context: replyMode
              ? [messageContent, replyMode.codeFileName, replyMode.assetFileName]
              : undefined,
          },
          message_author: null,
        };
        socket.emit('message', message);

        setMessageValue('');
        setWaitingForResponse(true);
        setReplyMode(null);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [messageValue, selectedChat?.id, userId, replyMode],
    );

    const setMessage = useCallback((value) => {
      setMessageValue(value);
    }, []);

    const updatedProps: MessagesHOCProps = {
      messageValue,
      setMessage,
      sendMessage,
      setMessageValue,
      waitingForResponse,
      socket,
    };

    return <>
      {contextHolder}
      <Component {...updatedProps} />
    </>;
  };
};
