import React from 'react';
import MarkdownView from 'react-markdown';

import cn from 'classnames';
import remarkGfm from 'remark-gfm';
import { MessageType } from 'src/types/agentResponse';

import InteractiveChart from '../../../../../../components/InteractiveChart/InteractiveChart.component';
import { generatePatientOptions } from '../../../../../../components/InteractiveChart/InteractiveChart.utils';
import { ReactComponent as EditPlotsIcon } from '../../../../../../icons/EditPlots.svg';
import { ReactComponent as ResponseIcon } from '../../../../../../icons/message_response.svg';
import { ThumbActionDtoActionEnum } from '../../../../../../swagger_api';
import { Commands } from '../../../../../../types/enums/llm-commands';
import { AgentResponseMessage } from './AgentResponseMessage/AgentResponseMessage.component';
import InProgressMessage from './InProgressMessage/InProgressMessage.component';
import styles from './MessageResponse.module.scss';
import { mapPatientsData } from './MessageResponse.utils';

interface CommonMessageProps {
  isUserMessage: boolean;
  children: React.ReactNode | string;
  showBadge?: boolean;
  messageType?: MessageType;
  metadata?: Record<string, any>;
  onHeaderClick?: () => void;
}

interface MessageResponseProps {
  type?: string;
  isUserMessage: boolean;
  metadata: Record<string, any>;
  text?: string;
  showBadge?: boolean;
  messageType: MessageType;
  onHeaderClick?: () => void;
  onThumbClick?: (action: ThumbActionDtoActionEnum) => void;
  onLogClick?: () => void;
}

export function replaceInvalidTags(markdown: string): string {
  return markdown.replace(/<BLQ/g, '&lt;BLQ');
}

export const CommonMessage: React.FC<CommonMessageProps> = ({
  isUserMessage,
  showBadge,
  messageType,
  children,
  metadata,
  onHeaderClick,
}) => {
  const sanitizedMarkdown = replaceInvalidTags(children as string);

  const getRepliedFileName = () => {
    const [, , repliedFile] = metadata.command_context;
    return repliedFile;
  };

  const isModifyPlotMessage = () => metadata.command === Commands.MODIFY_PLOT;

  if (isModifyPlotMessage()) {
    return (
      <div className={cn(styles.messageResponse, styles['messageResponse__replied'])}>
        <div>
          {showBadge && !isUserMessage && <ResponseIcon className={styles.messageIcon} />}
          <div
            className={cn(styles.messageCont, {
              [styles['message-user']]: isUserMessage,
            })}
          >
            {isModifyPlotMessage() && (
              <div className={styles.messageModifyPlot} onClick={onHeaderClick}>
                <EditPlotsIcon />
                <p>{getRepliedFileName()}</p>
              </div>
            )}
            <MarkdownView
              remarkPlugins={[remarkGfm]}
              className={cn({
                [styles.markdown]: true,
                [styles.message]: true,
                [styles['message-common']]: !showBadge,
                [styles['message-log']]:
                  messageType === MessageType.THOUGHT || messageType === MessageType.CONSOLE,
              })}
            >
              {String(sanitizedMarkdown.replace(/<.*?>/gi, ''))}
            </MarkdownView>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.messageResponse}>
      {showBadge && !isUserMessage && <ResponseIcon className={styles.messageIcon} />}
      <div
        className={cn(styles.messageCont, {
          [styles['message-user']]: isUserMessage,
        })}
      >
        <MarkdownView
          remarkPlugins={[remarkGfm]}
          className={cn({
            [styles.markdown]: true,
            [styles.message]: true,
            [styles['message-common']]: !showBadge,
            [styles['message-log']]:
              messageType === MessageType.THOUGHT || messageType === MessageType.CONSOLE,
          })}
        >
          {String(sanitizedMarkdown.replaceAll(/<.*?>/gi, ''))}
        </MarkdownView>
      </div>
    </div>
  );
};

export const MessageResponse: React.FC<MessageResponseProps> = ({
  metadata,
  text,
  onThumbClick,
  onHeaderClick,
  ...restProps
}) => {
  if (metadata?.type === 'Progress') {
    const additionalProps = {
      text,
      datasetName: metadata.datasetName,
      status: metadata.status,
      actionButtonText: metadata.actionButtonText,
    };

    return (
      <InProgressMessage
        {...restProps}
        isUserMessage={restProps.isUserMessage}
        {...additionalProps}
      />
    );
  }

  if (metadata?.patientsData?.length) {
    const patients = mapPatientsData(metadata.patientsData);
    const patientOptions = generatePatientOptions(patients.length);

    return (
      <CommonMessage
        onHeaderClick={onHeaderClick}
        metadata={metadata}
        isUserMessage={false}
        {...restProps}
      >
        <InteractiveChart
          title="Patients Review"
          patientsOptions={patientOptions}
          patientData={patients}
          totalPatients={patients.length}
          isOpen={true}
          pageSize={4}
          onPageChange={() => {}}
          onSave={() => {}}
        />
      </CommonMessage>
    );
  }

  if (metadata?.message_type === MessageType.RESPONSE) {
    return (
      <div>
        <AgentResponseMessage
          metadata={metadata}
          onHeaderClick={onHeaderClick}
          text={text}
          onThumbClick={onThumbClick}
          restProps={restProps}
        />
      </div>
    );
  }

  return (
    <CommonMessage
      metadata={metadata}
      onHeaderClick={onHeaderClick}
      isUserMessage={restProps.isUserMessage}
      {...restProps}
    >
      {cleanString(text)}
    </CommonMessage>
  );
};

export function cleanString(input) {
  if (typeof input !== 'string') return input;

  if (input.startsWith('"') && input.endsWith('"')) {
    input = input.slice(1, -1);
  }

  input = input.replace(/\\n/g, '\n');

  if (input.endsWith('\n')) {
    input = input.slice(0, -1);
  }

  return input;
}
