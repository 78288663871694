/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CanceledMessageId } from '../models';
import { ChatDto } from '../models';
import { ChatOutput } from '../models';
import { CopyChatDto } from '../models';
import { CreateChatDto } from '../models';
import { CreateChatOutputDto } from '../models';
import { CreateMessageDto } from '../models';
import { EditCodeAssetDto } from '../models';
import { MessageDto } from '../models';
import { ThumbActionDto } from '../models';
import { UpdateChatDto } from '../models';
/**
 * ChatsApi - axios parameter creator
 * @export
 */
export const ChatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add cancel message id to current chat, so further messages will be ignored
         * @param {CanceledMessageId} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMessage: async (body: CanceledMessageId, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cancelMessage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelMessage.');
            }
            const localVarPath = `/api/chats/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Copy a chat
         * @param {CopyChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyChat: async (body: CopyChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling copyChat.');
            }
            const localVarPath = `/api/chats/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: CreateChatDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete all chat outputs ID
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllChatOutputs: async (chat_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chat_id' is not null or undefined
            if (chat_id === null || chat_id === undefined) {
                throw new RequiredError('chat_id','Required parameter chat_id was null or undefined when calling deleteAllChatOutputs.');
            }
            const localVarPath = `/api/chats/outputs/delete/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chat_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChat: async (chat_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chat_id' is not null or undefined
            if (chat_id === null || chat_id === undefined) {
                throw new RequiredError('chat_id','Required parameter chat_id was null or undefined when calling deleteChat.');
            }
            const localVarPath = `/api/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chat_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete chat output by ID
         * @param {string} chat_output_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChatOutput: async (chat_output_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chat_output_id' is not null or undefined
            if (chat_output_id === null || chat_output_id === undefined) {
                throw new RequiredError('chat_output_id','Required parameter chat_output_id was null or undefined when calling deleteChatOutput.');
            }
            const localVarPath = `/api/chats/outputs/{chatOutputId}`
                .replace(`{${"chatOutputId"}}`, encodeURIComponent(String(chat_output_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update code and puts in a new S3 file
         * @param {EditCodeAssetDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCodeAsset: async (body: EditCodeAssetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling editCodeAsset.');
            }
            const localVarPath = `/api/chats/outputs/edit-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/chats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get messages by chat id
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChat: async (chat_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chat_id' is not null or undefined
            if (chat_id === null || chat_id === undefined) {
                throw new RequiredError('chat_id','Required parameter chat_id was null or undefined when calling getChat.');
            }
            const localVarPath = `/api/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chat_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save chat outputs
         * @param {CreateChatOutputDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveChatOutput: async (body: CreateChatOutputDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveChatOutput.');
            }
            const localVarPath = `/api/chats/outputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save shared chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSharedChat: async (chat_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chat_id' is not null or undefined
            if (chat_id === null || chat_id === undefined) {
                throw new RequiredError('chat_id','Required parameter chat_id was null or undefined when calling saveSharedChat.');
            }
            const localVarPath = `/api/chats/saved-shared/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chat_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send message
         * @param {CreateMessageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMessage: async (body: CreateMessageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendMessage.');
            }
            const localVarPath = `/api/chats/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Perform a thumb action on a message
         * @param {ThumbActionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        thumbAction: async (body: ThumbActionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling thumbAction.');
            }
            const localVarPath = `/api/chats/message/thumb-action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a new chat
         * @param {UpdateChatDto} body 
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (body: UpdateChatDto, chat_id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            // verify required parameter 'chat_id' is not null or undefined
            if (chat_id === null || chat_id === undefined) {
                throw new RequiredError('chat_id','Required parameter chat_id was null or undefined when calling update.');
            }
            const localVarPath = `/api/chats/{chatId}`
                .replace(`{${"chatId"}}`, encodeURIComponent(String(chat_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatsApi - functional programming interface
 * @export
 */
export const ChatsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add cancel message id to current chat, so further messages will be ignored
         * @param {CanceledMessageId} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelMessage(body: CanceledMessageId, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).cancelMessage(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Copy a chat
         * @param {CopyChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyChat(body: CopyChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatDto>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).copyChat(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateChatDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatDto>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).create(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete all chat outputs ID
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllChatOutputs(chat_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatOutput>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).deleteAllChatOutputs(chat_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChat(chat_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).deleteChat(chat_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete chat output by ID
         * @param {string} chat_output_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChatOutput(chat_output_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatOutput>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).deleteChatOutput(chat_output_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update code and puts in a new S3 file
         * @param {EditCodeAssetDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCodeAsset(body: EditCodeAssetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).editCodeAsset(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<ChatDto>>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).findAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get messages by chat id
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChat(chat_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).getChat(chat_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save chat outputs
         * @param {CreateChatOutputDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveChatOutput(body: CreateChatOutputDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatOutput>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).saveChatOutput(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save shared chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSharedChat(chat_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatDto>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).saveSharedChat(chat_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send message
         * @param {CreateMessageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(body: CreateMessageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<MessageDto>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).sendMessage(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Perform a thumb action on a message
         * @param {ThumbActionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thumbAction(body: ThumbActionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).thumbAction(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a new chat
         * @param {UpdateChatDto} body 
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(body: UpdateChatDto, chat_id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ChatDto>>> {
            const localVarAxiosArgs = await ChatsApiAxiosParamCreator(configuration).update(body, chat_id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ChatsApi - factory interface
 * @export
 */
export const ChatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add cancel message id to current chat, so further messages will be ignored
         * @param {CanceledMessageId} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelMessage(body: CanceledMessageId, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ChatsApiFp(configuration).cancelMessage(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Copy a chat
         * @param {CopyChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyChat(body: CopyChatDto, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatDto>> {
            return ChatsApiFp(configuration).copyChat(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new chat
         * @param {CreateChatDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateChatDto, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatDto>> {
            return ChatsApiFp(configuration).create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete all chat outputs ID
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAllChatOutputs(chat_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatOutput>> {
            return ChatsApiFp(configuration).deleteAllChatOutputs(chat_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChat(chat_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ChatsApiFp(configuration).deleteChat(chat_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete chat output by ID
         * @param {string} chat_output_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChatOutput(chat_output_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatOutput>> {
            return ChatsApiFp(configuration).deleteChatOutput(chat_output_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update code and puts in a new S3 file
         * @param {EditCodeAssetDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCodeAsset(body: EditCodeAssetDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ChatsApiFp(configuration).editCodeAsset(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all chats
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ChatDto>>> {
            return ChatsApiFp(configuration).findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get messages by chat id
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChat(chat_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ChatsApiFp(configuration).getChat(chat_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save chat outputs
         * @param {CreateChatOutputDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveChatOutput(body: CreateChatOutputDto, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatOutput>> {
            return ChatsApiFp(configuration).saveChatOutput(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save shared chat
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSharedChat(chat_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatDto>> {
            return ChatsApiFp(configuration).saveSharedChat(chat_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send message
         * @param {CreateMessageDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMessage(body: CreateMessageDto, options?: AxiosRequestConfig): Promise<AxiosResponse<MessageDto>> {
            return ChatsApiFp(configuration).sendMessage(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Perform a thumb action on a message
         * @param {ThumbActionDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async thumbAction(body: ThumbActionDto, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return ChatsApiFp(configuration).thumbAction(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a new chat
         * @param {UpdateChatDto} body 
         * @param {string} chat_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(body: UpdateChatDto, chat_id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ChatDto>> {
            return ChatsApiFp(configuration).update(body, chat_id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatsApi - object-oriented interface
 * @export
 * @class ChatsApi
 * @extends {BaseAPI}
 */
export class ChatsApi extends BaseAPI {
    /**
     * 
     * @summary Add cancel message id to current chat, so further messages will be ignored
     * @param {CanceledMessageId} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async cancelMessage(body: CanceledMessageId, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ChatsApiFp(this.configuration).cancelMessage(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Copy a chat
     * @param {CopyChatDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async copyChat(body: CopyChatDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatDto>> {
        return ChatsApiFp(this.configuration).copyChat(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a new chat
     * @param {CreateChatDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async create(body: CreateChatDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatDto>> {
        return ChatsApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete all chat outputs ID
     * @param {string} chat_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async deleteAllChatOutputs(chat_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatOutput>> {
        return ChatsApiFp(this.configuration).deleteAllChatOutputs(chat_id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a chat
     * @param {string} chat_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async deleteChat(chat_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ChatsApiFp(this.configuration).deleteChat(chat_id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete chat output by ID
     * @param {string} chat_output_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async deleteChatOutput(chat_output_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatOutput>> {
        return ChatsApiFp(this.configuration).deleteChatOutput(chat_output_id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update code and puts in a new S3 file
     * @param {EditCodeAssetDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async editCodeAsset(body: EditCodeAssetDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ChatsApiFp(this.configuration).editCodeAsset(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all chats
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async findAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ChatDto>>> {
        return ChatsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get messages by chat id
     * @param {string} chat_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async getChat(chat_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ChatsApiFp(this.configuration).getChat(chat_id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Save chat outputs
     * @param {CreateChatOutputDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async saveChatOutput(body: CreateChatOutputDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatOutput>> {
        return ChatsApiFp(this.configuration).saveChatOutput(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Save shared chat
     * @param {string} chat_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async saveSharedChat(chat_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatDto>> {
        return ChatsApiFp(this.configuration).saveSharedChat(chat_id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Send message
     * @param {CreateMessageDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async sendMessage(body: CreateMessageDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<MessageDto>> {
        return ChatsApiFp(this.configuration).sendMessage(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Perform a thumb action on a message
     * @param {ThumbActionDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async thumbAction(body: ThumbActionDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return ChatsApiFp(this.configuration).thumbAction(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a new chat
     * @param {UpdateChatDto} body 
     * @param {string} chat_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatsApi
     */
    public async update(body: UpdateChatDto, chat_id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ChatDto>> {
        return ChatsApiFp(this.configuration).update(body, chat_id, options).then((request) => request(this.axios, this.basePath));
    }
}
