import React from "react";
import styles from "../n-Input.module.scss";

export const UpArrow: React.FC<{ onClick?: () => void; }> = ({ onClick }) => (
    <svg
        className={styles["input__up-arrow"]}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 6"
        onClick={onClick}
    >
        <path
            d="M5.00048 2.21868L8.30048 5.51868L9.24315 4.57601L5.00048 0.333343L0.757812 4.57601L1.70048 5.51868L5.00048 2.21868Z"
            fill="#002B73" />
    </svg>
);
