import React from 'react';
import { Popover } from 'antd';

import Dropdown from '../../../../../../components/Dropdown/Dropdown.component';
import { ReactComponent as BinIcon } from '../../../../.././../icons/Unpin.svg';
import { ReactComponent as DownloadIcon } from '../../../../.././../icons/n-Download.svg';
import { FileIcon } from './components/FileIcon.component';
import { Summary } from './components/Summary.component';
import { chatsService } from '../../../../../../api';
import { FolderData } from '../OutputContent.component';
import { useChatStore } from '../../../../Chat.hook';
import useDownloadFile from '../../../../../../hooks/useDownloadFile';
import { Text } from 'src/components/Text/Text';

import styles from './Folder.module.scss';

export interface File {
  id: string;
  fileName: string;
  originalPath: string;
  originalFileName: string;
  isCode?: boolean;
}

const Folder: React.FC<FolderData> = ({ id, folderName, codeFileName, originalFolderName, files }) => {
  const { chats, setChats, selectedChat } = useChatStore();
  const { downloadFile } = useDownloadFile();

  const extractFileName = (filePath) => decodeURIComponent(filePath.split('?')[0].split('/').pop());

  const downloadBulk = () => {
    [{ originalPath: originalFolderName, originalFileName: extractFileName(codeFileName) }].forEach(file => {
      downloadFile({ signedUrl: file.originalPath, fileName: file.originalFileName });
    });
  }

  const deleteChatOutputsByFileId = (chats, chatId, fileId) => {
    return chats.map(chat => {
      if (chat.id === chatId) {
        return {
          ...chat,
          chat_outputs: chat.chat_outputs.filter(output => fileId !== output?.id)
        };
      }
      return chat;
    });
  };

  const deleteFile = async (id) => {
    const withDeletedItem = deleteChatOutputsByFileId(chats, selectedChat.id, id);

    setChats(withDeletedItem);

    await chatsService.deleteChatOutput(id);
    const updatedChats = await chatsService.findAll();
    setChats(updatedChats);
  }

  const filesWithoutCode = files.filter(file => !file.isCode);

  return (
    <main className={styles['folder__main']}>
      <Dropdown nested opened={!!files.length}
        title={
          <Summary
            id={id}
            onClickDownload={downloadBulk}
            folderName={folderName}
            fullFileName={extractFileName(codeFileName)}
            files={files}
          />
        }
      >
        <div className={styles['folder__file-list']}>
          {filesWithoutCode.length ? filesWithoutCode.map((file, index) => (
            <div key={index} className={styles['folder__file-wrapper']}>
              <div className={styles['folder__file-marker']} />
              <div className={styles['folder__file-item']}>

                <div className={styles['folder__file-item-wrapper']}>
                  <FileIcon file={file} />
                  <Popover content={<Text.Small>{extractFileName(file.originalFileName)}</Text.Small>} placement="topLeft">
                    <div className={styles['folder__file-text']}>
                      <p className={styles['folder__file-name']}>{file.fileName}</p>
                    </div>
                  </Popover>
                </div>
                <div className={styles['folder__file-actions']}>
                  <DownloadIcon
                    className={styles['icon']}
                    onClick={() =>
                      downloadFile(
                        { signedUrl: file.originalPath, fileName: extractFileName(file.originalFileName) }
                      )
                    }
                  />
                  <BinIcon className={styles['icon']} onClick={() => deleteFile(file.id)} />
                </div>
              </div>
            </div>
          )) : <span style={{ padding: 20, opacity: 0.6, fontStyle: 'italic' }}>
            No assets saved for this code</span>}
        </div>
      </Dropdown>
    </main>
  );
}

export default Folder;
