import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import { useChatStore } from 'src/pages/Chat/Chat.hook';
import { User } from 'src/swagger_api';

import Menu from '../../../../../components/Menu/Menu.component';
import { useModal } from '../../../../../components/Modal/Modal.hook';
import { ModalName } from '../../../../../components/Modal/modalEnums';
import { ReactComponent as ArrowIcon } from '../../../../../icons/Arrow.svg';
import { ReactComponent as CollapseIcon } from '../../../../../icons/collapse-icon-outline.svg';
import { ReactComponent as PencilRightIcon } from '../../../../../icons/item-pencil-right-top-outline.svg';
import { ReactComponent as Logo } from '../../../../../icons/logo-outline.svg';
import { ReactComponent as OutputsCollapseEmptyIcon } from '../../../../../icons/output-collapse.svg';
import { ReactComponent as OutputsCollapseFilledIcon } from '../../../../../icons/outputs-collapse-filled.svg';
import routes from '../../../../../router/routes';
import { tabs } from './Navbar.constant';
import styles from './Navbar.module.scss';
import Profile from './Profile/Profile.component';
import Tabs from './Tabs/Tabs.component';

const Navbar: FC<{}> = () => {
  const [activeTab, setActiveTab] = useState(routes.CHAT);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isViewMenuOpen, setIsViewMenuOpen] = useState(false);
  const [user, setUser] = useState<User & { email: string }>();
  const { toggleOutputs, toggleChat, isOutputsCollapsed, isChatCollapsed } = useChatStore();
  const { showModal } = useModal();

  const router = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(router.pathname);
  }, [router.pathname]);

  useEffect(() => {
    const userStorage = localStorage.getItem('user');
    const { user } = JSON.parse(userStorage);
    if (user?.id) {
      setUser(user);
    } else {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const viewOptions = tabs.map(({ title, icon: Icon, key }) => ({
    label: title,
    icon: <Icon />,
    handler: () => navigate(key),
  }));

  const accountOptions = [
    { label: 'Profile' },
    { label: 'Analysis Settings', handler: () => showModal(ModalName.ANALYSIS_SETTINGS_MODAL) },
    { label: 'Logout', handler: signOut },
  ];

  const isMobile = window.innerWidth <= 1280;
  const currentTab = tabs.find((tab) => tab.key === activeTab);

  const outputsCollapseIcon = !isOutputsCollapsed ? (
    <OutputsCollapseFilledIcon onClick={toggleOutputs} />
  ) : (
    <OutputsCollapseEmptyIcon onClick={toggleOutputs} />
  );

  const TabIcon = currentTab.icon;
  const renderMenu = (options, isOpen, onToggle) => (
    <Menu options={options} isOpen={isOpen} onToggle={onToggle}>
      <TabIcon />
      {currentTab.title}
      <ArrowIcon
        className={cn(styles['navbar__arrow'], {
          [styles['navbar__arrow-down']]: isOpen,
        })}
      />
    </Menu>
  );

  const accountMenu = (
    <Menu options={accountOptions} isOpen={isMenuOpen} onToggle={() => setIsMenuOpen(!isMenuOpen)}>
      <div className="navbar-profile">
        {user && <Profile name={user.email} isOpen={isMenuOpen} />}
      </div>
    </Menu>
  );

  const mobileLayout = (
    <div className={styles.navbar}>
      <div className={styles['navbar-actions']}>
        {isChatCollapsed && activeTab === routes.CHAT && (
          <>
            <CollapseIcon onClick={toggleChat} />
            <PencilRightIcon onClick={() => showModal(ModalName.MANAGE_CHAT_MODAL)} />
            <Logo />
          </>
        )}
        {renderMenu(viewOptions, isViewMenuOpen, () => setIsViewMenuOpen(!isViewMenuOpen))}
      </div>
      <div className={styles['navbar-account']}>
        {accountMenu}
        {user && <Profile name={user.email} isOpen={isMenuOpen} iconOnly />}
        {activeTab === routes.CHAT && outputsCollapseIcon}
      </div>
    </div>
  );

  const desktopLayout = (
    <div className={styles.navbar}>
      <Tabs tabs={tabs} activeTab={activeTab} onActiveTab={(tab) => setActiveTab(tab)} />
      {accountMenu}
      <div className={styles['navbar-profile']}>
        {user && <Profile name={user.email} isOpen={isMenuOpen} />}
      </div>
    </div>
  );

  return isMobile ? mobileLayout : desktopLayout;
};

export default Navbar;
