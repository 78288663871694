import ModalPortal from './ModalPortal.component';
import { useModal } from './Modal.hook';
import styles from './Modal.module.scss';

const Modal = () => {
    const { closeModal, modalContent } = useModal();

    if (!modalContent) {
        return null;
    }

    return (
        <ModalPortal>
            <div className={styles['modal__overlay']} onClick={() => closeModal()}>
                <div className={styles['modal__content']} onClick={(e) => e.stopPropagation()}>
                    {/* <button className={styles['modal__close-button']} onClick={() => closeModal()}>×</button> */}
                    {modalContent}
                </div>
            </div>
        </ModalPortal>
    );
};

export default Modal;
