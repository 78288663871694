import { createBrowserRouter } from 'react-router-dom';

import Dashboard from '../pages/Dashboard/Dashboard.component';
import Datasets from '../pages/Datasets/Datasets.component';
import Jobs from '../pages/Jobs/Jobs.component';
import Login from '../pages/Login';
import { AuthRoute } from './AuthRoute';
import NotFound from './NotFound';
import routes from './routes';
import Chat from '../pages/Chat/ChatContainer.component';


const router = createBrowserRouter([
  {
    path: routes.CHAT,
    element: (
      <AuthRoute>
        <Chat />
      </AuthRoute>
    ),
  },
  {
    path: routes.DATASETS,
    element: (
      <AuthRoute>
        <Datasets />
      </AuthRoute>
    ),
  },
  {
    path: routes.DASHBOARD,
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: routes.JOBS,
    element: (
      <AuthRoute>
        <Jobs />
      </AuthRoute>
    ),
  },
  {
    path: routes.LOGIN,
    element: <Login />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
