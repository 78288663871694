export const fetchFileContent = async (filePath: string) => {
    const response = await fetch(filePath);
    
    if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let content = '';
    let done = false;

    while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        content += decoder.decode(value, { stream: !done });
    }

    return content;
};
