import React from "react";
import styles from "./OutputSkeleton.module.scss";

const OutputSkeleton: React.FC = () => {
    const items = [1, 2, 3, 4];

    return (
        <div className={styles["output-skeleton__container"]}>
            <div className={styles["output-skeleton__top-row"]}>
                <div className={styles["output-skeleton__top-row__blockContainer"]}>
                    <div className={styles["output-skeleton__top-row__block"]} />
                </div>
                <div className={styles["output-skeleton__top-row__blockContainer"]} >
                    <div className={styles["output-skeleton__top-row__block"]} />
                </div>
            </div>
            {items.map((_, index) => (
                <div className={styles["output-skeleton__item"]} key={index}>
                    <div className={styles["output-skeleton__item__square"]} />
                    <div className={styles["output-skeleton__item__rectangle"]} />
                </div>
            ))}
        </div>
    );
};

export default OutputSkeleton;
