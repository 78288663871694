/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AnalysisSettings } from '../models';
import { CreateOrganizationDto } from '../models';
import { Organization } from '../models';
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create organization
         * @param {CreateOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: CreateOrganizationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/organization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get analysis settings by organization id
         * @param {string} organization_id 
         * @param {string} analysis_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisSettingsByOrganizationId: async (organization_id: string, analysis_type: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organization_id' is not null or undefined
            if (organization_id === null || organization_id === undefined) {
                throw new RequiredError('organization_id','Required parameter organization_id was null or undefined when calling getAnalysisSettingsByOrganizationId.');
            }
            // verify required parameter 'analysis_type' is not null or undefined
            if (analysis_type === null || analysis_type === undefined) {
                throw new RequiredError('analysis_type','Required parameter analysis_type was null or undefined when calling getAnalysisSettingsByOrganizationId.');
            }
            const localVarPath = `/api/organization/{organizationId}/{analysisType}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organization_id)))
                .replace(`{${"analysisType"}}`, encodeURIComponent(String(analysis_type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create organization
         * @param {CreateOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateOrganizationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Organization>>>> {
            const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration).create(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get analysis settings by organization id
         * @param {string} organization_id 
         * @param {string} analysis_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisSettingsByOrganizationId(organization_id: string, analysis_type: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AnalysisSettings>>>> {
            const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration).getAnalysisSettingsByOrganizationId(organization_id, analysis_type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create organization
         * @param {CreateOrganizationDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateOrganizationDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Organization>>> {
            return OrganizationApiFp(configuration).create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get analysis settings by organization id
         * @param {string} organization_id 
         * @param {string} analysis_type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisSettingsByOrganizationId(organization_id: string, analysis_type: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AnalysisSettings>>> {
            return OrganizationApiFp(configuration).getAnalysisSettingsByOrganizationId(organization_id, analysis_type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * 
     * @summary Create organization
     * @param {CreateOrganizationDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public async create(body: CreateOrganizationDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Organization>>> {
        return OrganizationApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get analysis settings by organization id
     * @param {string} organization_id 
     * @param {string} analysis_type 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public async getAnalysisSettingsByOrganizationId(organization_id: string, analysis_type: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AnalysisSettings>>> {
        return OrganizationApiFp(this.configuration).getAnalysisSettingsByOrganizationId(organization_id, analysis_type, options).then((request) => request(this.axios, this.basePath));
    }
}
