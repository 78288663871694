import { CreateUserDto, UsersApi } from '../../swagger_api';
import { protectedConfiguration } from '../swagger';

class UsersService {
  usersApi: UsersApi;

  constructor() {
    this.usersApi = new UsersApi(protectedConfiguration);
  }

  async findAll() {
    try {
      const response = await this.usersApi.findAll();
      return response;
    } catch (error) {
      console.error('Error in register:', error);
      throw error;
    }
  }

  async findOne(id: number) {
    try {
      const response = await this.usersApi.findOne(id);
      return response;
    } catch (error) {
      console.error('Error in register:', error);
      throw error;
    }
  }

  async create(userDto: CreateUserDto) {
    try {
      const response = await this.usersApi.createUser(userDto);
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  }

  async getCurrentUser() {
    try {
      const response = await this.usersApi.getCurrentUser();
      return response;
    } catch(error) {
      console.error('Error when getting current user:', error);
      throw error;
    }
  }

  async deleteSharedChat(userId: string, chatId: string) {
    try {
      const response = await this.usersApi.deleteSharedChat(userId, chatId);
      return response;
    } catch (error) {
      console.error('Error when updating user:', error);
      throw error;
    }
  }
}

export const usersService = new UsersService();
