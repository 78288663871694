import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import cn from 'classnames';
import TableSkeleton from 'src/components/TableSkeleton/TableSkeleton';

import Layout from '../Chat/components/Layout/Layout.component';
import styles from './Datasets.module.scss';
import './Datasets.scss';
import DatasetTable from './components/DatasetTable/DatasetTable';
import { DatasetsList } from './components/DatasetsList/DatasetsList';
import { useDatasets } from './hooks/useDatasets';

const Datasets = () => {
  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const openedItemId = queryParams.get('id');

  const [selected, setSelected] = useState(openedItemId);
  const { datasets, getCsvTable, csvData, isLoading } = useDatasets(openedItemId);

  const navigate = useNavigate();

  const openDataset = useCallback(
    ({ id }) => {
      getCsvTable(id);
      setSelected(id);
      queryParams.set('id', id);
      navigate({
        search: queryParams.toString(),
      });
    },
    [getCsvTable, navigate, queryParams],
  );

  return (
    <Layout>
      <div className={styles.sidebarActionsCont}>
        <div className={styles.datasetCont}>
          <div
            className={cn(styles.datasetPaper, { [styles['datasetPaper-opened']]: !!openedItemId })}
          >
            <DatasetsList
              datasets={datasets}
              openDataset={openDataset}
              selectedDataset={selected}
            />
          </div>

          <div
            className={cn(styles.csvTableCont, { [styles['csvTableCont-opened']]: openedItemId })}
          >
            {isLoading || !csvData.length ? (
              <div className={styles['csvTableCont-dataset']}>
                <div className={styles.title}>Datasets</div>
                <div>
                  <TableSkeleton className={styles.skeleton} />
                </div>
              </div>
            ) : (
              <DatasetTable
                csvData={csvData}
                datasets={datasets}
                openDataset={openDataset}
                selectedDatasetId={selected}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Datasets;
