import CodeEditor from '../../../../Jobs/components/JobPage/components/CodeEditor/CodeEditor.component';
import useEditableCodeStore from '../EditCodeModal.hook'

interface EditableCodeEditorProps {
  code: string;
}

const EditableCodeEditor = ({ code }: EditableCodeEditorProps) => {
  const {  loading, setCurrentCode,setIsEdited } = 
  useEditableCodeStore();

  const handleEditorChange = (value: string | undefined) => {
    if (value !== undefined) {
      setCurrentCode(value);
      setIsEdited(true);
    }
  };

  return (
    <div>
      <CodeEditor
        overrideCodeEditorHeight={1000}
        code={code}
        onChange={handleEditorChange} 
        readOnly={loading} 
      />
    </div>
  );
};

export default EditableCodeEditor;
