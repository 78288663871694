import React from 'react';

import { ReactComponent as ChartIcon } from '../../../../../../icons/32/Chart.svg';
import { ReactComponent as CodeIcon } from '../../../../../../icons/32/Code.svg';
import { ReactComponent as TableIcon } from '../../../../../../icons/32/Table.svg';
import styles from './EmptyFolder.module.scss';

interface IconGroupProps {
  icons: React.FunctionComponent<React.SVGProps<SVGSVGElement>>[];
}

const IconGroup: React.FC<IconGroupProps> = ({ icons }) => (
  <div className={styles['empty-folder__icon-group']}>
    {icons.map((Icon, index) => (
      <Icon className={styles['empty-folder__img']} key={index} />
    ))}
  </div>
);

const EmptyFolder: React.FC = () => (
  <section className={styles['empty-folder__section']}>
    <IconGroup icons={[ChartIcon, TableIcon, CodeIcon]} />
    <p className={styles['empty-folder__paragraph']}>
      Save here <span className={styles['empty-folder__bold-text']}>code</span>,{' '}
      <span className={styles['empty-folder__bold-text']}>plot</span> or{' '}
      <span className={styles['empty-folder__bold-text']}>table</span>.
    </p>
  </section>
);

export default EmptyFolder;
