import cn from 'classnames';

import styles from './Paper.module.scss';

type PaperProps = {
  children: React.ReactNode;
  className?: string;
};

export const Paper = ({ children, className }: PaperProps) => {
  return <div className={cn(styles.paper, className)}>{children}</div>;
};
