import { useCallback, useEffect } from 'react';


import { useModal } from '../../../../../../components/Modal/Modal.hook';
import ModalContent from '../../../../../../components/Modal/ModalContent.component';
import { ModalName } from '../../../../../../components/Modal/modalEnums';
import styles from './SaveDatasetModal.module.scss';
import SaveDatasetModalBody from './SaveDatasetModalBody';

const SaveDatasetModal = ({ datasetFilePath }) => {
  const { initializeModal } = useModal();

  const initializeManageChatModal = useCallback(() => {
    initializeModal(
      ModalName.SAVE_DATASET_MODAL,
      <ModalContent
        className={styles['dataset-modal']}
        header={<>{'Save to the Dataset'}</>}
        body={<SaveDatasetModalBody datasetFilePath={datasetFilePath} />}
        footer={null}
        classNameBody={styles['dataset-modal-body']}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeManageChatModal();
  }, [initializeManageChatModal]);

  return null;
};

export default SaveDatasetModal;
