import { FC } from 'react';

import { useChatStore } from '../../Chat.hook';
import ChatSkeleton from '../ChatSkeleton/ChatSkeleton.component';
import styles from './Layout.module.scss';
import Navbar from './Navbar/Navbar.component';
import Sidebar from './Sidebar/Sidebar.component';

interface LayoutProps {
  children: React.ReactNode;
  isChat?: boolean;
}

const Layout: FC<LayoutProps> = ({ children, isChat }) => {
  const { isLoading } = useChatStore();

  return (
    <div className={styles.layout}>
      {isChat && <Sidebar />}
      <div className={styles.layoutWorkspace}>
        <Navbar />
        {children}
        {isLoading && <ChatSkeleton />}
      </div>
    </div>
  );
};

export default Layout;
