import { useSearchParams } from 'react-router-dom';

import Layout from '../Chat/components/Layout/Layout.component';
import { JobPage, JobsLayout, JobsTables } from './components';
import { useJobsData } from './hooks/useJobsData';

const Jobs = () => {
  const { jobs, loading } = useJobsData();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  if (loading) return <Layout>Loading...</Layout>;

  return (
    <Layout>
      <JobsLayout>
        {jobId ? (
          <JobPage jobId={jobId} jobs={jobs} />
        ) : (
          <JobsTables jobs={jobs} isLoading={loading} />
        )}
      </JobsLayout>
    </Layout>
  );
};

export default Jobs;
