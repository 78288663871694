
import cn from "classnames";
import { FC } from "react";

import styles from "./Profile.module.scss"
import { ReactComponent as ArrowIcon } from "../../../../../../icons/Arrow.svg"
import { ReactComponent as UserIcon } from "../../../../../../icons/User.svg"


interface ProfileProps {
    name: string;
    isOpen?: boolean;
    iconOnly?: boolean;
}

const Profile: FC<ProfileProps> = ({ name, isOpen, iconOnly = false }) => {
    return <div className={styles.profile}>
        <UserIcon />
        {!iconOnly && name}
        <ArrowIcon className={cn(styles['profile__arrow'], { [styles['profile__arrow-down']]: isOpen })} />
    </div>
}

export default Profile;