import { useState } from "react";

const useNumberInput = (
    initialValue: number,
    min?: number,
    max?: number,
    step = 1,
    onChange?: (newValue: number) => void
) => {
    const [value, setValue] = useState(initialValue);


    const increment = () => {
        const newValue = value + step;
        const clampedValue = max !== undefined ? Math.min(newValue, max) : newValue;
        setValue(clampedValue);
        if (onChange) onChange(clampedValue);
    };

    const decrement = () => {
        const newValue = value - step;
        const clampedValue = min !== undefined ? Math.max(newValue, min) : newValue;
        setValue(clampedValue);
        if (onChange) onChange(clampedValue);
    };

    return { value, increment, decrement };
};

export default useNumberInput;
