import { AxiosResponse } from 'axios';

import { OrganizationApi } from '../../swagger_api';
import { protectedConfiguration } from '../swagger';

class OrganizationService {
    organizationApi: OrganizationApi;

    constructor() {
        this.organizationApi = new OrganizationApi(protectedConfiguration);
    }

    getAnalysisSettingsByOrganizationId = async (organizationId: string, analysisType: string): Promise<AxiosResponse<any>> => {
        try {
            const response = await this.organizationApi.getAnalysisSettingsByOrganizationId(organizationId, analysisType);
            return response;
        } catch (error) {
            console.error('Error in login:', error);
            throw error;
        }
    };
}

export const organizationService = new OrganizationService();
