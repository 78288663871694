export const ADMINISTRATION_TYPES = [
    { value: "IV", label: "Intravenous" },
    { value: "Oral", label: "Oral" },
    { value: "Other", label: "Other" }
];

export const INTEGRAL_METHODS = [
    { value: "Linear trapezoidal linear", label: "Linear trapezoidal linear" },
    { value: "Other methods", label: "Other methods" }
];

export const BLQ_METHODS = [
    { value: "LLOQ/2", label: "LLOQ/2" },
    { value: "Other methods", label: "Other methods" }
];

export const WEIGHTING_METHODS = [
    { value: "Linear", label: "Linear" },
    { value: "Other methods", label: "Other methods" }
];

export const initialState = {
    // Settings
    administrationType: ADMINISTRATION_TYPES[0].value,
    integralMethod: INTEGRAL_METHODS[0].value,
    partialAUCTimeMin: 0,
    partialAUCTimeMax: 7,
    blqMethodAfterTmax: BLQ_METHODS[0].value,
    // Acceptance criteria
    r2: 0.1,
    adjustedR2Percentage: 0.98,
    adjustedR2MaxPoints: 5,
    adjustedR2MinTime: 2,
    numberOfPoints: 5,
    intervalMin: 1,
    intervalMax: 7,
    weighting: WEIGHTING_METHODS[0].value,
    extrapolatedAUC: '20%',
    span: 1
};

export const FIELD_PROPERTIES = {
    administrationType: {
        label: "Administration type",
    },
    integralMethod: {
        label: "Integral Method",
    },
    partialAUCTimeMin: {
        label: "Partial AUC Time",
        description: "Min (days)",
    },
    partialAUCTimeMax: {
        label: "Partial AUC Time",
        description: "Max (days)",
    },
    blqMethodAfterTmax: {
        label: "BQL Method after Tmax",
    },
    r2: {
        label: "R^2",
    },
    adjustedR2Percentage: {
        label: "Adjusted R^2",
    },
    adjustedR2MaxPoints: {
        label: "Maximum number of points",
    },
    adjustedR2MinTime: {
        label: "Minimum time",
    },
    numberOfPoints: {
        label: "Number of Points",
    },
    intervalMin: {
        label: "Interval",
        description: "Min (days)",
    },
    intervalMax: {
        label: "Interval (Max)",
        description: "Max (days)",
    },
    weighting: {
        label: "Weighting",
    },
    extrapolatedAUC: {
        label: "% extrapolated AUC",
    },
    span: {
        label: "Span (number of terminal half-lives in the terminal phase)",
    },
};

export const FIELD_NAMES = {
    ADMINISTRATION_TYPE: "administrationType",
    INTEGRAL_METHOD: "integralMethod",
    PARTIAL_AUC_TIME_MIN: "partialAUCTimeMin",
    PARTIAL_AUC_TIME_MAX: "partialAUCTimeMax",
    BLQ_METHOD_AFTER_TMAX: "blqMethodAfterTmax",
    R2: "r2",
    ADJUSTED_R2_PERCENTAGE: "adjustedR2Percentage",
    ADJUSTED_R2_MAX_POINTS: "adjustedR2MaxPoints",
    ADJUSTED_R2_MIN_TIME: "adjustedR2MinTime",
    NUMBER_OF_POINTS: "numberOfPoints",
    INTERVAL_MIN: "intervalMin",
    INTERVAL_MAX: "intervalMax",
    WEIGHTING: "weighting",
    EXTRAPOLATED_AUC: "extrapolatedAUC",
    SPAN: "span"
};
