import { MessageDto } from 'src/swagger_api';
import { MessageType } from 'src/types/agentResponse';

import { ChatState } from '../Chat.hook';

const getMessagesToUpdate = (prevMessages: MessageDto[], message: MessageDto) => {
  const existingIndex = prevMessages.findIndex(
    (m) => m.metadata?.message_id === message.metadata?.message_id,
  );
  if (
    existingIndex !== -1 &&
    message.metadata?.type === 'Progress' &&
    message.message_author === 'conversational_agent'
  ) {
    const updatedMessages = [...prevMessages];
    updatedMessages[existingIndex] = message;
    return updatedMessages;
  } else {
    return [...prevMessages, message];
  }
};

export const processMessages = (message: MessageDto, prevMessages: ChatState['messages']) => {
  const { logMessages, chatMessages } = prevMessages;

  const updatedLogMessages = new Map(logMessages.map((msg) => [msg.id, msg]));
  const newMessages = getMessagesToUpdate(chatMessages, message);

  newMessages.forEach((message, index) => {
    if (message.metadata.message_type === MessageType.THOUGHT) {
      updatedLogMessages.set(message.id, message);
    }

    if (message.metadata.message_type === MessageType.RESPONSE) {
      const previousConsoleMessages = [];

      for (let i = index - 1; i >= 0 && previousConsoleMessages.length < 5; i--) {
        if (newMessages[i]?.metadata.message_type === MessageType.THOUGHT) {
          previousConsoleMessages.push(newMessages[i].id);
        }
      }

      if (previousConsoleMessages.length) {
        message.metadata.referenceMessages = previousConsoleMessages as MessageDto[];
      }

      const refMessage = newMessages.find(
        (msg) =>
          msg.metadata.message_type === MessageType.RESPONSE &&
          new Date(msg.created_at) > new Date(message.created_at),
      );
      if (refMessage) {
        message.metadata.referenceMessageId = refMessage.id;
      }
    }
  });

  return {
    chatMessages: newMessages,
    updatedLogMessages: Array.from(updatedLogMessages.values()),
  };
};
