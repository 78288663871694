import React, { useState, useEffect } from "react";
import cn from "classnames";

import { ReactComponent as BackIcon } from "../../icons/Back.svg"
import styles from "./Select.module.scss";


export interface Option {
    value: string;
    label: string;
}

interface SelectProps {
    options: Option[];
    selectedOption: string;
    label?: string;
    revertButton?: boolean;
    className?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;

}

const Select: React.FC<SelectProps> = ({
    label,
    options,
    selectedOption,
    revertButton = true,
    className,
    disabled,
    onChange,
}) => {
    const [originalOption, setOriginalOption] = useState(selectedOption);
    const [notificationText, setNotificationText] = useState("");

    useEffect(() => {
        setOriginalOption(selectedOption);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleIconClick = () => {
        onChange(originalOption);
        setNotificationText("");
    };

    const handleChange = (value: string) => {
        onChange(value);
        if (value !== originalOption) {
            setNotificationText("It has been changed");
        } else {
            setNotificationText("");
        }
    };

    return (
        <main>
            <section className={styles.select__section}>
                <div className={styles.select__details}>
                    {label && <label className={styles.select__label}>{label}</label>}
                    <div className={styles.select__box}>
                        <div className={styles.select__selectWrapper}>
                            <select
                                className={cn(styles.select__select, className)}
                                value={selectedOption}
                                onChange={(e) => handleChange(e.target.value)}
                                disabled={disabled}
                            >
                                {options.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.select__icons}>
                            {revertButton && <div
                                className={styles.select__iconWrapper}
                                onClick={handleIconClick}
                            >
                                <BackIcon
                                    className={styles.select__image}
                                />
                            </div>}
                        </div>
                    </div>
                    {notificationText && revertButton && (
                        <p className={styles.select__changeNotice}>
                            {notificationText}
                        </p>
                    )}
                </div>
            </section>
        </main>
    );
};

export default Select;
