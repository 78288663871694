import { useState } from 'react';
import MarkdownView from 'react-showdown';

import classNames from 'classnames';
import SpinnerLoading from 'src/components/Loading/SpinnerLoading/SpinnerLoading';
import { DownArrow } from 'src/components/n-Input/components/DownArrow';
import { MessageDto } from 'src/swagger_api';

import { replaceInvalidTags } from '../MessageResponse/MessageResponse';
import styles from './LogsManager.module.scss';

type Props = {
  logMessages: MessageDto[];
  currentThought: string;
};

const LogsManager = ({
  logMessages = [],
  currentThought = 'Gathering my thoughts',
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <div className={styles['logs-manager']}>
      <div
        className={classNames(styles['logs-manager-header'], {
          [styles['logs-manager-header-collapsed']]: isCollapsed,
        })}
      >
        <div className={styles['logs-manager__processing']}>
          <SpinnerLoading />
          <p>{currentThought}</p>
        </div>

        {!!logMessages?.length && (
          <div className={styles['logs-manager__processing-arrow']} onClick={toggleCollapse}>
            <p>See me thinking</p>
            <DownArrow onClick={() => {}} />
          </div>
        )}
      </div>
      <div className={`${styles['logs-manager-messages']} ${isCollapsed ? styles.collapsed : ''}`}>
        {logMessages?.map((message) => {
          const sanitizedMarkdown = replaceInvalidTags(message.text as string);

          return (
            <div key={message.id}>
              <MarkdownView
                className={styles['logs-manager-messages-item']}
                markdown={String(sanitizedMarkdown.replaceAll(/<.*?>/gi, ''))}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LogsManager;
