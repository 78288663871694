export interface Metadata {
  chat_id: string;
  message_id: string;
  replied_to_message_id: string;
  user_id: string;
  dataset: string;
}

export enum MessageType {
  ASSET = 'agent_asset',
  THOUGHT = 'agent_thought',
  CONSOLE = 'agent_console',
  CONFIRMATION = 'agent_confirmation',
  INPUT_BUTTON = "agent_input_button",
  RESPONSE = 'agent_response',
  ERROR = 'error_message',
}

export enum CommandName {
  FEEDBACK = 'feedback'
}

export enum MessageTarget {
  CONVERSATIONAL_AGENT = 'conversational_agent',
}

export interface AgentResponse {
  metadata: Metadata;
  button_context: {
    button_content: string[];
    button_type: string;
  };
  message_author: string;
  message: string;
  partial: boolean;
  message_target: MessageTarget;
  message_type: MessageType;
  job_context: any;
  command: string;
  command_context: string;
}
