import { useEffect, useMemo, useRef, useState } from 'react';
import { memo } from 'react';

import cn from 'classnames';
import classNames from 'classnames';

import Input from '../../../../../../components/Input/Input.component';
import { ReactComponent as EditPlotsIcon } from '../../../../../../icons/EditPlots.svg';
import { ReactComponent as CloseIcon } from '../../../../../../icons/close.svg';
import { ReactComponent as SendIcon } from '../../../../../../icons/send-icon.svg';
import { ReactComponent as StopIcon } from '../../../../../../icons/stop_button.svg';
import { useChatStore } from '../../../../Chat.hook';
import styles from './MessageInput.module.scss';

const cancelChatEnabled = process.env.REACT_APP_FF_CANCEL_CHAT === 'true';

type MessageInputType = any;

const MessageInput = ({
  sendMessage,
  messageValue,
  setMessageValue,
  waitingForResponse,
  disabled,
  isWelcome,
  scrollToMessage,
  lastMessageSuggestions,
  cancelChat,
}: MessageInputType) => {
  const { replyMode, setReplyMode, isChatCollapsed, isOutputsCollapsed } = useChatStore();
  const [isVisible, setIsVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const closeReply = () => {
    setIsVisible(false);
    setTimeout(() => setReplyMode(null), 500);
  };

  const onSendMessage = (e) => {
    if (e.key === 'Enter' && !waitingForResponse && !disabled) {
      const suggestionData = lastMessageSuggestions
        ? {
            message_type: 'human_button',
            button_type: 'user_confirmation',
          }
        : null;
      sendMessage(null, suggestionData);
    }
  };

  useEffect(() => {
    if (replyMode) {
      setIsVisible(true);
      inputRef?.current?.focus();
    }
  }, [replyMode]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const messagesContainerClassName = useMemo(() => {
    if (isChatCollapsed && isOutputsCollapsed) {
      return classNames(styles['input-container'], [styles['input-container-collapsed']]);
    }
    return classNames(styles['input-container'], {
      [styles['input-container-sidebar-collapsed']]: isChatCollapsed,
      [styles['input-container-outputs-collapsed']]: isOutputsCollapsed,
    });
  }, [isChatCollapsed, isOutputsCollapsed]);

  return (
    <div className={messagesContainerClassName}>
      {replyMode && (
        <div
          onClick={() => scrollToMessage(replyMode.messageId)}
          className={cn(styles['input-container__plot-info'], {
            [styles['input-container__plot-info--show']]: isVisible,
            [styles['input-container__plot-info--hide']]: !isVisible,
          })}
        >
          <div className={styles['input-container__info-container']}>
            <EditPlotsIcon className={styles['input-container__edit-icon']} />
            <div className={styles['input-container__text']}>
              <h1 className={styles['input-container__title']}>{replyMode.assetFileName}</h1>
              <p className={styles['input-container__description']}>
                You are going to edit the plot. Please describe what would you like to edit on the
                plot?
              </p>
            </div>
          </div>
          <CloseIcon onClick={closeReply} />
        </div>
      )}
      <Input
        inputRef={inputRef}
        type="text"
        placeholder="Ask me anything..."
        className={styles['input-container__input']}
        required
        value={messageValue}
        onChange={setMessageValue}
        onKeyPress={onSendMessage}
      />
      {!disabled && (
        <SendIcon className={styles['input-container__send-icon']} onClick={sendMessage} />
      )}
      {disabled && cancelChatEnabled && (
        <StopIcon className={styles['input-container__send-icon']} onClick={cancelChat} />
      )}
    </div>
  );
};

export default memo(MessageInput);
