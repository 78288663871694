import SpinnerLoading from 'src/components/Loading/SpinnerLoading/SpinnerLoading';

import styles from './assets.module.scss';

const AssetsLoading = () => {
  return (
    <div className={styles['assets-loading']}>
      <SpinnerLoading />
    </div>
  );
};

export default AssetsLoading;
