import React from 'react';

import { useChatStore } from '../../../../Chat.hook';
import { Card } from './Card/Card.component';
import styles from './Welcome.module.scss';

const description = `InsightRx Apollo-AI is an Intelligence-Augmented Analysis Platform that offers versatile and comprehensive clinical pharmacology analysis and reporting capabilities for clinical trials.`;

const cardData = [
  [
    {
      title: 'Continuous Demographic Variables Summary',
      description: 'Summarize continuous demographic variables',
    },
    {
      title: 'Concentration vs Time by Treatment Group',
      description: 'Visualize PK concentrations over time by treatment group',
    },
  ],
  [
    {
      title: 'Concentration vs Time by Individual',
      description: 'Visualize PK concentrations over time by individual',
    },
    {
      title: 'Review Anomalous Data',
      description: 'Review anomalous data across different treatment groups',
    },
  ],
];

interface WelcomeProps {
  sendSuggestedMessage: (message: string) => Promise<void>;
  hideSuggestions?: boolean;
}

const Welcome: React.FC<WelcomeProps> = ({ sendSuggestedMessage, hideSuggestions = false }) => {
  const { selectedChat } = useChatStore();

  return (
    <section className={styles['welcome__main']}>
      <header className={styles['welcome__header']}>
        <div className={styles['welcome__logo']}>IRX</div>
        <h1 className={styles['welcome__title']}>Welcome to InsightRX</h1>
        <p className={styles['welcome__description']}>{description}</p>
      </header>
      {selectedChat && !hideSuggestions &&
        cardData.map((row, rowIndex) => (
          <section key={rowIndex} className={styles['welcome__content']}>
            {row.map((card, cardIndex) => (
              <Card
                key={cardIndex}
                title={card.title}
                description={card.description}
                onClick={() => sendSuggestedMessage(card.description)}
              />
            ))}
          </section>
        ))}
    </section>
  );
};

export default Welcome;
