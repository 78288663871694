import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { chatsService, usersService } from '../../api';
import routes from '../../router/routes';
import ChatComponent from './Chat.component';
import { useChatStore } from './Chat.hook';
import { sortAndExtractChats } from './utils';

const ChatContainer = () => {
  const { setIsLoading, setChats, setSharedChats } = useChatStore();
  const navigate = useNavigate();

  const fetchAllChats = useCallback(async () => {
    try {
      setIsLoading(true);
      const chats = await chatsService.findAll();
      setChats(chats);

    } catch (err) {
      setIsLoading(false);
    }
  }, [setChats, setIsLoading]);
  
  const fetchAllSharedChats = useCallback(async () => {
    try {
      const user = await usersService.getCurrentUser();
      const sharedChats = (user.data as any).sharedChats;
      const sortedChats = sortAndExtractChats(sharedChats);
      setSharedChats(sortedChats);
    } catch (err) {
      throw err;
    }
  }, [setSharedChats]);
  
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const user = localStorage.getItem('user');
    if (!accessToken || !user) {
      navigate(routes.LOGIN);
    } else {
      Promise.all([fetchAllChats(), fetchAllSharedChats()]).then(() => {
          setIsLoading(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllChats, navigate, setIsLoading]);

  return <ChatComponent />;
};

export default ChatContainer;
