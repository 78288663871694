import { RouterProvider } from 'react-router-dom';

import { ModalProvider } from './components/Modal/ModalContext.component';
import ErrorBoundary from './lib/error/ErrorBoundary';
import router from './router/router';

const App = () => {
  return (
    <ModalProvider>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </ModalProvider>
  );
};

export default App;
