import { useEffect, useState } from 'react';
import  { Editor} from '@monaco-editor/react';
import './CodeEditor.style.scss';
import SpinnerLoading from '../../../../../../components/Loading/SpinnerLoading/SpinnerLoading';
import { fetchFileContent } from './CodeEditor.service';

interface CodeEditorProps {
  code: string;
  onChange?: (value: string | undefined) => void;
  readOnly?: boolean;
  overrideCodeEditorHeight?: number;
}

const MAX_DEFAULT_HEIGHT = 700;

const CodeEditor = ({ code, overrideCodeEditorHeight, onChange, readOnly = true }: CodeEditorProps) => {
  const [currentCodeFile, setCurrentCodeFile] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [currentEditorHeight, setEditorHeight] = useState('');

  const getFile = async (filePath: string) => {
    setLoading(true);

    try {
        const text = await fetchFileContent(filePath);

        setCurrentCodeFile(text);

        const lines = text.split('\n').length;
        const lineHeight = 18;
        const newHeight = lines * lineHeight + 18;

        if (overrideCodeEditorHeight) {
            setEditorHeight(`${overrideCodeEditorHeight}px`);
        } else {
            setEditorHeight(`${MAX_DEFAULT_HEIGHT < newHeight ? MAX_DEFAULT_HEIGHT : newHeight}px`);
        }
    } catch (error) {
        console.error("Error fetching file content:", error);
    } finally {
        setTimeout(() => {
            setLoading(false);
        }, 700);
    }
  };

  useEffect(() => {
    if (code) {
      getFile(code);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (loading) {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 740 }}>
      <SpinnerLoading />
    </div>
  }

  if (!currentCodeFile) {
    return null;
  }

  return (
    <Editor
      theme="vs-dark"
      value={currentCodeFile}
      height={currentEditorHeight}
      onChange={onChange} 
      options={{
        readOnly,
        scrollBeyondLastLine: false,
        minimap: { enabled: false },
        scrollbar: {
          vertical: 'hidden',
          handleMouseWheel: true,
          alwaysConsumeMouseWheel: false,
        },
        automaticLayout: true,
      }}
      language="r"
    />
  );
};

export default CodeEditor;
