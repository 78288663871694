import { useCallback } from 'react';

const useDownloadFile = () => {
  const downloadFile = useCallback(async ({ signedUrl, fileName }) => {
    try {
      const response = await fetch(signedUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
    }
  }, []);

  return { downloadFile };
};

export default useDownloadFile;
