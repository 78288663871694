import { DatasetDto } from '@/swagger_api';
import { create } from 'zustand';

type Modes = 'edit' | 'create' | 'delete' | 'delete_shared';
type ActionButtonsPosition = 'left' | 'right';

interface ManageChatModalInitialState {
    title: string;
    chatName: string;
    actionButtonsPosition: ActionButtonsPosition;
    selectedDataset: any | null;
    activeDataset: string;
    mode: Modes;
    actionButtonText: string;
    datasets: DatasetDto[];
    chatId: string;
    isCreating: boolean;
}

interface ManageChatModalStore {
    setTitle: (title: string) => void;
    setDatasets: (datasets: DatasetDto[]) => void;
    setSelectedDataset: (datasets: any) => void;
    setActionButtonText: (text: string) => void;
    setChatName: (name: any) => void;
    setMode: (mode: Modes) => void;
    setChatId: (id: string) => void;
    setIsCreating: (isCreating: boolean) => void;
    setActionButtonPosition: (position: ActionButtonsPosition) => void;
    reset: () => void;
    setActiveDataset: (dataset: string) => void;
}

const initialState: ManageChatModalInitialState = {
    title: 'Select dataset and chat name',
    chatName: '',
    actionButtonsPosition: 'left',
    selectedDataset: 'tiger',
    activeDataset: '',
    datasets: [],
    mode: 'create',
    actionButtonText: 'create',
    chatId: null,
    isCreating: false
}

const useManageChatModalStore = create<ManageChatModalStore & ManageChatModalInitialState>((set) => ({
    ...initialState,
    setActionButtonText: (text) => set({ actionButtonText: text }),
    setTitle: (title) => set({ title }),
    setDatasets: (datasets) => set({ datasets }),
    setSelectedDataset: (selectedDataset) => set({ selectedDataset }),
    setChatName: (name) => set({ chatName: name }),
    setMode: (mode) => set({ mode }),
    setChatId: (id) => set({ chatId: id }),
    setIsCreating: (isCreating) => set({ isCreating }),
    setActionButtonPosition: (position) => set({ actionButtonsPosition: position }),
    reset: () => set({ ...initialState }),
    setActiveDataset: (dataset) => set({ activeDataset: dataset })

}));

export default useManageChatModalStore;
