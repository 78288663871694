import { useModal } from "../../../../components/Modal/Modal.hook";
import Button from "../../../../components/Button/Button.component";

import styles from "./AnalysisModalFooter.module.scss"
import useAnalysisModalStore from "./AnalysisModal.hook";

import { useOrgAnalysisSettings } from "./hooks/useOrgAnalysisSettings";

const AnalysisModalFooter = () => {
    const { closeModal } = useModal();

    const { hasError } = useAnalysisModalStore();
    const { syncOrgAnalysisSettings } = useOrgAnalysisSettings()

    const onApplyNewOrganizationSettings = () => {
        syncOrgAnalysisSettings()
        closeModal()
    }

    return <div className={styles['analysis-modal-footer']}>
        <Button
            onClick={closeModal}
            color="secondary">
            Cancel
        </Button>
        <div className={styles['analysis-modal-footer__actions']}>
            <Button
                disabled={hasError}
                onClick={onApplyNewOrganizationSettings}
                color="secondary">
                Use as Organization Default Settings
            </Button>
        </div>
    </div>
}

export default AnalysisModalFooter;