import { useEffect, useState } from 'react';

import { jobsService } from '../../../api/jobs/jobs.service';
import { JobDto } from '../../../swagger_api';
import { JobStatus } from '../../../types/enums/job-status';

export type JobsData = {
  [JobStatus.COMPLETED]: JobDto[] | [];
  [JobStatus.IN_PROGRESS]: JobDto[] | [];
  [JobStatus.QUEUED]: JobDto[] | [];
  [JobStatus.CANCELED]: JobDto[] | [];
  [JobStatus.FAILED]: JobDto[] | [];
};

export const useJobsData = () => {
  const [jobsData, setJobsData] = useState<JobsData>({
    [JobStatus.COMPLETED]: [],
    [JobStatus.IN_PROGRESS]: [],
    [JobStatus.QUEUED]: [],
    [JobStatus.CANCELED]: [],
    [JobStatus.FAILED]: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    jobsService.getJobs().then(({ data }) => {
      setJobsData({
        [JobStatus.COMPLETED]: data.filter((job) => job.status === JobStatus.COMPLETED),
        [JobStatus.IN_PROGRESS]: data.filter((job) => job.status === JobStatus.IN_PROGRESS),
        [JobStatus.QUEUED]: data.filter((job) => job.status === JobStatus.QUEUED),
        [JobStatus.CANCELED]: data.filter((job) => job.status === JobStatus.CANCELED),
        [JobStatus.FAILED]: data.filter((job) => job.status === JobStatus.FAILED),
      });
      setLoading(false);
    });
  }, []);

  return { jobs: jobsData, loading };
};
