import { Patient } from '../InteractiveChart.utils';

export const CHART_WIDTH = 300;
export const CHART_HEIGHT = 300;
export const CHART_MARGIN = { t: 30, l: 50, r: 30, b: 50 };
export const AXIS_FONT_SIZE = 10;
export const AXIS_GRID_COLOR = 'rgba(0, 0, 0, 0.1)';
export const AXIS_ZERO_LINE_COLOR = 'black';
export const AXIS_LINE_COLOR = 'black';
export const X_AXIS_TITLE = 'Time (hours)';
export const Y_AXIS_TITLE = 'Concentration (ng/mL)';
export const MARKER_SIZE = 6;
export const LINE_COLOR = 'black';
export const LINE_WIDTH = 1;
export const HOVER_INFO = 'none';
export const LEGEND_SHOW = false;

export const getLayout = (patient: Patient) => ({
    title: patient.label,
    width: CHART_WIDTH,
    height: CHART_HEIGHT,
    font: {
        size: AXIS_FONT_SIZE,
        color: AXIS_LINE_COLOR,
    },
    xaxis: {
        title: X_AXIS_TITLE,
        color: AXIS_LINE_COLOR,
        gridcolor: AXIS_GRID_COLOR,
        zerolinecolor: AXIS_ZERO_LINE_COLOR,
        linecolor: AXIS_LINE_COLOR,
    },
    yaxis: {
        title: Y_AXIS_TITLE,
        type: 'log',
        color: AXIS_LINE_COLOR,
        gridcolor: AXIS_GRID_COLOR,
        zerolinecolor: AXIS_ZERO_LINE_COLOR,
    },
    showlegend: LEGEND_SHOW,
    margin: CHART_MARGIN,
});

export const getData = (patient: Patient, excludedPoints: Record<number, { index: number }[]>, chartIndex: number) => [{
    x: patient.data.map((point) => point.x),
    y: patient.data.map((point) => point.y),
    type: 'scatter' as const,
    mode: 'lines+markers' as const,
    marker: {
        color: patient.data.map((_, index) => {
            const isExcluded = (excludedPoints[chartIndex] || []).some((p) => p.index === index);
            return isExcluded ? 'red' : 'blue';
        }),
        size: MARKER_SIZE,
    },
    line: {
        color: LINE_COLOR,
        width: LINE_WIDTH,
    },
    hoverinfo: HOVER_INFO,
    name: patient.label,
}];
