import { ChatDto, ChatOutput, ChatsApi, CopyChatDto, CreateChatDto, CreateChatOutputDto, EditCodeAssetDto, MessageDto, ThumbActionDto, UpdateChatDto } from '../../swagger_api';
import { swaggerConfiguration } from '../swagger';

class ChatsService {
  chatsApi: ChatsApi;

  constructor() {
    this.chatsApi = new ChatsApi(swaggerConfiguration);
  }

  async findAll() {
    try {
      const { data: chats } = await this.chatsApi.findAll();

      return chats;
    } catch (error) {
      console.error('Error in findAll chats:', error);
      throw error;
    }
  }

  async saveSharedChat(id: string) {
    try {
      const response = await this.chatsApi.saveSharedChat(id);
      return response;
    } catch (error) {
      console.error(`Error in getting a chat with id ${id}:`, error);
      throw error;
    }
  }

  async createChat(chatDto: CreateChatDto): Promise<ChatDto> {
    try {
      const { data: chat } = await this.chatsApi.create(chatDto);

      return chat;
    } catch (error) {
      console.error('Error in createChat:', error);
      throw new Error('Error in creating chat');
    }
  }

  async updateChat(chatId: string, chatDto: UpdateChatDto): Promise<ChatDto> {
    try {
      const { data: chat } = await this.chatsApi.update(chatDto, chatId);

      return chat;
    } catch (error) {
      console.error('Error in createChat:', error);
      throw new Error('Error in updating chat');
    }
  }

  async deleteChat(chatId: string): Promise<void> {
    try {
      const { data: chat } = await this.chatsApi.deleteChat(chatId);

      return chat;
    } catch (error) {
      console.error('Error in createChat:', error);
      throw new Error('Error in creating chat');
    }
  }

  async sendMessage(chatId: string, message: string): Promise<MessageDto> {
    try {
      const { data: messageRes } = await this.chatsApi.sendMessage({
        chat_id: chatId,
        text: message,
        message_author: 'user',
      });

      return messageRes;
    } catch (error) {
      console.error('Error in sendMessage:', error);
      throw new Error('Error in sendMessage');
    }
  }

  async getChat(
    chatId: string,
  ): Promise<{ chat: ChatDto; messages: MessageDto[]; logMessages: MessageDto[] }> {
    try {
      const { data } = await this.chatsApi.getChat(chatId);
      
      const chatWithMessages: { chat: ChatDto; messages: MessageDto[]; logMessages: MessageDto[] } =
        data as any;

      return {
        chat: chatWithMessages.chat,
        logMessages: chatWithMessages.logMessages,
        messages: chatWithMessages.messages,
      };
    } catch (error) {
      console.error('Error in getChat:', error);
      throw error;
    }
  }

  async cancelMessage(chatId: string, messageId: string): Promise<void> {
    try {
      await this.chatsApi.cancelMessage({ canceled_message_id: messageId }, chatId);
    } catch (error) {
      console.error('Error in cancelMessageId:', error);
      throw error;
    }
  }

  async saveChatOutput(chatOutputDto: CreateChatOutputDto): Promise<ChatOutput> {
    try {
      const { data: chatOutput } = await this.chatsApi.saveChatOutput(chatOutputDto);

      return chatOutput;
    } catch (error) {
      console.error('Error in saveChatOutput:', error);
      throw error;
    }
  }

  async deleteChatOutput(chatOutputId: string): Promise<ChatOutput> {
    try {
      const { data: chatOutput } = await this.chatsApi.deleteChatOutput(chatOutputId);

      return chatOutput;
    } catch (error) {
      console.error('Error in deleteChatOutput:', error);
      throw new Error('Error in deleteAllChatOutputs chat');
    }
  }

  async deleteAllChatOutputs(chatId: string): Promise<ChatOutput> {
    try {
      const { data: chatOutput } = await this.chatsApi.deleteAllChatOutputs(chatId);

      return chatOutput;
    } catch (error) {
      console.error('Error in deleteAllChatOutputs:', error);
      throw new Error('Error in deleteAllChatOutputs chat');
    }
  }

  async editCodeAsset(chatOutputDto: EditCodeAssetDto): Promise<string> {
    try {
      const { data: editedCode } = await this.chatsApi.editCodeAsset(chatOutputDto);

      return editedCode as unknown as string;
    } catch (error) {
      console.error('Error in editCodeAsset:', error);
      throw error;
    }
  }

  async thumbAction(thumbActionDto: ThumbActionDto): Promise<void> {
    try {
      const { data: thumbAction } = await this.chatsApi.thumbAction(thumbActionDto);

      return thumbAction;
    } catch (error) {
      console.error('Error in thumbAction:', error);
      throw error;
    }
  }

  async copyChat(copyChatDto: CopyChatDto): Promise<ChatDto> {
    try {
      const { data: chat } = await this.chatsApi.copyChat(copyChatDto);

      return chat;
    } catch (error) {
      console.error('Error in copyChat:', error);
      throw error;
    }
  }
}

export const chatsService = new ChatsService();
