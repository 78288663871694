/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { AssetDto } from './asset-dto';
import { DerivedDatasetRuleDto } from './derived-dataset-rule-dto';
import { QueueEventDto } from './queue-event-dto';
 /**
 * 
 *
 * @export
 * @interface AnalysisDto
 */
export interface AnalysisDto {

    /**
     * Unique identifier of the analysis
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example 123e4567-e89b-12d3-a456-426614174000
     */
    id: string;

    /**
     * Name of the analysis
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example Analysis 1
     */
    name: string;

    /**
     * Chat ID the analysis belongs to
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example 123e4567-e89b-12d3-a456-426614174000
     */
    chat_id: string;

    /**
     * Parent analysis ID
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example 123e4567-e89b-12d3-a456-426614174000
     */
    parent_analysis_id: string;

    /**
     * Organization ID
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example 123e4567-e89b-12d3-a456-426614174000
     */
    organization_id: string;

    /**
     * Type of analysis
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example POP-PK
     */
    analysis_type: AnalysisDtoAnalysisTypeEnum;

    /**
     * Summary of the analysis
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example Summary of analysis
     */
    analysis_summary: string;

    /**
     * Status of the analysis
     *
     * @type {string}
     * @memberof AnalysisDto
     * @example IN_PROGRESS
     */
    status: AnalysisDtoStatusEnum;

    /**
     * Assets belonging to the analysis
     *
     * @type {Array<AssetDto>}
     * @memberof AnalysisDto
     */
    assets: Array<AssetDto>;

    /**
     * Queue events belonging to the analysis
     *
     * @type {Array<QueueEventDto>}
     * @memberof AnalysisDto
     */
    queue_events: Array<QueueEventDto>;

    /**
     * Derived datasets belonging to the analysis
     *
     * @type {Array<DerivedDatasetRuleDto>}
     * @memberof AnalysisDto
     */
    derived_datasets: Array<DerivedDatasetRuleDto>;

    /**
     * @type {Date}
     * @memberof AnalysisDto
     */
    created_at: Date;

    /**
     * @type {Date}
     * @memberof AnalysisDto
     */
    updated_at: Date;
}

/**
 * @export
 * @enum {string}
 */
export enum AnalysisDtoAnalysisTypeEnum {
    POPPK = 'POP_PK',
    NCA = 'NCA'
}
/**
 * @export
 * @enum {string}
 */
export enum AnalysisDtoStatusEnum {
    INPROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    FAILED = 'FAILED',
    CANCELED = 'CANCELED',
    QUEUED = 'QUEUED'
}

