export const isCodeMessage = (messageMetadata) => messageMetadata.type === 'R'
export const isPlotMessage = (messageMetadata) => messageMetadata.type === 'html'
export const isCleanDataset = (datasetName: string) => datasetName === 'clean_dataset.csv'

export const groupByFilePath = (data) => {
    return data?.reduce((acc, item) => {
        if (!acc[item?.code_file_path]) {
            acc[item?.code_file_path] = [];
        }
        acc[item?.code_file_path].push(item);
        return acc;
    }, {});
};

export const isCodeOutput = (metadata, output) => {
    return isCodeMessage(metadata) && !output.asset_file_path && !!output.code_file_path;
};

export const isAssetOutput = (metadata, output) => {
    return !isCodeMessage(metadata) && output.asset_file_path;
};

export const findMatchingOutput = (chats, chatId, metadata, assetPathKey) => {
    return chats
        .find(chat => chat.id === chatId)
        .chat_outputs
        .find(output =>
            getFileNameWithoutQueryParams(metadata.url) ===
            getFileNameWithoutQueryParams(output[assetPathKey])
        );
};

export const getFilePath = (metadata) => {
    return !isCodeMessage(metadata) ? metadata.url : null;
};

export const getCodeFilePath = (metadata, codeFile) => {
    return !isCodeMessage(metadata) ? codeFile.filePath : metadata.url;
};

export const extractFileName = (filePath) => decodeURIComponent(filePath?.split('/').pop());

export const getFileNameWithoutQueryParams = (filePath: string): string => {
    return extractFileName(filePath).split('?')[0];
  };


  export const groupByFileName = (data) => {
    return data?.reduce((acc, item) => {
        const fileNameWithoutQueryParams = getFileNameWithoutQueryParams(item?.code_file_path)

        if (!acc[fileNameWithoutQueryParams]) {
            acc[fileNameWithoutQueryParams] = [];
        }
        acc[fileNameWithoutQueryParams].push(item);
        return acc;
    }, {});
};
