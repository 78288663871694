import { useCallback, useMemo } from 'react';

import { Dropdown } from 'antd';
import { chatsService } from 'src/api';
import { DatasetType, useChatStore } from 'src/pages/Chat/Chat.hook';

import { ReactComponent as Datasets } from '../../../../../../icons/Datasets.svg';
import useManageChatModalStore from '../../../Layout/Sidebar/ManageChatModal/ManageChatModal.hook';
import styles from './DatasetManager.module.scss';
import './DatasetManager.style.scss';
import { toast } from 'react-toastify';

const DatasetManager = () => {
  const { selectedChat, chats, cleanDatasetIncluded, setSelectedChat } = useChatStore();
  const { activeDataset, setActiveDataset } = useManageChatModalStore();

  const setChatActiveDataset = useCallback(
    (currentDataset: string) => {
      setActiveDataset(currentDataset);
      setSelectedChat({ ...selectedChat, active_dataset: currentDataset });
      chatsService.updateChat(selectedChat.id, { active_dataset: currentDataset });
      toast.success(`Active dataset successfully changed to ${currentDataset}`)
    },
    [selectedChat, setActiveDataset, setSelectedChat],
  );

  // @ts-ignore
  const initialDataset = chats.find(chat => chat.id === selectedChat.id)?.dataset?.name;

  const datasetOptions = useMemo(() => {
    const datasets = [];
    if (initialDataset) {
      datasets.push({
        label: (
          <button
            className={styles['manager-dataset']}
            onClick={() => setChatActiveDataset(initialDataset)}
          >
            {initialDataset}
            <div className={styles['manager-dataset-original']}>Original Dataset</div>
          </button>
        ),
        key: String(initialDataset),
      });
    }

    if (cleanDatasetIncluded) {
      datasets.push({
        label: (
          <button
            className={styles['manager-dataset']}
            onClick={() => setChatActiveDataset(DatasetType.Clean)}
          >
            {DatasetType.Clean}
          </button>
        ),
        key: String(DatasetType.Clean),
      });
    }

    return datasets;
  }, [cleanDatasetIncluded, initialDataset, setChatActiveDataset]);

  return (
    <div className={styles['manager']}>
      <span className={styles['manager-text']}><Datasets />Active dataset:</span>
      <Dropdown
        menu={{ items: datasetOptions }}
        trigger={['click']}
        placement="topLeft"
        arrow
        overlayStyle={{ maxHeight: 200, overflow: 'auto', marginTop: -10 }}
      >
        <span className={styles['manager-current']}>{activeDataset || initialDataset}</span>
      </Dropdown>
    </div>
  );
};

export default DatasetManager;
