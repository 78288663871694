/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AnalysisSettings } from '../models';
import { AnalysisSettingsDto } from '../models';
import { CreateAnalysisSettingsDto } from '../models';
import { UpdateAnalysisSettingsDto } from '../models';
/**
 * AnalysisSettingsApi - axios parameter creator
 * @export
 */
export const AnalysisSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new analysis setting
         * @param {CreateAnalysisSettingsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: CreateAnalysisSettingsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/analysis-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all analysis settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analysis-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an analysis
         * @param {UpdateAnalysisSettingsDto} body 
         * @param {string} id Analysis ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (body: UpdateAnalysisSettingsDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            const localVarPath = `/api/analysis-settings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysisSettingsApi - functional programming interface
 * @export
 */
export const AnalysisSettingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new analysis setting
         * @param {CreateAnalysisSettingsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateAnalysisSettingsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalysisSettingsDto>>> {
            const localVarAxiosArgs = await AnalysisSettingsApiAxiosParamCreator(configuration).create(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all analysis settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<AnalysisSettingsDto>>>> {
            const localVarAxiosArgs = await AnalysisSettingsApiAxiosParamCreator(configuration).findAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update an analysis
         * @param {UpdateAnalysisSettingsDto} body 
         * @param {string} id Analysis ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(body: UpdateAnalysisSettingsDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AnalysisSettings>>> {
            const localVarAxiosArgs = await AnalysisSettingsApiAxiosParamCreator(configuration).update(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnalysisSettingsApi - factory interface
 * @export
 */
export const AnalysisSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new analysis setting
         * @param {CreateAnalysisSettingsDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateAnalysisSettingsDto, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalysisSettingsDto>> {
            return AnalysisSettingsApiFp(configuration).create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all analysis settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<AnalysisSettingsDto>>> {
            return AnalysisSettingsApiFp(configuration).findAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an analysis
         * @param {UpdateAnalysisSettingsDto} body 
         * @param {string} id Analysis ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(body: UpdateAnalysisSettingsDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AnalysisSettings>> {
            return AnalysisSettingsApiFp(configuration).update(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysisSettingsApi - object-oriented interface
 * @export
 * @class AnalysisSettingsApi
 * @extends {BaseAPI}
 */
export class AnalysisSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new analysis setting
     * @param {CreateAnalysisSettingsDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisSettingsApi
     */
    public async create(body: CreateAnalysisSettingsDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalysisSettingsDto>> {
        return AnalysisSettingsApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all analysis settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisSettingsApi
     */
    public async findAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<AnalysisSettingsDto>>> {
        return AnalysisSettingsApiFp(this.configuration).findAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update an analysis
     * @param {UpdateAnalysisSettingsDto} body 
     * @param {string} id Analysis ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysisSettingsApi
     */
    public async update(body: UpdateAnalysisSettingsDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AnalysisSettings>> {
        return AnalysisSettingsApiFp(this.configuration).update(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
