
import Input from "../../../../../../components/n-Input/n-Input.component";
import Select from "../../../../../../components/Select/Select.component";
import styles from "../AnalysisModalForm.module.scss";

export interface ValidationError {
    valid: boolean;
    message: string;
}

interface AnalysisGenericFormProps<T> {
    state: T;
    handleChange: (name: string, value: any) => void;
    config: any;
    errors: Record<string, ValidationError>
}

const getErrorMessage = (validationResults: Record<string, any>, fieldName: string): string | null => {
    const validationResult = validationResults[fieldName];
    return validationResult && !validationResult.valid ? validationResult.message : null;
};

const createHandleChangeHelper = (handleChange: (name: string, value: any) => void) => (fieldName: string) => (value: any) => {
    handleChange(fieldName, value);
};

const AnalysisGenericForm = <T,>({ config, state, handleChange, errors }: AnalysisGenericFormProps<T>) => {
    const handler = createHandleChangeHelper(handleChange);

    return (
        <div >
            {config.map(section => (
                <div key={section.heading} style={{ display: "flex", flexDirection: "column", gap: 30 }}>
                    <h2 className={styles['analysis-modal-form__heading']}>{section.heading}</h2>
                    {section.fields.map(field => {
                        switch (field.type) {
                            case 'select':
                                return (
                                    <Select
                                        key={field.name}
                                        label={field.label}
                                        selectedOption={state[field.name]}
                                        onChange={handler(field.name)}
                                        options={field.options}
                                    />
                                );
                            case 'input':
                                return (
                                    <Input
                                        key={field.name}
                                        type={field.inputType}
                                        label={field.label}
                                        value={state[field.name]}
                                        onChange={(value) => handler(field.name)(value)}
                                        error={getErrorMessage(errors, field.name)}
                                    />
                                );
                            case 'range':
                                return (
                                    <div
                                        key={field.fields.map(f => f.name).join('-')}
                                        className={styles['analysis-modal-form__range']}>
                                        {field.fields.map(subField => (
                                            <Input
                                                key={subField.name}
                                                value={state[subField.name]}
                                                onChange={(value) => handler(subField.name)(value)}
                                                label={subField.label}
                                                description={subField.description}
                                                error={getErrorMessage(errors, subField.name)}
                                            />
                                        ))}
                                    </div>
                                );
                            case 'group-column':
                                return (
                                    <div
                                        key={`${field.mainField.name}-${field.secondaryField.name}`}
                                        className={styles['analysis-modal-form__group-column']}>
                                        <Input
                                            style={{ alignSelf: 'center', marginLeft: 121 }}
                                            description={field.mainField.description}
                                            value={state[field.mainField.name]}
                                            onChange={handler(field.mainField.name)}
                                            error={getErrorMessage(errors, field.mainField.name)}
                                        />
                                        <Input
                                            style={{ alignSelf: 'center', marginLeft: 121 }}
                                            description={field.secondaryField.description}
                                            value={state[field.secondaryField.name]}
                                            onChange={handler(field.secondaryField.name)}
                                            error={getErrorMessage(errors, field.secondaryField.name)}
                                        />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })}
                    <div style={{ margin: "10px 0" }} />
                </div>
            ))}
        </div>
    );
};

export default AnalysisGenericForm;
