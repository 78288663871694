import { useEffect, useState } from 'react';

import AssetsLoading from './AssetsLoading';

// hide plots that too big for render
const TEN_MB = 10000000;

const Charts = ({ asset }: { asset: string }) => {
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchHtml = async () => {
      try {
        setLoading(true);
        const response = await fetch(asset);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const contentLength = response.headers.get('Content-Length');
        const text = await response.text();
        if (+contentLength > TEN_MB) {
          console.error('Error: Content length is too large');
          setHtmlContent(`<div>Content is too large</div>`);
        } else {
          setHtmlContent(text);
        }

        setTimeout(() => {
          setLoading(false);
        }, 700);
      } catch (error) {
        console.error('Error fetching HTML:', error);
      }
    };

    fetchHtml();
  }, [asset]);

  if (loading) {
    return <AssetsLoading />;
  }

  if (!htmlContent) {
    return <div />;
  }

  return (
    <iframe
      srcDoc={htmlContent}
      title="HTML Content"
      style={{ width: '100%', height: '500px', border: 'none', padding: 24 }}
    />
  );
};

export default Charts;
