import React, { useEffect, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import { Socket } from 'socket.io-client';
import { Text } from 'src/components/Text/Text';
import { CreateChatOutputDto, MessageDto } from '@/swagger_api';
import { chatsService } from 'src/api';
import { useModal } from 'src/components/Modal/Modal.hook';
import { ModalName } from 'src/components/Modal/modalEnums';
import useDownloadFile from 'src/hooks/useDownloadFile';
import { ReactComponent as ArrowIcon } from 'src/icons/Arrow.svg';
import { ReactComponent as EditPlotsIcon } from 'src/icons/EditPlots.svg';
import { ReactComponent as SaveIcon } from 'src/icons/Pin.svg';
import { ReactComponent as RemoveIcon } from 'src/icons/Unpin.svg';
import { ReactComponent as ApplyDatasetIcon } from 'src/icons/apply-dataset.svg';
import { ReactComponent as NDownloadIcon } from 'src/icons/n-Download.svg';
import { ReactComponent as SaveDatasetIcon } from 'src/icons/save_dataset.svg';
import { fetchFileContent } from '../../../../../Jobs/components/JobPage/components/CodeEditor/CodeEditor.service';
import { DatasetType, useChatStore } from '../../../../Chat.hook';
import useEditableCodeStore from '../../../EditCodeModal/EditCodeModal.hook';
import useSendCode from '../../../EditCodeModal/hooks/useSendCode';
import { useShareChat } from 'src/pages/Chat/hooks/useShareChat.hook';
import useManageChatModalStore from '../../../Layout/Sidebar/ManageChatModal/ManageChatModal.hook';
import SaveDatasetModal from '../SaveDatasetModal/SaveDatasetModal';
import {
  findMatchingOutput,
  getCodeFilePath,
  getFileNameWithoutQueryParams,
  getFilePath,
  groupByFileName,
  isAssetOutput,
  isCleanDataset,
  isCodeMessage,
  isCodeOutput,
  isPlotMessage,
} from './MessageAssetContainer.utils';
import styles from './assets.module.scss';
import { toast } from 'react-toastify';
import classNames from 'classnames';

import RunAction from './components/RunAction.component';
import EditAction from './components/EditAction.component';

type MessageAssetContainerProps = {
  title?: string;
  className?: string;
  icon?: JSX.Element;
  children?: JSX.Element;
  isOpenByDefault?: boolean;
  message: MessageDto;
  codeFile: { name: string; filePath: string };
  messageProcessing?: boolean;
  socket: Socket;
  scrollToMessage: (messageId: string) => void;
};

const MessageAssetContainer: React.FC<MessageAssetContainerProps> = ({
  title = '',
  icon,
  message: { chat_id, id: message_id, metadata },
  codeFile,
  className,
  children,
  isOpenByDefault = true,
  socket,
  messageProcessing,
  scrollToMessage,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault);
  const [loadingStates, setLoadingStates] = useState({});

  const { isShared } = useShareChat();
  const {
    setChats,
    setReplyMode,
    selectedChat,
    chats,
    studies,
    cleanDatasetIncluded,
    setCleanDatasetIncluded,
  } = useChatStore();

  const { setActiveDataset } = useManageChatModalStore();
  const { showModal } = useModal();
  const { setOriginalKey, setFileName } = useEditableCodeStore();
  const { downloadFile } = useDownloadFile();
  const { isLoading, setIsLoading, handleSave } = useSendCode(socket);

  const updateChatOutputs = (chats, chatId, transformFn) => {
    return chats.map((chat) => {
      if (chat.id === chatId) {
        return {
          ...chat,
          chat_outputs: transformFn(chat.chat_outputs),
        };
      }
      return chat;
    });
  };

  const deleteChatOutputs = (chats, chatId, messageIdsToDelete) => {
    return chats.map((chat) => {
      if (chat.id === chatId) {
        return {
          ...chat,
          chat_outputs: chat.chat_outputs.filter(
            (output) => !messageIdsToDelete.includes(output.id),
          ),
        };
      }
      return chat;
    });
  };

  const handleOptimisticUpdate = (chats, isDeleting = false) => {
    const chatId = chat_id;
    const messageId = message_id;
    const assetId = `asset-file-${messageId}`;
    const codeId = `code-file-${messageId}`;
    const assetPathKey = !isCodeMessage(metadata) ? 'asset_file_path' : 'code_file_path';

    const output = findMatchingOutput(chats, chatId, metadata, assetPathKey);

    if (isDeleting) {
      return deleteChatOutputs(chats, chatId, [output.id]);
    }

    return codeFile
      ? updateChatOutputs(chats, chatId, (chat_outputs) => [
          ...chat_outputs,
          {
            id: codeId,
            message_id: messageId,
            asset_file_path: null,
            code_file_path: codeFile.filePath,
            chat_id: chatId,
          },
          {
            id: assetId,
            message_id: messageId,
            asset_file_path: getFilePath(metadata),
            code_file_path: getCodeFilePath(metadata, codeFile),
            chat_id: chatId,
          },
        ])
      : updateChatOutputs(chats, chatId, (chat_outputs) => [
          ...chat_outputs,
          {
            id: codeId,
            message_id: messageId,
            asset_file_path: null,
            code_file_path: metadata.url,
            chat_id: chatId,
          },
        ]);
  };

  const handleDeleteAsset = (loadingId) => async () => {
    setLoadingStates((states) => ({ ...states, [loadingId]: true }));

    const chatId = chat_id;
    const messageId = message_id;

    const optimisticChats = handleOptimisticUpdate(chats, true);
    setChats(optimisticChats);

    try {
      await deleteOutputs(chatId);

      const updatedChats = await fetchChats();
      const finalChats = cleanOutputs(updatedChats, chatId, messageId);

      setChats(finalChats);
      setLoadingStates((states) => ({ ...states, [loadingId]: false }));
    } catch (error) {
      console.error('Failed to delete asset:', error);
      setChats(rollbackUpdate(chats));
      setLoadingStates((states) => ({ ...states, [loadingId]: false }));
    }
  };

  const deleteOutputs = async (chatId) => {
    const isCode = isCodeMessage(metadata);
    const assetPathKey = !isCode ? 'asset_file_path' : 'code_file_path';

    if (isCode) {
      const { folders } = studies.find((study) => study.id === chatId);
      const { files } = folders.filter(
        (folder) =>
          getFileNameWithoutQueryParams(folder.codeFileName) ===
          getFileNameWithoutQueryParams(metadata.url),
      )[0];

      for await (let file of files) {
        await removeChatOutput(file.id);
      }
    } else {
      const output = findMatchingOutput(chats, chatId, metadata, assetPathKey);
      await removeChatOutput(output.id);
    }
  };

  const cleanOutputs = (updatedChats, chatId, messageId) => {
    return deleteChatOutputs(updatedChats, chatId, [
      `asset-file-${messageId}`,
      `code-file-${messageId}`,
    ]);
  };

  const rollbackUpdate = (chats) => {
    const revertToOriginal = (chat) => ({
      ...chat,
      chat_outputs: chat.chat_outputs.filter(
        (output) =>
          output.id !== `asset-file-${message_id}` && output.id !== `code-file-${message_id}`,
      ),
    });

    return chats.map((chat) => (chat.id === chat_id ? revertToOriginal(chat) : chat));
  };

  const saveChatOutput = async (data: CreateChatOutputDto) => {
    setLoadingStates((states) => ({
      ...states,
      [data.asset_file_path || data.code_file_path]: true,
    }));

    await chatsService.saveChatOutput(data);

    setLoadingStates((states) => ({
      ...states,
      [data.asset_file_path || data.code_file_path]: false,
    }));
  };

  const fetchChats = async () => {
    return chatsService.findAll();
  };

  const removeChatOutput = (outputId) => chatsService.deleteChatOutput(outputId);

  const getAllChatOutputs = (chats) => {
    return chats.flatMap((chat) => chat.chat_outputs);
  };

  const chatOutputs = getAllChatOutputs(chats);

  const isSaved = chatOutputs.some((output) => {
    const fileNameWithoutQuery = getFileNameWithoutQueryParams(metadata.url);

    if (isCodeOutput(metadata, output)) {
      return groupByFileName(chatOutputs)[fileNameWithoutQuery]?.some(
        (item) => selectedChat.id === item.chat_id && getFileNameWithoutQueryParams(item.code_file_path) === fileNameWithoutQuery,
      );
    }

    if (isAssetOutput(metadata, output)) {
      return selectedChat.id === output.chat_id && getFileNameWithoutQueryParams(output?.asset_file_path) === metadata.name;
    }

    return false;
  });

  const handleSaveAsset = (loadingId) => async () => {
    setLoadingStates((states) => ({ ...states, [loadingId]: true }));

    const chatId = chat_id;
    const messageId = message_id;

    const optimisticChats = handleOptimisticUpdate(chats);
    setChats(optimisticChats);

    try {
      await saveOutputs(chatId, messageId);

      const updatedChats = await fetchChats();
      const finalChats = cleanOutputs(updatedChats, chatId, messageId);

      setChats(finalChats);
      setLoadingStates((states) => ({ ...states, [loadingId]: false }));
    } catch (error) {
      console.error('Failed to save asset:', error);
      setChats(rollbackUpdate(chats));
      setLoadingStates((states) => ({ ...states, [loadingId]: false }));
    }
  };

  const saveOutputs = async (chatId, messageId) => {
    if (!codeFile) {
      return saveChatOutput({
        message_id: messageId,
        asset_file_path: null,
        code_file_path: metadata.url,
        chat_id: chatId,
      });
    } else {
      const updatedChats = await fetchChats();
      const outputs = getAllChatOutputs(updatedChats);

      const isCodeSaved = outputs.some((output) => output.code_file_path === codeFile.filePath);

      if (!isCodeSaved) {
        await saveChatOutput({
          message_id: messageId,
          asset_file_path: null,
          code_file_path: codeFile.filePath,
          chat_id: chatId,
        });
      }

      await saveChatOutput({
        message_id: messageId,
        asset_file_path: getFilePath(metadata),
        code_file_path: getCodeFilePath(metadata, codeFile),
        chat_id: chatId,
      });
    }
  };

  const toggleDropdown = (event) => {
    if (event.target.dataset.dropdownIgnoreClick !== 'true') {
      setIsOpen(!isOpen);
    }
  };

  const onEditCode = (event) => {
    event.stopPropagation();

    setOriginalKey(metadata.url);
    showModal(ModalName.EDIT_CODE_MODAL);
    setFileName(metadata.name);
  };

  const setChatActiveDataset = async () => {
    setActiveDataset(DatasetType.Clean);
    chatsService.updateChat(chat_id, { active_dataset: DatasetType.Clean });
    toast.success('Active dataset successfully changed to clean')
  };

  const saveDataset = async () => {
    showModal(ModalName.SAVE_DATASET_MODAL);
  };

  const onRunCode = async (event) => {
    event.stopPropagation();

    try {
      setIsLoading(true);

      const currentCode = await fetchFileContent(metadata.url);

      handleSave({
        execute: true,
        originalKey: metadata.url,
        currentCode,
        onSuccess: () => {},
      });
    } catch (error) {
      console.error('Error fetching file content:', error);
    }
  };

  useEffect(() => {
    if (cleanDatasetIncluded) {
      return;
    }
    if (isCleanDataset(metadata.name)) {
      setCleanDatasetIncluded(true);
    }
  }, [cleanDatasetIncluded, metadata.name, setCleanDatasetIncluded]);

  const ActionIcon = isSaved ? RemoveIcon : SaveIcon;

  return (
    <div
      className={cn(styles['interactive-chart__container'], className, {
        [styles['interactive-chart__container-closed']]: !isOpen,
      })}
    >
      <div className={styles['interactive-chart__header']} onClick={toggleDropdown}>
        <h2 className={styles['interactive-chart__header-title']}>
          {icon}
          {title}
        </h2>

        <div className={styles['interactive-chart__actions']}>
          <Popover content={<Text.Small>{'Save Dataset'}</Text.Small>} placement="top">
            {isCleanDataset(metadata.name) && (
              <div data-dropdown-ignore-click>
                <ApplyDatasetIcon onClick={() => saveDataset()} data-dropdown-ignore-click />{' '}
              </div>
            )}
          </Popover>

          <Popover content={<Text.Small>{'Set Active Dataset'}</Text.Small>} placement="top">
            {isCleanDataset(metadata.name) && (
              <div data-dropdown-ignore-click>
                <SaveDatasetIcon onClick={setChatActiveDataset} data-dropdown-ignore-click />{' '}
              </div>
            )}
          </Popover>

          {!isShared && (
            <Popover content={<Text.Small>{'Modify'}</Text.Small>} placement="top">
              {isPlotMessage(metadata) && (
                <div data-dropdown-ignore-click>
                  <EditPlotsIcon
                    data-dropdown-ignore-click
                    onClick={(event) => {
                      event.stopPropagation();

                      scrollToMessage(message_id);

                      setReplyMode({
                        messageId: message_id,
                        codeFileName: codeFile?.name,
                        assetFileName: getFileNameWithoutQueryParams(metadata.url),
                      });
                    }}
                  />
                </div>
              )}
            </Popover>
          )}

          <RunAction
            isCodeMessage={isCodeMessage(metadata)}
            isShared={isShared}
            messageProcessing={messageProcessing}
            isLoading={isLoading}
            onRunCode={onRunCode}
          />

          <EditAction
            isCodeMessage={isCodeMessage(metadata)}
            isShared={isShared}
            messageProcessing={messageProcessing}
            isLoading={isLoading}
            onEditCode={onEditCode}
          />

          <Popover content={<Text.Small>{'Download'}</Text.Small>} placement="top">
            <div data-dropdown-ignore-click>
              <NDownloadIcon
                data-dropdown-ignore-click
                onClick={(event) => {
                  event.stopPropagation();

                  downloadFile({
                    signedUrl: metadata.url,
                    fileName: getFileNameWithoutQueryParams(metadata.url),
                  });
                }}
              />
            </div>
          </Popover>

        {!isShared && <Popover content={<Text.Small>{isSaved ? 'Unpin' : 'Pin'}</Text.Small>} placement="top">
            <div data-dropdown-ignore-click>
              <ActionIcon
                className={classNames(styles['save-asset-button'], {
                  [styles['save-asset-button__disabled']]: loadingStates[title],
                })}
                onClick={!isSaved ? handleSaveAsset(title) : handleDeleteAsset(title)}
                data-dropdown-ignore-click
              />
            </div>
          </Popover>}

          <div
            data-dropdown-ignore-click
            id="toggleDropdown"
            className={styles['interactive-chart__header-toggle']}
          >
            <ArrowIcon
              className={cn(styles['interactive-chart__header-toggle-arrow'], {
                [styles['interactive-chart__header-toggle-arrow-opened']]: isOpen,
              })}
            />
          </div>
        </div>
      </div>
      {isOpen && children}
      <SaveDatasetModal datasetFilePath={metadata.url} />
    </div>
  );
};

export default MessageAssetContainer;
