import React from "react";
import styles from "../n-Input.module.scss";

export const DownArrow: React.FC<{ onClick: () => void; }> = ({ onClick }) => (
    <svg
        className={styles["input__down-arrow"]}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 6"
        onClick={onClick}
    >
        <path
            d="M5.00048 3.78132L8.30048 0.481323L9.24315 1.42399L5.00048 5.66666L0.757812 1.42399L1.70048 0.481323L5.00048 3.78132Z"
            fill="#002B73" />
    </svg>
);
