
import { useCallback, useEffect } from "react";

import ManageChatModalBody from "./ManageChatModalBody.component";
import ManageChatModalHeader from "./ManageChatModalHeader.component";
import ManageChatModalFooter from "./ManageChatModalFooter.component";

import { useModal } from "../../../../../../components/Modal/Modal.hook";
import ModalContent from "../../../../../../components/Modal/ModalContent.component";
import { ModalName } from "../../../../../../components/Modal/modalEnums";

import styles from "./ManageChatModal.module.scss"

const ManageChatModal = () => {
    const { initializeModal } = useModal();

    const initializeManageChatModal = useCallback(() => {
        initializeModal(
            ModalName.MANAGE_CHAT_MODAL,
            <ModalContent
                className={styles['manage-chat-modal']}
                header={<ManageChatModalHeader />}
                body={<ManageChatModalBody />}
                footer={<ManageChatModalFooter />}
            />,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        initializeManageChatModal();
    }, [initializeManageChatModal]);

    return null;
}

export default ManageChatModal;