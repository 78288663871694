import React, { useEffect, useRef, useCallback } from 'react';
import Plotly from 'plotly.js-dist-min';

import styles from '../InteractiveChart.module.scss';
import { getLayout, getData } from './ChartsRenderer.constant';
import { Patient } from '../InteractiveChart.utils';

interface ChartsRendererProps {
    patients: Patient[];
    currentPage: number;
    pageSize: number;
    onPointClick: (event: any, elements: any, chartIndex: number) => void;
    excludedPoints: Record<number, { index: number }[]>;
}

const ChartsRenderer: React.FC<ChartsRendererProps> = ({
    patients,
    currentPage,
    pageSize,
    onPointClick,
    excludedPoints,
}) => {
    const chartsRef = useRef<HTMLDivElement[]>([]);

    const updateChartColors = useCallback(() => {
        const start = currentPage * pageSize;
        const end = Math.min(start + pageSize, patients.length);

        patients.slice(start, end).forEach((_, i) => {
            const chartIndex = currentPage * pageSize + i;
            const chartElement = chartsRef.current[i];

            if (chartElement) {
                const data = getData(patients[chartIndex], excludedPoints, chartIndex);
                Plotly.restyle(chartElement, { marker: { color: data[0].marker.color } });
            }
        });
    }, [currentPage, excludedPoints, pageSize, patients]);

    const initializeCharts = useCallback(() => {
        const start = currentPage * pageSize;
        const end = Math.min(start + pageSize, patients.length);

        chartsRef.current = [];

        patients.slice(start, end).forEach((patient, i) => {
            const chartIndex = currentPage * pageSize + i;
            const chartId = `chart-${patient.id}-${chartIndex}`;
            const chartElement = document.getElementById(chartId) as any;

            const layout = getLayout(patient);
            const data = getData(patient, excludedPoints, chartIndex);

            if (chartElement) {
                Plotly.newPlot(chartId, data as any, layout as any);

                chartElement.on('plotly_click', (event) => {
                    const points = event.points.map((point: any) => ({
                        x: point.x,
                        y: point.y,
                        index: point.pointIndex,
                    }));
                    onPointClick(event, points, chartIndex);
                });
            }

            chartsRef.current.push(chartElement);
        });
    }, [currentPage, pageSize, patients, onPointClick, excludedPoints]);

    useEffect(() => {
        initializeCharts();
    }, [initializeCharts]);

    useEffect(() => {
        updateChartColors();
    }, [currentPage, pageSize, patients, excludedPoints, updateChartColors]);

    return (
        <div className={styles['interactive-chart__charts-container']}>
            {patients.slice(currentPage * pageSize, Math.min(currentPage * pageSize + pageSize, patients.length)).map((patient, index) => (
                <div key={index} className={styles['interactive-chart__chart-wrapper']}>
                    <div id={`chart-${patient.id}-${currentPage * pageSize + index}`} style={{ width: '100%', height: '300px' }}></div>
                </div>
            ))}
        </div>
    );
};

export default React.memo(ChartsRenderer);
