import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useAuthRedirect from '../hooks/useAuthRedirect';
import { useSessionTimeout } from '../hooks/useSessionTimeout';

export const AuthRoute = ({ children }) => {
  useAuthRedirect();
  useSessionTimeout();

  return (
    <>
      <ToastContainer />
      {children}
    </>
  );
};
