/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * 
 *
 * @export
 * @interface ThumbActionDto
 */
export interface ThumbActionDto {

    /**
     * Type of thumb action
     *
     * @type {string}
     * @memberof ThumbActionDto
     */
    action: ThumbActionDtoActionEnum;

    /**
     * ID of the message to be thumbed
     *
     * @type {string}
     * @memberof ThumbActionDto
     */
    message_id: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ThumbActionDtoActionEnum {
    Up = 'thumbs_up',
    Down = 'thumbs_down'
}

