/* tslint:disable */
/* eslint-disable */
/**
 * Pharma App
 * API Documentation
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateJobDto } from '../models';
import { JobDto } from '../models';
/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new job
         * @param {CreateJobDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (body: CreateJobDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/api/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a job
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteJob.');
            }
            const localVarPath = `/api/jobs/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all jobs
         * @param {any} [status] Filter by job status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAll: async (status?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find job by ID
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findById.');
            }
            const localVarPath = `/api/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new job
         * @param {CreateJobDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateJobDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JobDto>>> {
            const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).create(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a job
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJob(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).deleteJob(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all jobs
         * @param {any} [status] Filter by job status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(status?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<JobDto>>>> {
            const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).findAll(status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Find job by ID
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JobDto>>> {
            const localVarAxiosArgs = await JobsApiAxiosParamCreator(configuration).findById(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a new job
         * @param {CreateJobDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(body: CreateJobDto, options?: AxiosRequestConfig): Promise<AxiosResponse<JobDto>> {
            return JobsApiFp(configuration).create(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a job
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJob(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return JobsApiFp(configuration).deleteJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all jobs
         * @param {any} [status] Filter by job status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAll(status?: any, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<JobDto>>> {
            return JobsApiFp(configuration).findAll(status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find job by ID
         * @param {string} id Job ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findById(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<JobDto>> {
            return JobsApiFp(configuration).findById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new job
     * @param {CreateJobDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public async create(body: CreateJobDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<JobDto>> {
        return JobsApiFp(this.configuration).create(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a job
     * @param {string} id Job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public async deleteJob(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return JobsApiFp(this.configuration).deleteJob(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all jobs
     * @param {any} [status] Filter by job status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public async findAll(status?: any, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<JobDto>>> {
        return JobsApiFp(this.configuration).findAll(status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Find job by ID
     * @param {string} id Job ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public async findById(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<JobDto>> {
        return JobsApiFp(this.configuration).findById(id, options).then((request) => request(this.axios, this.basePath));
    }
}
