import { useMemo, useState } from 'react';

import cn from 'classnames';

import { ReactComponent as ThumbDownActiveIcon } from '../../../../../../../icons/thumb-down-active.svg';
import { ReactComponent as ThumbDownIcon } from '../../../../../../../icons/thumb-down-icon.svg';
import { ReactComponent as ThumbUpActiveIcon } from '../../../../../../../icons/thumb-up-active.svg';
import { ReactComponent as ThumbUpIcon } from '../../../../../../../icons/thumb-up-icon.svg';
import { ThumbActionDtoActionEnum } from '../../../../../../../swagger_api';
import { CommonMessage, cleanString } from '../MessageResponse';
import styles from './AgentResponseMessage.module.scss';
import { useShareChat } from 'src/pages/Chat/hooks/useShareChat.hook';

export const AgentResponseMessage = ({
  metadata,
  onHeaderClick,
  restProps,
  text,
  onThumbClick,
}) => {

  const { isShared } = useShareChat()

  const [activeThumb, setActiveThumb] = useState(() => {
    if (metadata.thumbs_up) return ThumbActionDtoActionEnum.Up;
    if (metadata.thumbs_down) return ThumbActionDtoActionEnum.Down;
    return null;
  });

  const thumbIcons = useMemo(() => {
    return {
      up: activeThumb === ThumbActionDtoActionEnum.Up ? <ThumbUpActiveIcon /> : <ThumbUpIcon />,
      down:
        activeThumb === ThumbActionDtoActionEnum.Down ? <ThumbDownActiveIcon /> : <ThumbDownIcon />,
    };
  }, [activeThumb]);

  const handleThumbClick = (action) => {
    if (activeThumb === action) return;

    if (activeThumb === action) {
      setActiveThumb(null);
      onThumbClick(null);
    } else {
      setActiveThumb(action);
      onThumbClick(action);
    }
  };

  return (
    <div className={styles['tooltip-container']}>
      <CommonMessage
        metadata={metadata}
        onHeaderClick={onHeaderClick}
        isUserMessage={restProps.isUserMessage}
        {...restProps}
      >
        {cleanString(text)}
      </CommonMessage>
      {!isShared && <div className={styles['actions']}>
        <button
          className={cn(styles['actions__button'])}
          onClick={() => handleThumbClick(ThumbActionDtoActionEnum.Up)}
        >
          {thumbIcons.up}
        </button>
        <button
          className={cn(styles['actions__button'])}
          onClick={() => handleThumbClick(ThumbActionDtoActionEnum.Down)}
        >
          {thumbIcons.down}
        </button>
      </div>}
    </div>
  );
};
