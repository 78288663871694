import { useChatStore } from "../Chat.hook";
import { ChatDto } from "@/swagger_api";

const getUserFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem('user') ?? '{}');
  } catch (error) {
    console.error('Failed to parse user from localStorage', error);
    return {};
  }
};

const isCurrentChatShared = (currentUserId: string | undefined, chatUserId: string | undefined) => {
  return currentUserId !== chatUserId;
};

const isChatShared = (chat: ChatDto, sharedChats: ChatDto[] | undefined) => {
  return !!sharedChats?.find(shared => shared?.id === chat?.id);
};

const findSharedChatAuthor = (chat: ChatDto, sharedChats: ChatDto[] | undefined) => {
  const sharedChat = sharedChats?.find(shared => shared?.id === chat?.id);
  
  return ((sharedChat as any)?.author as { email?: string })?.email;
};


export const useShareChat = () => {
  const { selectedChat, sharedChats } = useChatStore();

  const user = getUserFromLocalStorage();

  const isSharedCurrent = isCurrentChatShared(user?.user?.id, selectedChat?.user_id);

  const getIsShared = (chat: ChatDto) => isChatShared(chat, sharedChats);

  const getSharedChatAuthor = (chat: ChatDto) => findSharedChatAuthor(chat, sharedChats);

  return { isShared: isSharedCurrent, getIsShared, getSharedChatAuthor };
};

