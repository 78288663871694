import { Configuration } from '../swagger_api';
import { BASE_API_URL } from './api-url';

export const swaggerConfiguration = new Configuration({
  basePath: BASE_API_URL,
  baseOptions: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  },
});

export const protectedConfiguration = new Configuration({
  basePath: BASE_API_URL,
  baseOptions: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  },
});
