import { AxiosResponse } from 'axios';

import { JobsApi } from '../../swagger_api';
import { protectedConfiguration } from '../swagger';

class JobsService {
  jobsApi: JobsApi;

  constructor() {
    this.jobsApi = new JobsApi(protectedConfiguration);
  }

  getJobs = async (): Promise<AxiosResponse<any>> => {
    try {
      const response = await this.jobsApi.findAll();
      return response;
    } catch (error) {
      console.error('Error in login:', error);
      throw error;
    }
  };
}

export const jobsService = new JobsService();
