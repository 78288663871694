import { useCallback, useEffect, useState } from 'react';

import Papa from 'papaparse';

import { datasetsService } from '../../../api/datasets/datasets.service';
import { DatasetDto } from '../../../swagger_api';

export const useDatasets = (openedItemId: string) => {
  const [datasets, setDatasets] = useState<DatasetDto[]>([]);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDatasets = async () => {
    const { data: datasets } = await datasetsService.getDatasets();
    setDatasets(datasets);
  };

  const getCsvTable = useCallback(
    async (selectedDatasetId: string) => {
      if (!selectedDatasetId || !datasets.length) return;
      const filePath = datasets.find((dataset) => dataset.id === selectedDatasetId)?.file_path;
      setLoading(true);
      const response = await fetch(filePath);
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let csv = '';
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        csv += decoder.decode(value, { stream: !done });
      }

      const results = await Papa.parse(csv, { header: true });
      const rows = results.data;

      setTimeout(() => {
        setLoading(false);
      }, 1000);

      setCsvData(rows);
    },
    [datasets],
  );

  useEffect(() => {
    fetchDatasets();
  }, []);

  useEffect(() => {
    if (openedItemId) {
      getCsvTable(openedItemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedItemId, datasets]);

  return {
    datasets,
    datasetTableData: {},
    terms: [],
    csvData,
    getCsvTable,
    isLoading: loading,
  };
};
