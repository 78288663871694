import { useCallback, useEffect } from "react";

import useManageChatModalStore from "./ManageChatModal.hook";

import Select from "../../../../../../components/Select/Select.component";
import Input from "../../../../../../components/n-Input/n-Input.component";
import { datasetsService } from "../../../../../../api";

import styles from "./ManageChatModal.module.scss"
import { generateDefaultChatName } from "./ManageChatModal.utils";

const ManageChatModalBody = () => {
    const {
        mode,
        datasets,
        chatName,
        setChatName,
        setActionButtonText,
        setTitle,
        setSelectedDataset,
        selectedDataset,
        setDatasets,
    } = useManageChatModalStore();

    const fetchDatasets = useCallback(
        async () => {
            const { data: datasets } = await datasetsService.getDatasets();
            setSelectedDataset(datasets[0]?.id)
            setDatasets(datasets)
            setSelectedDataset(datasets.filter(dataset => !dataset.user_id)[0]?.id)
        }, [setDatasets, setSelectedDataset])

    useEffect(() => {
        if(mode === 'create') {
            fetchDatasets()
            setChatName(generateDefaultChatName())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchDatasets, setChatName])

    useEffect(() => {
        if (mode === 'edit') {
            setTitle('Enter a new chat name')
            setActionButtonText('Save')
        }
    }, [mode, setActionButtonText, setTitle])

    const datasetsOptions = datasets.length ?
        datasets.filter(dataset => !dataset.user_id).map(dataset => ({ label: dataset.name, value: dataset.id })) :
        [{ label: 'Loading...', value: 'loading' }]

    if (mode === 'delete') {
        return <div className={styles['manage-chat-modal__content']}>
            <p style={{ opacity: 0.8 }}>
                The chat will <b>NO</b> longer be accessible after clicking the delete button,
                but saved assets from these chats will remain in the chat outputs.
            </p>
        </div>
    }


    return (
        <div className={styles['manage-chat-modal__content']}>
            {mode === 'create' &&
                <Select
                    className={styles['manage-chat-modal__select']}
                    label="Dataset"
                    selectedOption={selectedDataset}
                    options={datasetsOptions}
                    onChange={setSelectedDataset}
                    revertButton={false}
                    disabled={!datasets.length}
                />
            }
            {mode === 'delete_shared' ? (
              <div>This chat will be deleted only in your environment</div>
            ) : (
              <Input
                className={styles['manage-chat-modal__input']}
                label="Chat name"
                value={chatName}
                onChange={setChatName}
              />
            )}
        </div>
    );
}

export default ManageChatModalBody;