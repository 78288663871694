import { useCallback, useEffect, useState } from 'react';

import throttle from 'lodash/throttle';

import { Paper } from '../../../../components/Paper/Paper';
import styles from './Terms.module.scss';

export const Terms = ({ initialTerms }) => {
  const [terms, setTerms] = useState(initialTerms);
  const [searchQuery, setSearchQuery] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterTerms = useCallback(
    throttle((query) => {
      const lowercasedQuery = query.toLowerCase();
      const filtered = initialTerms.filter(
        (term) =>
          term.name.toLowerCase().includes(lowercasedQuery) ||
          term.description.toLowerCase().includes(lowercasedQuery),
      );
      setTerms(filtered);
    }, 300),
    [initialTerms],
  );

  const onSearch = ({ target }) => {
    const query = target.value;
    setSearchQuery(query);
    filterTerms(query);
  };

  useEffect(() => {
    setTerms(initialTerms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTerms]);

  return (
    <Paper className={styles.termsCont}>
      <h2>Terms</h2>
      <input
        value={searchQuery}
        className={styles.searchInput}
        placeholder="Search term"
        onChange={onSearch}
      />

      {terms.map((term, index) => (
        <div key={index} className={styles.term}>
          <div className={styles.name}>{term.name}</div>
          <div className={styles.definition}>{term.description}</div>
        </div>
      ))}
    </Paper>
  );
};
